import {
  Material,
  MaterialGroup,
  SheetSizeSelectionOption,
} from '@cutr/constants/cutlist';
import cn from 'classnames';

import { useCutlistState } from '@/api/store';
import { getSmallestFittingMaterial } from '@/utils/nesting';

import styles from './MaterialRow.module.css';
import { getSrcFromMaterial, onImgError } from './materialRowUtils';

export interface MaterialSelectedProps {
  selectedMaterial: Material['articleCode'];
  automaticSheetSizeMaterials: Material['articleCode'][];
  sheetSizeSelection: SheetSizeSelectionOption;
}

type MaterialVariationRow = {
  items: Material[];
  group: MaterialGroup;
  onMaterialSelected: (props: MaterialSelectedProps) => void;
};
type MaterialVariationProps = React.ButtonHTMLAttributes<HTMLDivElement> &
  MaterialVariationRow;

const MaterialVariationRowItem = ({ items }: { items: Material[] }) => {
  if (items.length === 0) return null;
  const item = items[0];
  const thicknessMM = item.thicknessUM / 1000;

  return (
    <>
      <div>
        <img
          onError={onImgError}
          src={getSrcFromMaterial(item)}
          alt={item.variationGroup?.name || item.shortDescription}
          loading="lazy"
        />
      </div>
      <div>
        <div>
          <strong>{item.variationGroup?.name || item.name}</strong>
        </div>

        {`${thicknessMM} mm`}
      </div>
    </>
  );
};

export const MaterialVariationRow = ({
  items,
  onMaterialSelected,
  group,
  className,
  ...props
}: MaterialVariationProps) => {
  if (items.length === 0) return null;

  const parts = useCutlistState((state) =>
    state.parts.filter((part) => part.groupId === group.id)
  );

  const onClick = () => {
    const smallestFittingMaterial = getSmallestFittingMaterial(
      parts,
      items.map((m) => m.articleCode)
    );
    onMaterialSelected({
      selectedMaterial: smallestFittingMaterial,
      automaticSheetSizeMaterials: items.map((m) => m.articleCode),
      sheetSizeSelection: 'automatic',
    });
  };

  return (
    <div
      className={cn(styles.materialRow, className)}
      data-id={items[0].variationGroup?.id}
      onClick={onClick}
      {...props}
    >
      <MaterialVariationRowItem items={items} />
    </div>
  );
};
