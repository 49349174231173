import cn from 'classnames';
import React from 'react';

import { useOutsideClick } from '@/utils/hooks';

import { Input } from './Input';
import styles from './SearchInput.module.css';

export interface SearchInputOption {
  value: string;
  component: React.FC;
}

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  value: string;
  onValueChange: (e: string) => void;
  onSelectionChange: (e: string) => void;
  options: SearchInputOption[];
}

export const SearchInput = ({
  value,
  options,
  id,
  onValueChange,
  onSelectionChange,
  ...props
}: SearchInputProps) => {
  const [expanded, setExpanded] = React.useState(options.length > 0);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  useOutsideClick(dropdownRef, () => setExpanded(false));

  React.useEffect(() => {
    if (options.length > 1) {
      setExpanded(true);
    }

    if (options.length === 1) {
      onSelectionChange(options[0].value);
    }
  }, [options]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onValueChange(newValue);
  };

  const handleSelection = (newValue: string) => {
    setExpanded(false);
    onSelectionChange(newValue);
  };

  return (
    <div
      className={styles.dropdown}
      ref={dropdownRef}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          setExpanded(false);
        }

        if (e.key === 'Enter') {
          const el = e.currentTarget.parentElement?.querySelector(
            '[role="option"][aria-selected="true"]'
          );
          if (!el) return setExpanded((e) => !e);

          const code = el.getAttribute('data-id');
          if (!code) return;

          const disabled = el.getAttribute('aria-disabled');
          if (disabled === 'true') {
            return;
          }

          el.setAttribute('aria-selected', 'false');
          handleSelection(code);
        }

        let els: HTMLElement[] = [];
        let found = -1;
        if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
          setExpanded(true);
          els = [
            ...(e.currentTarget.parentElement?.querySelectorAll(
              '[role="option"]'
            ) || []),
          ] as HTMLElement[];

          for (const el of els) {
            if (el.getAttribute('aria-selected') === 'true') {
              el.setAttribute('aria-selected', 'false');
              found = els.findIndex((ell) => ell === el);
              continue;
            }
          }
        }
        if (e.key === 'ArrowDown') {
          const targetEl = els[found + 1] ? els[found + 1] : els[0];
          targetEl?.setAttribute('aria-selected', 'true');
          targetEl?.scrollIntoView({
            block: 'center',
          });
        }
        if (e.key === 'ArrowUp') {
          const targetEl = els[found - 1]
            ? els[found - 1]
            : els[els.length - 1];
          targetEl?.setAttribute('aria-selected', 'true');
          targetEl?.scrollIntoView({
            block: 'center',
          });
        }
      }}
    >
      <Input
        tabIndex={0}
        id={`search-input-${id}`}
        aria-controls={`search-input-${id}`}
        value={value}
        onFocus={() => setExpanded(true)}
        onClick={() => setExpanded(true)}
        onChange={handleChange}
        {...props}
      />
      {options.length > 0 && (
        <div
          tabIndex={0}
          role="button"
          onClick={() => setExpanded((e) => !e)}
          className={cn(styles.ggChevronDown, expanded && styles.ggChevronUp)}
        />
      )}
      {expanded && (
        <div className={styles.dropdownWrapper}>
          {options && (
            <div
              className={styles.dropdownOptions}
              role="listbox"
              id={`dropdown-${id}`}
            >
              {options.map((o) => (
                <div
                  role="option"
                  tabIndex={-1}
                  aria-selected={value === o.value}
                  key={o.value}
                  data-id={o.value}
                  onClick={() => handleSelection(o.value)}
                >
                  <o.component />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
