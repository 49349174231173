import cn from 'classnames';
import React from 'react';

import styles from './Card.module.css';

interface CardProps {
  variant?: 'white' | 'gray';
  children: React.ReactNode;
  className?: string;
}

const Card = ({ variant, children, className }: CardProps) => {
  return (
    <div className={cn([styles.card, variant && styles[variant], className])}>
      {children}
    </div>
  );
};

export default Card;
