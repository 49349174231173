import './StepNavigation.css';

import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useIsLoggedIn } from '@/api/login';
import { useStepNavigation } from '@/api/navigation';
import { isInIframe } from '@/utils/misc';

export const StepNavigation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigation = useStepNavigation();
  const activeIndex = navigation.findIndex(
    (item) => location.pathname === item.pathname
  );
  const isLoggedIn = useIsLoggedIn();

  if (!isLoggedIn) return null;

  return (
    <div className="step">
      <ul>
        {navigation.map((item, k) => {
          const isActive = activeIndex === k;
          const isDone = activeIndex > k;
          const index = k + 1;

          const classNames = [
            isActive && 'active',
            !isActive && isDone && 'done',
          ]
            .filter(Boolean)
            .join(' ');
          const Wrapper = ({ children }: { children?: ReactNode }) => {
            if (isInIframe() || item.isClickable) {
              return (
                <Link to={item.pathname}>
                  <>{children}</>
                </Link>
              );
            } else {
              return <div>{children}</div>;
            }
          };
          return (
            <React.Fragment key={k}>
              <li className={classNames}>
                <Wrapper>
                  <span>{isDone && !isActive ? '✓' : index}</span>
                  {t(item.labelKey)}
                </Wrapper>
              </li>
              {!item.final && <li className={'filler ' + classNames}></li>}
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};
