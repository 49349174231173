import { Icon, Info } from '@/primitives/Icons';

export const InfoMessage = ({
  message,
}: {
  message: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className="info">
      <p className="flexAlign gap-xs">
        <Icon icon={<Info />} className="noShrink" />
        {message}
      </p>
    </div>
  );
};
