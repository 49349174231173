import { useAddressPretty } from '@/api/address';
import { AddressReview } from '@/blocks/AddressInfo';

export const AddressRecap = ({ readOnly = false }) => {
  const deliveryInfo = useAddressPretty();

  return (
    <>
      <AddressReview readOnly={readOnly} info={deliveryInfo} />
      <p />
    </>
  );
};
