import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useIsLoggedIn } from '@/api/login';
import { useCutlistState, useSetTitle, useTitle } from '@/api/store';
import { EstimatedPrice } from '@/blocks/Price';
import { RequestUnauthQuoteFlow } from '@/blocks/RequestUnauthQuoteFlow';
import { StepNavigation } from '@/blocks/StepNavigation';
import { useIsNextDisabled } from '@/hooks';
import { onFocusSelect } from '@/hooks';
import { useIframeListeners } from '@/hooks/order';
import { MaterialGroupTabNav } from '@/interfaces/MaterialGroupTabNav';
import { Nesting } from '@/interfaces/Nesting';
import { PartTable, SkeletonTable } from '@/interfaces/PartTable/index';
import { Options } from '@/interfaces/PartTableCommon/Components';
import { Input } from '@/primitives/Input';
import { useUpdateCutlist } from '@/queries/crud';
import {
  useCurrentFeatures,
  useCurrentSource,
  useIsCutrEmbedded,
  useThemeConfig,
} from '@/theme';
import { isInIframe } from '@/utils/misc';

export const OrderPage = (): JSX.Element => {
  return (
    <div className="content">
      <Header />
      <SubHeader />
      <MaterialGroupTabNav />
      <section className="layout">
        <main>
          <PartTable />
        </main>
        <Aside />
      </section>

      <IframeListeners />
    </div>
  );
};

export const SubHeader = () => {
  return <Options />;
};

export const Header = () => {
  const title = useTitle();
  const setTitle = useSetTitle();
  const isCutrEmbedded = useIsCutrEmbedded();
  const { t } = useTranslation();
  const shortId = useCutlistState((state) => state.shortId);
  const { showCutlistHeader } = useCurrentFeatures();

  if (!showCutlistHeader) return null;

  if (isCutrEmbedded) return null;

  return (
    <div className="opposites">
      <div>
        <label htmlFor="cutlist-title">{shortId ? shortId : ''}</label>
        <Input
          onInput={(e) => setTitle(e.currentTarget.value)}
          value={title}
          className="title"
          placeholder={t('cutlist-form.field.orderTitle.placeholder')}
          id="cutlist-title"
          onFocus={onFocusSelect}
        />
      </div>
      <StepNavigation />
    </div>
  );
};

const Aside = () => {
  const source = useCurrentSource();
  const hidePriceForVerdouwAgents = isInIframe() && source === 'VERDOUW';

  return (
    <aside>
      <Nesting />
      {!hidePriceForVerdouwAgents && <Price />}
    </aside>
  );
};

const Price = () => {
  const navigate = useNavigate();
  const source = useCurrentSource();
  const isNextDisabled = useIsNextDisabled();
  const { orderId } = useCutlistState();
  const { mutateAsync } = useUpdateCutlist();
  const { backBtnUrl } = useThemeConfig();
  const { integrationType, allowUnauthUsers } = useCurrentFeatures();
  const isLoggedIn = useIsLoggedIn();

  const [isRequestUnauthQuoteOpen, setIsRequestUnauthQuoteOpen] =
    React.useState(false);

  return (
    <>
      <EstimatedPrice
        onAction={async () => {
          if (allowUnauthUsers && !isLoggedIn) {
            setIsRequestUnauthQuoteOpen(true);
            window.analytics.track(
              'Request quote button clicked by unauth user'
            );
            return;
          }

          if (integrationType === 'webshop') {
            await mutateAsync({ syncThirdParty: true });

            window.location.replace(backBtnUrl);
            return;
          }

          navigate(`/cutlist/${orderId}/details`);
          window.analytics.track('Navigated to contact details', {
            ticker: source,
          });
        }}
        isNextDisabled={isNextDisabled}
      />
      {isRequestUnauthQuoteOpen && (
        <RequestUnauthQuoteFlow
          closeRequestFlow={() => setIsRequestUnauthQuoteOpen(false)}
        />
      )}
    </>
  );
};

// isolated to minimize rerenders in parent component
const IframeListeners = () => {
  useIframeListeners();

  return null;
};

export const SkeletonOrderPage = () => {
  return (
    <div className="content">
      <Header />
      <SubHeader />
      <section className="layout">
        <main>
          <SkeletonTable />
        </main>
        <Aside />
      </section>
    </div>
  );
};
