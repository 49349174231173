import { PartItem } from '@cutr/constants/cutlist';
import { Edgebanding } from '@cutr/constants/cutlist';
import { useTranslation } from 'react-i18next';

import { useCutlistState } from '@/api/store';
import { Button } from '@/primitives/Button';
import { CircledX, Icon } from '@/primitives/Icons';

import { EdgeInfo } from './EdgeInfo';

export const sides: (keyof Edgebanding)[] = [
  'length1',
  'length2',
  'width1',
  'width2',
];

export const EdgeProcessing = ({ part }: { part: PartItem }) => {
  const { setPart } = useCutlistState();
  const { t } = useTranslation();
  const emptyPartEdge = {
    length1: null,
    length2: null,
    width1: null,
    width2: null,
  };

  const handleClearAll = () => {
    setPart({
      ...part,
      edgebanding: emptyPartEdge,
      grooves: {},
      roundedEdgeband: {},
    });
  };

  return (
    <>
      {sides.map((side) => (
        <td key={side}>
          <span className="flexAlign gap-xs">
            <EdgeInfo part={part} side={side} />
          </span>
        </td>
      ))}
      <td>
        <Button
          variant="icon"
          className="delete"
          icon={<Icon icon={<CircledX />} />}
          tooltip={t('agent.clearAllEdgeProcessing')}
          onClick={handleClearAll}
        ></Button>
      </td>
    </>
  );
};
