import { sandwichHasHPL } from '@cutr/constants/cutlist';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMaterialGroupErrorStore, usePartsErrorStore } from '@/api/errors';
import { getMaterial } from '@/api/materials';
import { makeTitle, useMaterialGroupState } from '@/api/materialsGroup';
import { useCutlistParts } from '@/api/store';

export const useTableErrors = () => {
  const { t } = useTranslation();
  const groups = useMaterialGroupState((state) => state.groups);
  const { addGroupError, removeGroupErrors } = useMaterialGroupErrorStore();
  const { addPartError, removePartErrorsByField } = usePartsErrorStore();

  const parts = useCutlistParts();

  React.useEffect(() => {
    groups.forEach((group) => {
      const groupParts = parts.filter((p) => p.groupId === group.id);
      groupParts.forEach((part) => {
        removePartErrorsByField(part.id, 'partType');
        if (
          group.sheetSizeSelection === 'automatic' &&
          part.partType === 'sheet'
        ) {
          addPartError(
            part.id,
            'partType',
            t(`cutlist-form.errors.automaticSheetSizeWarning.addFullSheet`)
          );
        }
      });

      removeGroupErrors(group.id);
      if (group.core1 || group.core2 || group.topHpl || group.bottomHpl) {
        // Create error for material groups with single side HPLs. We do not allow single side gluing.
        const shouldHaveHPL = sandwichHasHPL(group.materialSandwichType);
        if (shouldHaveHPL && (!group.topHpl || !group.bottomHpl)) {
          addGroupError(
            group.id,
            t('cutlist-form.errors.oneSideHPLError', {
              materialGroupIndex: makeTitle(group),
            })
          );
        }
        if (shouldHaveHPL && group.core1) {
          const core1Material = getMaterial(group.core1);
          if (!core1Material?.gluingAndPressingAllowed) {
            addGroupError(
              group.id,
              t('cutlist-form.errors.coreMaterialNotAllowedForGluing', {
                materialGroupIndex: makeTitle(group),
              })
            );
          }
        }
        if (!group.core1) {
          addGroupError(
            group.id,
            t('cutlist-form.errors.missingCoreError', {
              materialGroupIndex: makeTitle(group),
            })
          );
        }

        // if there is edgebanding selected on the material group
        // but no edgebanding checkboxes are selected at part level
        const edgebandingCheckboxSelected = groupParts.some((p) => {
          if (!p.edgebanding) return false;
          return Object.values(p.edgebanding).some((v) => Boolean(v));
        });
        if (Boolean(group.edgeband) && !edgebandingCheckboxSelected) {
          addGroupError(
            group.id,
            t('cutlist-form.errors.edgebandingNotSelected', {
              materialGroupIndex: makeTitle(group),
            })
          );
        }

        // if there is edge profiling selected on the material group
        // but no parts have edge profiling checkboxes selected
        const edgeProfileCheckboxSelected = groupParts.some((p) => {
          if (!p.edgeProfile) return false;
          return Object.values(p.edgeProfile).some((v) => v !== 'none');
        });
        if (
          group.edgeProfile &&
          group.edgeProfile !== 'none' &&
          !edgeProfileCheckboxSelected
        ) {
          addGroupError(
            group.id,
            t('cutlist-form.errors.edgeProfilingNotSelected', {
              materialGroupIndex: makeTitle(group),
            })
          );
        }
      }
    });
  }, [groups, parts]);
};
