import { DotCutlistState } from '@cutr/constants/cutlist';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  downloadCutlistCsvTemplate,
  importCsvCutlistWithEdgeband,
  importCsvCutlistWithEdgeProfile,
} from '@/api/importExport';
import { useMaterialGroupState } from '@/api/materialsGroup';
import { useCutlistState } from '@/api/store';
import Card from '@/blocks/Card';
import { ErrorMessage } from '@/blocks/ErrorMessage';
import { Modal } from '@/blocks/Modal';
import { Button } from '@/primitives/Button';
import {
  Download,
  Icon,
  QuestionMark,
  StepOne,
  StepTwo,
  Upload,
  UploadIllustration,
} from '@/primitives/Icons';
import { useCurrentFeatures } from '@/theme';

import { useActiveGroup } from '../api/materialsGroup';
import { InfoMessage } from '../blocks/InfoMessage';
import styles from './ImportExport.module.css';

const DownloadTip = () => {
  const { t } = useTranslation();
  const { edgebandingUI } = useCurrentFeatures();

  return (
    <div className="flexAlign">
      <Card variant="gray">
        <div className="flexAlign gap-xs">
          <Icon icon={<QuestionMark />} />
          <h4>{t('upload-download.csv.downloadTipsHeader')}</h4>
        </div>
        <ul>
          <li>{t('upload-download.csv.downloadTip1')}</li>
          <li>
            {edgebandingUI
              ? t('upload-download.csv.downloadTip2')
              : t('upload-download.csv.downloadTip2EdgeProfile')}
          </li>
          <li>{t('upload-download.csv.downloadTip3')}</li>
        </ul>
      </Card>
    </div>
  );
};

const DownloadModal = ({ onDone }: { onDone: () => void }) => {
  const { t, i18n } = useTranslation();
  const onDownload = () => {
    window.analytics.track('Cutlist CSV Template Downloaded');
    downloadCutlistCsvTemplate(i18n.resolvedLanguage);
    onDone();
  };

  return (
    <div>
      <InfoMessage message={t('upload-download.csv.info')} />
      <div className="flexAlign start gap-xs">
        <Icon icon={<StepOne />} size={5} />
        <div className="stack">
          <h4>{t('upload-download.csv.downloadHeader')}</h4>
          <DownloadTip />
          <Button
            onClick={onDownload}
            size="s"
            icon={<Icon icon={<Download />} />}
          >
            {t('upload-download.csv.modalCTA')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const UploadModal = ({ onDone }: { onDone: () => void }) => {
  const store = useCutlistState();
  const gStore = useMaterialGroupState();
  const activeGroup = useActiveGroup();
  const { t, i18n } = useTranslation();
  const [tempStore, setTempStore] = React.useState<DotCutlistState>();
  const [error, setError] = React.useState('');
  const { hasEdgeProfiling } = useCurrentFeatures();
  const hasTempStore = Boolean(tempStore?.parts.length);

  React.useEffect(() => {
    const preventDefault = (e: Event) => e.preventDefault();

    window.addEventListener('dragover', preventDefault);
    window.addEventListener('drop', preventDefault);

    return () => {
      window.removeEventListener('dragover', preventDefault);
      window.removeEventListener('drop', preventDefault);
    };
  }, []);

  const onApplyCsvCutlist = () => {
    if (!tempStore) return;
    const partsToRemove = store.parts.filter(
      (p) => p.groupId === gStore.activeGroup
    );
    partsToRemove.map((p) => store.removePart(p));

    tempStore.parts.map((p) => store.setPart(p));

    onDone();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (error: any) => {
    let message;
    if (error.response) {
      message = error.response.data.message;
    } else {
      message = error.message;
    }
    window.analytics.track('Csv upload', {
      isSuccess: false,
      errors: message,
    });
    setError(message);
  };

  const trackCsvUpload = () => {
    window.analytics.track('Csv upload', {
      isSuccess: true,
      numberOfParts: store.parts.length,
    });
  };

  const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!activeGroup) return;

    setError('');
    if (!e.currentTarget.files) return;

    handleImport(e.currentTarget.files);
  };

  const handleFileDrop = (e: React.DragEvent) => {
    if (!activeGroup) return;
    setError('');

    if (!e.dataTransfer?.files.length) return;

    handleImport(e.dataTransfer.files);
  };

  const handleImport = (files: FileList) => {
    (hasEdgeProfiling
      ? importCsvCutlistWithEdgeProfile(
          files,
          i18n.resolvedLanguage,
          activeGroup
        )
      : importCsvCutlistWithEdgeband(files, i18n.resolvedLanguage, activeGroup)
    )
      .then((store) => {
        if (!store) return;
        setTempStore(store);
        trackCsvUpload();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <div onDrop={handleFileDrop}>
      <div>
        <div className="flexAlign start gap-xs">
          <Icon icon={<StepTwo />} size={5} />
          <div className="stack">
            <h4>{t('upload-download.csv.uploadHeader')}</h4>
          </div>
        </div>
      </div>
      {hasTempStore && (
        <div>
          <p>
            {t('upload-download.upload.modalSuccess', {
              number: tempStore?.parts.length || 0,
            })}
          </p>
          <p />
          <Button
            onClick={onApplyCsvCutlist}
            size="s"
            icon={<Icon icon={<Upload />} />}
          >
            {t('upload-download.upload.modalConfirm')}
          </Button>
        </div>
      )}

      {!hasTempStore && (
        <>
          <form className={styles.uploadForm}>
            <label htmlFor="cutlistUpload">
              <div>
                <Icon
                  icon={<UploadIllustration />}
                  color="var(--primary-color)"
                  size={8}
                />
              </div>
              {t('upload-download.csv.uploadHelp')}
              <input
                id="cutlistUpload"
                type="file"
                name="file"
                onChange={onFileSelected}
                accept=".csv"
              />
            </label>
          </form>
        </>
      )}

      {error != '' && <ErrorMessage message={error} />}
    </div>
  );
};

export const CsvUploadHandler = () => {
  const hasMaterials = useCutlistState((state) => state.hasMaterials);
  const { t } = useTranslation('translation', { useSuspense: false });
  const [opener, setOpener] = React.useState<'upload' | 'download' | null>(
    null
  );
  const isOpen = Boolean(opener);

  return (
    <>
      <Button
        disabled={!hasMaterials}
        tooltip={t('upload-download.upload.buttonTooltip')}
        onClick={() => setOpener('upload')}
        variant="secondary"
        size="s"
        icon={<Icon icon={<Upload />} />}
      >
        {t(`upload-download.upload.uploadCsv`)}
      </Button>
      {isOpen && (
        <Modal
          title={t('upload-download.csv.title')}
          handleClose={() => setOpener(null)}
          isOpen={isOpen}
        >
          <div className="stack">
            <DownloadModal onDone={() => setOpener(null)} />
            <UploadModal onDone={() => setOpener(null)} />
          </div>
        </Modal>
      )}
    </>
  );
};
