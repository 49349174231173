import { Material } from '@cutr/constants/cutlist';
import { SupplierMaterial } from '@cutr/constants/material';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useLeadDetails } from '@/api/account';
import { agentApi } from '@/api/backend/agent';
import { usePartsHaveErrors } from '@/api/errors';
import { getMaterial } from '@/api/materials';
import {
  useMaterialGroupState,
  useSaveAllGroupChanges,
} from '@/api/materialsGroup';
import { useCutlistState } from '@/api/store';
import { AgentQuoteHeader } from '@/blocks/AgentOrderHeader';
import Card from '@/blocks/Card';
import { CutlistEmailPreview } from '@/blocks/CutlistEmailPreview';
import { materialDimensions } from '@/blocks/MaterialRow';
import { useIsNextDisabled } from '@/hooks';
import { AgentQuotingPartTable } from '@/interfaces/AgentQuotingPartTable';
import { Nesting } from '@/interfaces/Nesting';
import { Options } from '@/interfaces/PartTableCommon/Components';
import { QuotingMaterialGroupTabNav } from '@/interfaces/QuotingMaterialGroupTabNav';
import { Button } from '@/primitives/Button';
import { Download, Icon, Loader } from '@/primitives/Icons';
import { saveBlob } from '@/utils/file';

import styles from './AgentQuoteParts.module.css';

const isDev = import.meta.env.VITE_CUTR_ENV === 'development';

export const AgentQuoteParts = (): JSX.Element => {
  return (
    <>
      <AgentQuoteHeader />
      <SubHeader />
      <CutlistEmailPreview />
      <QuotingMaterialGroupTabNav />
      <section className="layout">
        <AgentQuotingPartTable />
        <Aside />
      </section>
    </>
  );
};

export const SubHeader = () => {
  return <Options />;
};

const Aside = () => {
  return (
    <aside>
      <MaterialSummary />
      <Nesting hidden={!isDev} />
    </aside>
  );
};

type MaterialSummary = {
  id: string;
  core1: Material;
  variationDimensions: string[];
};

const MaterialSummary = () => {
  const { t } = useTranslation();
  const materialGroups = useMaterialGroupState((state) => state.groups);

  const savedMaterials = React.useMemo(() => {
    const materials = materialGroups
      .filter((group) => group.ulid)
      .map((group) => {
        const variationDimensions =
          group.automaticSheetSizeMaterials?.map((material) =>
            materialDimensions(getMaterial(material) as SupplierMaterial)
          ) || [];

        return {
          id: nanoid(),
          core1: getMaterial(group?.core1),
          variationDimensions,
        } as MaterialSummary;
      })
      .filter((item) => item.core1);

    return materials;
  }, [materialGroups]);

  return (
    <Card>
      <div className={styles.divider}>
        <h3>{t('agent.quoteFlow.materialSummaryTitle')}</h3>

        {savedMaterials.length > 0 ? (
          <ol className={styles.materialSummary}>
            {savedMaterials.map(({ id, core1, variationDimensions }) => {
              const core1Dimensions = materialDimensions(core1);
              const allDimensions = Array.from(
                new Set([core1Dimensions, ...variationDimensions])
              );
              return (
                <li key={id}>
                  <div className="stack gap-xxs">
                    <span>{core1.name}</span>
                    {allDimensions.map((itemDimension) => (
                      <small className="textStrong" key={itemDimension}>
                        {itemDimension}
                      </small>
                    ))}
                  </div>
                </li>
              );
            })}
          </ol>
        ) : (
          <p>{t('agent.quoteFlow.noMaterials')}</p>
        )}
      </div>

      <ActionButtons />
    </Card>
  );
};

const ActionButtons = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { shortId } = useCutlistState();
  const [isDownloadingHPO, setIsDownloadingHPO] = React.useState(false);
  const [isSavingGroups, setIsSavingGroups] = React.useState(false);
  const { email } = useLeadDetails();
  const isNextDisabled = useIsNextDisabled();
  const partsHaveErrors = usePartsHaveErrors();

  const [saveAllMaterialGroups, canSave] = useSaveAllGroupChanges();

  const isCTADisabled =
    isNextDisabled || isSavingGroups || isDownloadingHPO || !canSave;

  if (!id) {
    return null;
  }

  const onAction = async () => {
    if (!id) return;
    if (!email) {
      toast.error(t('agent.toasts.setCutlistOwner'));
      return;
    }

    setIsSavingGroups(true);
    try {
      const count = await saveAllMaterialGroups();
      if (count !== 0) {
        toast.success(t('agent.toasts.materialSettingsSaved'));
      }
    } catch (error) {
      toast.error(t('common.errors.saveOrder') as string);
    } finally {
      setIsSavingGroups(false);
    }
  };

  const downloadHpo = async () => {
    if (!id) return;
    setIsDownloadingHPO(true);

    await onAction();

    try {
      const result = await agentApi.getCutlistHpo(id);
      saveBlob(result, `${shortId}_hpo.xlsx`);
    } catch (error) {
      toast.error(t('agent.toasts.fileDownloadError'));
    } finally {
      setIsDownloadingHPO(false);
    }
  };

  return (
    <div className="stack" style={{ paddingTop: '1rem' }}>
      <Button
        className="outline"
        icon={<Icon icon={<Download />} />}
        onClick={downloadHpo}
        disabled={isDownloadingHPO}
      >
        {isDownloadingHPO ? (
          <Icon icon={<Loader />} />
        ) : (
          t('agent.quoteFlow.downloadHpo')
        )}
      </Button>
      <Button
        onClick={async () => {
          await onAction();
          navigate(`/admin/orders/${id}/quote/price`);
        }}
        disabled={isCTADisabled}
        tooltip={
          partsHaveErrors ? t('agent.tooltip.partsHaveErrorsOnNext') : undefined
        }
      >
        {isSavingGroups ? (
          <Icon icon={<Loader />} />
        ) : (
          t('agent.quoteFlow.partsCallToAction')
        )}
      </Button>
    </div>
  );
};
