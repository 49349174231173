import { useTranslation } from 'react-i18next';

import {
  useMaterialGroupErrorStore,
  useNestingErrorStore,
  usePartsErrorStore,
} from '@/api/errors';
import { ErrorMessage } from '@/blocks/ErrorMessage';

export const Errors = () => {
  const { nestingErrors } = useNestingErrorStore();
  const { materialGroupErrors } = useMaterialGroupErrorStore();
  const { t } = useTranslation();

  if (nestingErrors.length === 0 && materialGroupErrors.length === 0)
    return null;

  return (
    <ErrorMessage message={t('cutlist-form.errors.groupsTitle')}>
      {nestingErrors.map((e, id) => (
        <p key={id}>{e}</p>
      ))}
      {materialGroupErrors.map((e, id) => (
        <p key={id}>{e.errorMessage}</p>
      ))}
    </ErrorMessage>
  );
};

export const PartErrors = () => {
  const { partErrors } = usePartsErrorStore();
  const { t } = useTranslation();

  if (partErrors.length === 0) return null;

  return (
    <ErrorMessage message={t('cutlist-form.errors.title')}>
      {partErrors.map((e, id) => (
        <p key={id}>{e.errorMessage}</p>
      ))}
    </ErrorMessage>
  );
};
