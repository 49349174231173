import { NestingOutput } from '@cutr/constants/cutlist-nesting';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { NestingGroup } from '@/utils/nesting';

export type SheetGroup = NestingOutput & NestingGroup;
export type NestingStore = {
  sheetGroups: SheetGroup[];
  setNesting(sheetGroups: SheetGroup[]): void;
};

export const useNestingStore = create<NestingStore>()(
  devtools((set) => ({
    sheetGroups: [],
    setNesting: (sheetGroups) => set(() => ({ sheetGroups })),
  }))
);
