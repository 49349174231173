/**
 * Gets keyboard-focusable elements within a specified element
 */
export function getKeyboardFocusableElements(
  element?: HTMLElement | null
): HTMLElement[] {
  if (!element) return [];

  const elements = [
    ...element.querySelectorAll(
      'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
    ),
  ] as HTMLElement[];

  return elements.filter(
    (el) =>
      !el.hasAttribute('disabled') &&
      !el.getAttribute('aria-hidden') &&
      el.getAttribute('tabindex') !== '-1'
  );
}
