import { CutlistOrderStatus } from '@cutr/constants/cutlist';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { getPersistedKey } from '@/utils/misc';

export type PaginationState = {
  pageIndex: number;
  pageSize: number;
};

export type DateFilters = {
  createdBefore: string;
  createdAfter: string;
  submittedBefore: string;
  submittedAfter: string;
};

export type FiltersState = {
  pagination: PaginationState;
  search: string;
  statuses: CutlistOrderStatus[];
  dateFilters: DateFilters;
  agentIds: string[];
};

const DEFAULT_FILTERS_STATE: FiltersState = {
  pagination: {
    pageIndex: 0,
    pageSize: 30,
  },
  search: '',
  statuses: ['new', 'order_placed', 'quote_ready', 'quote_requested'],
  dateFilters: {
    createdBefore: '',
    createdAfter: '',
    submittedBefore: '',
    submittedAfter: '',
  },
  agentIds: [],
};

type FiltersStateActions = {
  setPagination(pagination: PaginationState): void;
  setSearch(search: string): void;
  setStatuses(statuses: CutlistOrderStatus[]): void;
  setDateFilters(dateFilters: DateFilters): void;
  setAgentIds(agentIds: string[]): void;
  resetFilters(): void;
};

export const useAgentWorkspaceFilterStore = create<
  FiltersState & FiltersStateActions
>()(
  persist(
    (set) => ({
      ...DEFAULT_FILTERS_STATE,
      setPagination: (pagination) => set(() => ({ pagination })),
      setSearch: (search) => set(() => ({ search })),
      setStatuses: (statuses) => set(() => ({ statuses })),
      setDateFilters: (dateFilters) => set(() => ({ dateFilters })),
      setAgentIds: (agentIds) => set(() => ({ agentIds })),
      resetFilters: () => set(() => ({ ...DEFAULT_FILTERS_STATE })),
    }),
    {
      name: getPersistedKey('cutlist-agent-workspace-filters'),
    }
  )
);
