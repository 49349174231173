import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { useCurrentSource } from '@/theme';
import { getPersistedKey } from '@/utils/misc';

export type AuthState = {
  clientNumber: null | string;
  email: null | string;
  token: null | string;
  agent: null | Agent;
};

const DEFAULT_AUTH_STATE: AuthState = {
  clientNumber: null,
  email: null,
  token: null,
  agent: null,
};

export type Agent = {
  id: string;
  cutlistAdminSourceId: string;
  email: string;
};

type AuthStateActions = {
  login(data: Omit<AuthState, 'agent'>): void;
  loginAgent({ token, agent }: { token: string; agent: Agent }): void;
  logout(): void;
};

export const useAuthStore = create<AuthState & AuthStateActions>()(
  persist(
    (set) => ({
      ...DEFAULT_AUTH_STATE,
      login: (data) => set(() => ({ ...DEFAULT_AUTH_STATE, ...data })),
      loginAgent: ({ token, agent }) =>
        set(() => ({ ...DEFAULT_AUTH_STATE, token, agent })),
      logout: () => set((state) => ({ ...state, ...DEFAULT_AUTH_STATE })),
    }),
    { name: getPersistedKey('cutlist-auth') }
  )
);

export const isLoggedInSelector = (state: AuthState) =>
  Boolean(state.token && !state.agent);
export const useIsLoggedIn = () => {
  const authStore = useAuthStore();
  const source = useCurrentSource();

  if (source === 'CUTR-EMBED') return true;

  return isLoggedInSelector(authStore);
};

// Agent auth
export const agentLoggedInSelector = (state: AuthState) =>
  Boolean(state.token && state.agent);

export const useAgentLoggedIn = () => {
  const authStore = useAuthStore();

  return agentLoggedInSelector(authStore);
};
