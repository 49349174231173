import { DotCutlistState } from '@cutr/constants/cutlist';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { dotCutlist, exportCutlist, importCutlist } from '@/api/importExport';
import { useMaterialGroupState } from '@/api/materialsGroup';
import { useCutlistState } from '@/api/store';
import { ErrorMessage } from '@/blocks/ErrorMessage';
import { Modal } from '@/blocks/Modal';
import { Button } from '@/primitives/Button';
import {
  Download,
  Icon,
  Info,
  Upload,
  UploadIllustration,
} from '@/primitives/Icons';

import styles from './ImportExport.module.css';

export const ImportExport = ({
  mode = 'upload',
  handleClose,
}: {
  handleClose: () => void;
  mode: 'upload' | 'download' | null;
}) => {
  const isUpload = mode === 'upload';
  const isDownload = mode === 'download';

  return (
    <div className={styles.wrapper}>
      {isUpload && <UploadModal onDone={handleClose} />}
      {isDownload && <DownloadModal onDone={handleClose} />}
    </div>
  );
};

const DownloadModal = ({ onDone }: { onDone: () => void }) => {
  const store = useCutlistState();
  const gStore = useMaterialGroupState();
  const { t } = useTranslation();
  const onDownload = () => {
    window.analytics.track('Cutlist File Downloaded');
    exportCutlist(dotCutlist(store, gStore));
    onDone();
  };

  return (
    <div>
      <p>{t('upload-download.download.modalInfo')}</p>
      <Button onClick={onDownload} size="s" icon={<Icon icon={<Download />} />}>
        {t('upload-download.download.modalCTA')}
      </Button>
    </div>
  );
};

const UploadModal = ({ onDone }: { onDone: () => void }) => {
  const store = useCutlistState();
  const gStore = useMaterialGroupState();
  const { t } = useTranslation();
  const [tempStore, setTempStore] = React.useState<DotCutlistState>();
  const [error, setError] = React.useState(false);
  const hasTempStore = Boolean(tempStore?.parts.length);

  React.useEffect(() => {
    const preventDefault = (e: Event) => e.preventDefault();

    window.addEventListener('dragover', preventDefault);
    window.addEventListener('drop', preventDefault);

    return () => {
      window.removeEventListener('dragover', preventDefault);
      window.removeEventListener('drop', preventDefault);
    };
  }, []);

  const onApply = () => {
    if (!tempStore) return;

    // save the order id if its set so we can
    // upload cutlists in existing orders first party
    const { orderId } = store;

    gStore.clear();
    store.reset();
    store.init(tempStore);
    store.setId(orderId || '');

    if (tempStore.groupState) {
      gStore.init(tempStore.groupState);
    }
    // noop if store is already set
    gStore.groupBy();
    onDone();
  };

  const importFile = (files: FileList | null) => {
    setError(false);
    if (!files?.length) return;
    importCutlist(files)
      .then((store) => {
        if (!store) return;
        setTempStore(store);
        window.analytics.track('Cutlist File Uploaded');
      })
      .catch(() => setError(true));
  };

  const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    importFile(e.currentTarget.files);
  };

  const handleFileDrop = (e: React.DragEvent) => {
    importFile(e.dataTransfer.files);
  };

  return (
    <div onDrop={handleFileDrop}>
      <p className={styles.warning}>
        <span className="flexAlign gap-xs">
          <Icon icon={<Info />} />
          {t('upload-download.upload.modalWarning')}
        </span>
      </p>
      {hasTempStore && (
        <div>
          <p>
            {t('upload-download.upload.modalSuccess', {
              number: tempStore?.parts.length || 0,
            })}
          </p>
          <p />
          <Button onClick={onApply} size="s" icon={<Icon icon={<Upload />} />}>
            {t('upload-download.upload.modalConfirm')}
          </Button>
        </div>
      )}

      {!hasTempStore && (
        <>
          <p>{t('upload-download.upload.modalInfo')}</p>
          <form className={styles.uploadForm}>
            <label htmlFor="cutlistUpload">
              <div>
                <Icon
                  icon={<UploadIllustration />}
                  color="var(--primary-color)"
                  size={8}
                />
              </div>
              {t('upload-download.upload.modalCTA')}
              <input
                id="cutlistUpload"
                type="file"
                name="file"
                onChange={onFileSelected}
                accept=".cutlist"
              />
            </label>
          </form>
        </>
      )}

      {error && (
        <ErrorMessage message={t('upload-download.upload.modalError')} />
      )}
    </div>
  );
};

export const ExportHandler = () => {
  const hasMaterials = useCutlistState((state) => state.hasMaterials);
  const { t } = useTranslation('translation', { useSuspense: false });
  const [opener, setOpener] = React.useState<'upload' | 'download' | null>(
    null
  );
  const isOpen = Boolean(opener);

  return (
    <>
      <Button
        disabled={!hasMaterials}
        tooltip={t('upload-download.upload.buttonTooltip')}
        onClick={() => setOpener('upload')}
        variant="secondary"
        size="xs"
        icon={<Icon icon={<Upload />} />}
        data-cy="export-upload-button"
      >
        {t(`upload-download.upload.buttonCTA`)}
      </Button>
      <Button
        disabled={!hasMaterials}
        tooltip={t('upload-download.download.buttonTooltip')}
        onClick={() => setOpener('download')}
        variant="secondary"
        size="xs"
        icon={<Icon icon={<Download />} />}
        className="tooltipLeft"
        data-cy="export-download-button"
      >
        {t(`upload-download.download.buttonCTA`)}
      </Button>
      {isOpen && (
        <Modal
          title={t(`upload-download.${opener}.modalTitle`)}
          handleClose={() => setOpener(null)}
          isOpen={isOpen}
        >
          <ImportExport mode={opener} handleClose={() => setOpener(null)} />
        </Modal>
      )}
    </>
  );
};
