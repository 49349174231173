// TODO: use full list on first party tool?
// import { countries as countriesListEN } from 'i18n-iso-countries/langs/en.json';
// import { countries as countriesListNL } from 'i18n-iso-countries/langs/nl.json';

// TODO: this is very basic and it's used for very basic needs
// it needs to support dutch as well
export function withPlural(num: number, label: string) {
  return `${num} ${label}${num === 1 ? '' : 's'}`;
}

export function getFormattedISODate() {
  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
}
const countriesListNL = {
  NL: 'Nederland',
  BE: 'België',
  DE: 'Duitsland',
  FR: 'Frankrijk',
  CH: 'Zwitserland',
};
const countriesListEN = {
  NL: 'Netherlands',
  BE: 'Belgium',
  DE: 'Germany',
  FR: 'France',
  CH: 'Switzerland',
};
const countriesListDE = {
  NL: 'Niederlande',
  BE: 'Belgien',
  DE: 'Deutschland',
  FR: 'Frankreich',
  CH: 'Schweiz',
};

export type CountryCode = keyof typeof countriesListEN;
export type CountryEntry = { code: CountryCode; label: string };

export const getCountriesList = (lang = 'en'): CountryEntry[] => {
  const list =
    lang === 'nl'
      ? countriesListNL
      : lang === 'de'
      ? countriesListDE
      : countriesListEN;

  return (Object.keys(list) as CountryCode[]).map((code) => {
    const countryName = list[code];
    const label = Array.isArray(countryName) ? countryName[0] : countryName;
    return { code, label };
  });
};

export const countryLabelToCode = (label: string, lang = 'en'): CountryCode => {
  const list = lang === 'nl' ? countriesListNL : countriesListEN;

  return (Object.keys(list) as CountryCode[]).find(
    (code) => list[code] === label
  ) as CountryCode;
};
