import { AddressPretty } from '@cutr/constants/cutlist-theme';
import { useTranslation } from 'react-i18next';

import { useCutlistState } from '@/api/store';
import Card from '@/blocks/Card';
import { Button, RouterButton } from '@/primitives/Button';
import { Edit, Icon, Map } from '@/primitives/Icons';

import styles from './AddressInfo.module.css';

export const AddressInfo = ({
  info,
  title,
  onEditAddress,
}: {
  info: AddressPretty;
  title?: string;
  onEditAddress?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="opposites">
        <h4 className={styles.title}>
          <Icon icon={<Map />} size={2.5} />
          {title || t('account-details.delivery.title')}
        </h4>
        {onEditAddress && (
          <Button
            style={{
              color: ' var(--primary)',
            }}
            size="xs"
            variant="icon"
            className="outline"
            icon={<Icon icon={<Edit />} />}
            onClick={() => onEditAddress()}
          />
        )}
      </div>
      <dl className={styles.list}>
        <dt>{t('account-details.field.name.label')}</dt>
        <dd>{info.name}</dd>
        <dt>{t('review.address.field')}</dt>
        <dd>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          {info.address},
          <br />
          {info.countryName}
        </dd>

        {info.tel && (
          <>
            <dt>{t('account-details.field.phone.label')}</dt>
            <dd>{info.tel}</dd>
          </>
        )}
        {info.email && (
          <>
            <dt>{t('account-details.field.email.label')}</dt>
            <dd>{info.email}</dd>
          </>
        )}
      </dl>
    </div>
  );
};

export const AddressReview = ({
  info,
  header,
  title,
  readOnly = false,
}: {
  info: AddressPretty;
  header?: string;
  title?: string;
  readOnly?: boolean;
}) => {
  const { t } = useTranslation();
  const { orderId } = useCutlistState();

  return (
    <div>
      <div className="opposites">
        <h3>{header || t('review.address.title')}</h3>
        {!readOnly && (
          <RouterButton
            to={`/cutlist/${orderId}/details`}
            variant="secondary"
            icon={<Icon icon={<Edit />} />}
          >
            {t('review.cta.editAddress')}
          </RouterButton>
        )}
      </div>
      <p />
      <Card>
        <AddressInfo info={info} title={title} />
      </Card>
    </div>
  );
};
