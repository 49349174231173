import { AddressPretty } from '@cutr/constants/cutlist-theme';
import { create } from 'zustand';

import { CountryCode } from '@/utils/intl';

import { useLeadDetails } from './account';

export const defaultAddress = {
  contactName: '',
  phone: '',
  line1: '',
  line2: '',
  postalCode: '',
  city: '',
  country: 'NL' as CountryCode,
};

export type Address = typeof defaultAddress;

export type Actions = {
  setProp(key: keyof Address, value: Address[typeof key]): void;
  reset(): void;
  init(address: Address): void;
};

export const useDeliveryAddress = create<Address & Actions>()((set) => ({
  ...defaultAddress,
  reset: () => set(() => ({ ...defaultAddress })),
  init: (savedState) => set(() => ({ ...savedState })),
  setProp: (key, value) =>
    set((state) => ({
      ...state,
      [key]: value,
    })),
}));

const requiredFields = [
  'contactName',
  'line1',
  'postalCode',
  'city',
  'country',
  'phone',
] as const;

const deliveryRequiredSelector = (state: Address) => {
  return requiredFields.every((field) => state[field]);
};

export const useIsAddressValid = () => {
  const isDeliveryValid = useDeliveryAddress(deliveryRequiredSelector);

  return isDeliveryValid;
};

export const useAddressPretty = (): AddressPretty => {
  const state = useLeadDetails();
  const deliveryAddress = useDeliveryAddress();

  return {
    name: deliveryAddress.contactName,
    address: [
      deliveryAddress.line1,
      deliveryAddress.line2,
      deliveryAddress.postalCode,
      deliveryAddress.city,
    ]
      .filter(Boolean)
      .join(', '),
    countryName: deliveryAddress.country,
    tel: deliveryAddress.phone,
    email: state.email,
  };
};
