import { Toaster } from 'sonner';

export const ToastProvider = () => {
  return (
    <Toaster
      richColors
      position="top-right"
      visibleToasts={1}
      toastOptions={{
        style: { top: 'var(--headerHeight)' },
      }}
    />
  );
};
