import { create } from 'zustand';

import { api } from '@/api/backend';
import { useInterval } from '@/utils/hooks';

type FeatureFlagsState = {
  features: Record<string, boolean>;
  updateFeatureFlags: (data: Record<string, boolean>) => void;
};

const useFeatureFlagsStore = create<FeatureFlagsState>()((set) => ({
  features: {},
  updateFeatureFlags: (data) => set(() => ({ features: data })),
}));

export const useFetchFeatureFlags = () => {
  const store = useFeatureFlagsStore();

  useInterval(() => {
    api.features().then((data) => store.updateFeatureFlags(data));
  }, 30000); // fetch features every 30s
};

export const useFeatureFlag = (flag: string) => {
  const { features } = useFeatureFlagsStore();

  return features[flag] || false;
};

export const useIsFeatureEnabled = () => {
  const { features } = useFeatureFlagsStore();

  return (flag: string) => features[flag] || false;
};

export const useIsFeatureEnabledAsync = () => {
  return async (flag: string) => {
    const features = (await api.features()) as Record<string, boolean>;

    return features[flag] || false;
  };
};
