import { Features, Theme } from '@cutr/constants/cutlist-theme';

import { OrderPage } from '@/pages/Order';
import { getFeaturesForTheme } from '@/theme';

import {
  withFeaturesOverride,
  withMockCutlist,
  withMockMaterials,
  withPriceToggle,
  withTheme,
} from './decorators';
import { ArgType, Meta } from './layout/types';

export type StoryProps = { theme: Theme } & Features;

const INITIAL_THEME: Theme = 'bnb';

const featuresArgTypes = Object.keys(getFeaturesForTheme(INITIAL_THEME)).reduce(
  (acc, key) => {
    const feature = getFeaturesForTheme(INITIAL_THEME)[key as keyof Features];

    const featureType = typeof feature;
    let control;

    switch (featureType) {
      case 'boolean':
        control = { control: 'boolean' };
        break;
      case 'number':
        control = { control: 'number' };
        break;
      case 'object':
        if (feature instanceof Array)
          control = { control: 'select', options: feature };
        if (feature instanceof Object) control = { control: 'text' };
        break;
      case 'string':
        control = { control: 'text' };
        break;
      default:
        break;
    }

    return { ...acc, [key]: control };
  },
  {}
) as Record<keyof Features, ArgType>;

const config = {
  args: {
    ...getFeaturesForTheme(INITIAL_THEME),
    theme: INITIAL_THEME,
  },
  decorators: [
    withPriceToggle,
    withMockCutlist,
    withMockMaterials,
    withTheme,
    withFeaturesOverride,
  ],
};

const meta: Meta = {
  title: 'Order page features',
  component: OrderPage,
  config,
  argTypes: featuresArgTypes,
};

export default meta;
