import React from 'react';

export const useToggle = (initialValue = false): [boolean, () => void] => {
  const [value, setValue] = React.useState(initialValue);

  const toggle = React.useCallback(() => {
    setValue((v) => !v);
  }, []);

  return [value, toggle];
};

export const useInterval = (
  callback: () => void,
  delay: number,
  callOnMount = true
) => {
  const savedCallback = React.useRef(callback);

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const tick = () => savedCallback.current();

    if (callOnMount) {
      tick();
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export const useDebounce = <T>(
  value: T,
  delay: number,
  initialValue: T = [] as T
) => {
  const [debouncedValue, setDebouncedValue] = React.useState<T>(initialValue);
  React.useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
};

export const useDebounceArray = <T>(
  value: T[],
  delay: number,
  initialValue: T[] = [] as T[]
) => {
  const [debouncedValue, setDebouncedValue] = React.useState<T[]>(initialValue);
  React.useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [...value, delay]);
  return debouncedValue;
};

export const useNextInputOnEnter = () => {
  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      // @ts-expect-error event.target is not typed
      if (event.key === 'Enter' && event.target?.nodeName === 'INPUT') {
        // @ts-expect-error event.target is not typed
        const form = event.target.form;
        let index = Array.prototype.indexOf.call(form, event.target);
        let toFocus;

        do {
          index++;
          toFocus = form.elements[index];
        } while (toFocus && toFocus.nodeName !== 'INPUT');

        if (toFocus && toFocus.nodeName === 'INPUT') {
          toFocus.focus();
        }

        event.preventDefault();
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);
};

export const useOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void
) => {
  React.useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, callback]);
};
