import { CutlistOrder } from '@cutr/constants/cutlist';
import { useQuery } from '@tanstack/react-query';

import { cutlistsApi } from '@/api/backend/cutlists';

import { cutlistsKeys } from './keys';

export const useCutlists = () => {
  return useQuery<CutlistOrder[]>({
    queryKey: cutlistsKeys.getAll,
    queryFn: cutlistsApi.getCutlists,
    initialData: [],
  });
};
