import { MaterialGroup } from '@cutr/constants/cutlist';
import { useTranslation } from 'react-i18next';

import { setGroupAndParts } from '@/api/materialsGroup';
import { Textarea } from '@/primitives/Input';
import { useCurrentFeatures } from '@/theme';

import styles from './MaterialGroupNotes.module.css';

export const MaterialGroupNotes = ({ group }: { group: MaterialGroup }) => {
  const { t } = useTranslation();
  const { continuousGrain, additionalProcessing } = useCurrentFeatures();

  if (!continuousGrain && !additionalProcessing) return null;

  const onUpdate = <K extends keyof MaterialGroup>(
    key: K,
    value: MaterialGroup[K]
  ) => {
    const updatedGroup = { ...group };

    updatedGroup[key] = value;
    setGroupAndParts(updatedGroup);
  };

  return (
    <div className={styles.additionalNotes}>
      <h3>{t('cutlist-form.additionalNotes')}</h3>

      <div className="flexAlign distribute alignItemsReset">
        {continuousGrain && (
          <div>
            <p>
              <strong>{t('cutlist-form.field.continuousGrain.title')}</strong>
            </p>
            <p style={{ minHeight: '2rem' }}>
              {t('cutlist-form.field.continuousGrain.body')}
            </p>
            <Textarea
              rows={4}
              value={group.continuousGrain}
              onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                onUpdate('continuousGrain', e.target.value);
              }}
            />
          </div>
        )}
        {additionalProcessing && (
          <div>
            <p>
              <strong>
                {t('cutlist-form.field.additionalProcessing.title')}
              </strong>
            </p>
            <p style={{ minHeight: '2rem' }}>
              {t('cutlist-form.field.additionalProcessing.body')}
            </p>
            <Textarea
              rows={4}
              value={group.additionalProcessing}
              onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                onUpdate('additionalProcessing', e.target.value);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
