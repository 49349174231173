import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './EdgesPreview.module.css';

export const EdgesPreview = ({
  size,
  label,
  quantity = 1,
  edges,
}: {
  size: [number, number];
  label: string;
  quantity?: number;
  edges: {
    l1: unknown;
    l2: unknown;
    w1: unknown;
    w2: unknown;
  };
}) => {
  const [length, width] = size;
  const ratio = Math.round((width / length) * 100);
  const { t } = useTranslation();

  return (
    <div
      className={styles.edgesPreview}
      style={{ '--aspectRatio': ratio || 56.25 } as React.CSSProperties}
    >
      <div className={styles.contour}>
        <span className={edges.w1 ? styles.active : undefined}>
          {t('cutlist-form.field.edgebanding.sides.w1')}
        </span>
        <span className={edges.w2 ? styles.active : undefined}>
          {t('cutlist-form.field.edgebanding.sides.w2')}
        </span>
        <span className={edges.l2 ? styles.active : undefined}>
          {t('cutlist-form.field.edgebanding.sides.l2')}
        </span>
        <span className={edges.l1 ? styles.active : undefined}>
          {t('cutlist-form.field.edgebanding.sides.l1')}
        </span>
        {/* eslint-disable i18next/no-literal-string */}
        {Boolean(width) && <span>{width}mm</span>}
        {Boolean(length) && <span>{length}mm</span>}
      </div>
      <div className={styles.details}>
        <strong>{label}</strong>
        <br />
        {t('cutlist-form.field.quantity.label')}: {quantity}
      </div>
      {/* eslint-enable */}
    </div>
  );
};
