import { MATERIAL_TYPE } from '@cutr/constants/material';

export const Materials = MATERIAL_TYPE;

// Some of the materials with a type from "CoreMaterialTypes" are thin (<1.5mm)
// and can be applied as a layer (like HPL).
// Examples are some of the veneers and bamboe materials, etc.
// Their types are correct (eg, solid-wood), but we use this threshold to determine
// if they should be shown as a core material or a layer.
export const MATERIAL_LAYER_THICKNESS_UM_THRESHOLD = 1500;

export const EdgebandMaterialTypes = [Materials.veneerband, Materials.edgeband];
export const CoreMaterialTypes = [
  Materials.plywood,
  Materials.concrete_plywood,
  Materials.chipboard,
  Materials.melamine_chipboard,
  Materials.osb,
  Materials.mdf,
  Materials.melamine_mdf,
  Materials.veneer_mdf,
  Materials.veneer,
  Materials.solid_wood,
  Materials.viroc,
  Materials.door_plate,
  Materials.cladding,
  Materials.underlayment,
  Materials.hardboard,
  Materials.plasterboard,
  Materials.fire_resistant_boards,
  Materials.fibre_cement,
  Materials.wood_fibre_cement,
  Materials.gypsum_fibre,
  Materials.decorative_fibreboard,
  Materials.decorative_composite,
  Materials.decorative_chipboard,
];
