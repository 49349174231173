import cn from 'classnames';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsLoggedIn } from '@/api/login';
import { LoginForm } from '@/blocks/LoginForm';
import { LoginModal } from '@/blocks/LoginModal';
import { Button, RouterButton } from '@/primitives/Button';
import { Icon } from '@/primitives/Icons';
import {
  DirectQuote,
  Edgebanding,
  EdgeProfiling,
  GlueAndPress,
  Labeling,
  MaterialSandwitch,
  Optimisation,
  Sawing,
} from '@/primitives/ServiceIllustrations';
import { useCreateEmptyCutlist } from '@/queries/crud';
import { useCurrentFeatures, useThemeConfig } from '@/theme';
import { formatEmbedVideoUrl } from '@/utils/misc';

import styles from './NewLandingPage.module.css';

const LandingPage = () => {
  const isLoggedIn = useIsLoggedIn();
  const { welcomeImage } = useThemeConfig();
  const availableServices = useServiceIllustrations();
  const { landingPageVideoUrls } = useCurrentFeatures();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'landing-page',
  });

  const partnerImage = welcomeImage || '/cutlist.png';

  return (
    <main className="content">
      <section className={styles.landingPage}>
        {!isLoggedIn ? <LogInBlock /> : <WelcomeLoggedInUserBlock />}
        <div className={styles.logo}>
          <img alt="cutlist" src={partnerImage} />
        </div>
        <div className={styles.services}>
          <h2>{t('titles.services')}</h2>
          <div className={styles.servicesList}>
            {availableServices.map((service) => (
              <ServiceIllustration
                key={service.label}
                icon={service.icon}
                label={service.label}
              />
            ))}
          </div>
        </div>
        {landingPageVideoUrls.length > 0 && (
          <div className={styles.videos}>
            <h2>{t('titles.videos')}</h2>
            <p>{t('descriptions.videos')}</p>
            <div className={styles.videoFrames}>
              {landingPageVideoUrls.map((videoUrl) => (
                <iframe
                  className={styles.frame}
                  key={videoUrl}
                  width="540"
                  height="304"
                  src={formatEmbedVideoUrl({ videoUrl })}
                  title="Video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              ))}
            </div>
          </div>
        )}
      </section>
    </main>
  );
};

export default LandingPage;

const LogInBlock = () => {
  const { allowUnauthUsers } = useCurrentFeatures();
  const { newAccountUrl } = useThemeConfig();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'landing-page',
  });
  const { createNewCutlist, isPending } = useCreateEmptyCutlist();

  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  if (!allowUnauthUsers) {
    return <LoginForm />;
  }

  return (
    <div className={styles.welcome}>
      <h1>{t('welcome.title')}</h1>
      <p className={styles.description}>{t('welcome.productDescription')}</p>
      <div className={`${styles.actionsBlock} flexAlign gap-m`}>
        <div className={styles.singleAction}>
          <p>{t('productAccess.requestAQuote')}</p>
          <Button
            size="l"
            name="startCutlistUnauth"
            className={cn(styles.callToAction, styles.button, 'noResize')}
            onClick={createNewCutlist}
            disabled={isPending}
          >
            {t('productAccess.startCutlistButton')}
          </Button>
        </div>
        <div className={styles.singleAction}>
          <p>{t('productAccess.existingCustomer')}</p>
          <div>
            <Button
              name="openLoginModal"
              className={`outline noResize ${styles.button}`}
              onClick={() => setLoginModalOpen(true)}
            >
              {t(`productAccess.signInButton`)}
            </Button>
            <LoginModal
              isOpen={loginModalOpen}
              onClose={() => setLoginModalOpen(false)}
            />
          </div>
          <a
            href={newAccountUrl}
            target="_blank"
            rel="noreferrer"
            style={{ marginTop: 'var(--space-xs)' }}
          >
            {t('productAccess.createAccountOpt')}{' '}
          </a>
        </div>
      </div>
    </div>
  );
};

const WelcomeLoggedInUserBlock = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'landing-page.welcome',
  });

  return (
    <div className={styles.welcome}>
      <h1>{t('title')}</h1>
      <p>{t('description')}</p>
      <RouterButton
        size="l"
        to="/"
        className={cn(styles.callToAction, styles.actionsBlock)}
      >
        {t('buttonText')}
      </RouterButton>
    </div>
  );
};

const ServiceIllustration = ({ icon, label }: ServiceIllustrationProps) => {
  return (
    <div className={styles.serviceCard}>
      <Icon icon={icon} style={{ width: '100%' }} />
      <p>{label}</p>
    </div>
  );
};

interface ServiceIllustrationProps {
  label: string;
  icon: ReactNode;
}

const useServiceIllustrations = () => {
  const availableServices: ServiceIllustrationProps[] = [];
  const { t } = useTranslation(undefined, {
    keyPrefix: 'landing-page.services',
  });

  const {
    hasEdgeProfiling,
    supportedMaterialSandwiches,
    edgebandingUI,
    hasCreateLabels,
    pricingType,
  } = useCurrentFeatures();

  if (supportedMaterialSandwiches.length > 1) {
    availableServices.push({
      label: t('glueAndPress'),
      icon: <GlueAndPress />,
    });
    availableServices.push({
      label: t('materialSandwitch'),
      icon: <MaterialSandwitch />,
    });
  }

  availableServices.push({
    label: t('optimisation'),
    icon: <Optimisation />,
  });

  availableServices.push({
    label: t('sawing'),
    icon: <Sawing />,
  });

  if (edgebandingUI) {
    availableServices.push({
      label: t('edgebanding'),
      icon: <Edgebanding />,
    });
  }

  if (hasEdgeProfiling) {
    availableServices.push({
      label: t('edgeProfiling'),
      icon: <EdgeProfiling />,
    });
  }

  if (hasCreateLabels !== 'none') {
    availableServices.push({
      label: t('labeling'),
      icon: <Labeling />,
    });
  }

  if (pricingType !== 'NoPrice') {
    availableServices.push({
      label: t('directQuote'),
      icon: <DirectQuote />,
    });
  }

  return availableServices;
};
