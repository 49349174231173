import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/primitives/Button';
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  EllipsisHorizontal,
} from '@/primitives/Icons';

import styles from './BasePagination.module.css';

interface BasePaginationProps {
  totalPages: number;
  currentPage: number;
  maxVisibleButtons?: number;
  onPageChanged: (page: number) => void;
  showPageNumbers?: boolean;
  disabled?: boolean;
}

export const BasePagination: React.FC<BasePaginationProps> = ({
  totalPages,
  currentPage,
  disabled,
  maxVisibleButtons = 3,
  onPageChanged,
  showPageNumbers = false,
}) => {
  const { t } = useTranslation();
  const startPage = useMemo(() => {
    if (currentPage === 1) {
      return 1;
    }
    if (currentPage === totalPages) {
      return Math.max(totalPages - maxVisibleButtons + 1, 1);
    }
    return currentPage - 1;
  }, [currentPage, totalPages, maxVisibleButtons]);

  const pages = useMemo(() => {
    const range = [];
    for (
      let i = startPage;
      i <= Math.min(startPage + maxVisibleButtons - 1, totalPages);
      i++
    ) {
      range.push({
        id: crypto.randomUUID(),
        name: i,
        isCurrent: i === currentPage,
      });
    }
    return range;
  }, [startPage, maxVisibleButtons, totalPages, currentPage]);

  const hasPagesAfter = startPage + maxVisibleButtons <= totalPages;

  const onClickPage = async (page: number) => {
    onPageChanged(page);
  };

  return (
    <ul className={styles.pagination} aria-label="pagination">
      <li>
        <Button
          size="xs"
          icon={<ChevronsLeft />}
          aria-label={`page 1`}
          onClick={() => onClickPage(1)}
          disabled={currentPage === 1 || disabled}
        ></Button>
      </li>

      <li>
        <Button
          size="xs"
          icon={<ChevronLeft />}
          aria-label={`page ${currentPage - 1}`}
          onClick={() => onClickPage(currentPage - 1)}
          disabled={currentPage === 1 || disabled}
        ></Button>
      </li>

      <li className={startPage === 1 ? styles.hide : undefined}>
        <EllipsisHorizontal />
      </li>

      {pages.map(({ id, name, isCurrent }) => (
        <li key={id}>
          <Button
            size="xs"
            onClick={() => onClickPage(name)}
            aria-label={isCurrent ? `page ${name}` : undefined}
            aria-current={isCurrent ? 'page' : undefined}
            className={isCurrent ? styles.active : 'outline snug transparent'}
            disabled={disabled}
          >
            {name}
          </Button>
        </li>
      ))}

      <li className={!hasPagesAfter ? styles.hide : undefined}>
        <EllipsisHorizontal />
      </li>

      <li>
        <Button
          size="xs"
          icon={<ChevronRight />}
          aria-label={`page ${currentPage + 1}`}
          onClick={() => onClickPage(currentPage + 1)}
          disabled={currentPage === totalPages || disabled}
        ></Button>
      </li>

      <li>
        <Button
          size="xs"
          icon={<ChevronsRight />}
          aria-label={`page ${totalPages}`}
          onClick={() => onClickPage(totalPages)}
          disabled={currentPage === totalPages || disabled}
        ></Button>
      </li>

      {showPageNumbers && (
        <li className={styles.last}>
          {t(`translation.pagination.pages`, {
            currentPage,
            totalPages,
          })}
        </li>
      )}
    </ul>
  );
};

export default BasePagination;
