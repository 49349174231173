import {
  Orientation,
  PartIn,
  SheetConfig,
} from '@cutr/constants/cutlist-nesting';

import { partFitsOffcut } from './helpers';

export type Offcut = {
  lengthMM: number;
  widthMM: number;
  offcutId?: number;
  sheetId: number;
  offsetX: number;
  offsetY: number;
  fullSheetOffcut: boolean;
  previousSawOrientation: Orientation | null;
  reloadDepth: number;
  // We use sheet's grainDirection for orientation fitting.
  sheetConfig: SheetConfig;
};

export function removeDuplicateSizeOffcuts(offcuts: Offcut[]): Offcut[] {
  const uniqueOffcuts: Offcut[] = [];
  const uniqueOffcutSizes = new Set();
  offcuts.forEach((offcut) => {
    const offcutSize = `${offcut.lengthMM},${offcut.widthMM}`;
    if (!uniqueOffcutSizes.has(offcutSize)) {
      uniqueOffcuts.push(offcut);
      uniqueOffcutSizes.add(offcutSize);
    }
  });
  return uniqueOffcuts;
}

class OffcutStack {
  private offcutStack: Offcut[] = [];
  private offcutIdCounter = 1;

  push(offcut: Offcut): number {
    if (offcut.offcutId) {
      throw new Error(
        `Offcut (id=${offcut.offcutId}) already pushed to the stack.`
      );
    }
    // this is the only place we assign offcut ID to the offcut object
    offcut.offcutId = this.offcutIdCounter++;
    this.offcutStack.push(offcut);
    return offcut.offcutId;
  }

  pop(): number {
    const topOffcut: Offcut | undefined = this.offcutStack.pop();
    if (topOffcut === undefined) throw new Error('Empty offcut stack.');
    if (!topOffcut.offcutId)
      throw new Error(`Offcut (${topOffcut}) does not have id.`);

    this.offcutIdCounter--;
    return topOffcut.offcutId;
  }

  top(): number {
    const topOffcut = this.offcutStack[this.offcutStack.length - 1];
    if (!topOffcut.offcutId)
      throw new Error(`Offcut (${topOffcut}) does not have id.`);

    return topOffcut.offcutId;
  }
}

export class Offcuts {
  private offcutStack: OffcutStack = new OffcutStack();
  usedOffcuts: { [key: number]: Offcut } = {};
  unusedOffcuts: { [key: number]: Offcut } = {};

  addOffcut(offcut: Offcut): number {
    const offcutId = this.offcutStack.push(offcut);
    this.unusedOffcuts[offcutId] = offcut;
    return offcutId;
  }

  deleteOffcut(offcutId: number) {
    if (!(offcutId in this.unusedOffcuts)) {
      throw new Error(`Offcut ${offcutId} is not among unused offcuts.`);
    }
    const topOffcutId = this.offcutStack.top();
    if (offcutId !== topOffcutId) {
      throw new Error(
        `Only top offcut (id=${topOffcutId}) can be deleted. Deleting offcut (id=${offcutId}) failed.`
      );
    }
    this.offcutStack.pop();
    delete this.unusedOffcuts[offcutId];
  }

  // Use the offcut and make it unavailable.
  useOffcut(offcutId: number) {
    if (offcutId in this.usedOffcuts) {
      throw new Error(`Offcut ${offcutId} is already used.`);
    }
    if (!(offcutId in this.unusedOffcuts)) {
      throw new Error(
        `Offcut ${offcutId} is not among unused offcuts. First add it.`
      );
    }
    // move the offcut from "unused" to "used"
    this.usedOffcuts[offcutId] = this.unusedOffcuts[offcutId];
    delete this.unusedOffcuts[offcutId];
  }

  // Used to revert "useOffcut" operation, for backtracking the recursive search.
  unuseOffcut(offcutId: number) {
    if (offcutId in this.unusedOffcuts) {
      throw new Error(`Offcut ${offcutId} is already unused.`);
    }
    if (!(offcutId in this.usedOffcuts)) {
      throw new Error(
        `Offcut ${offcutId} is not among used offcuts. First add it.`
      );
    }
    // move the offcut from "used" to "unused"
    this.unusedOffcuts[offcutId] = this.usedOffcuts[offcutId];
    delete this.usedOffcuts[offcutId];
  }

  getOffcut(offcutId: number): Offcut {
    if (offcutId in this.unusedOffcuts) return this.unusedOffcuts[offcutId];
    if (offcutId in this.usedOffcuts) return this.usedOffcuts[offcutId];
    throw new Error(`Offcut (id=${offcutId}) does not exist.`);
  }

  pickFittingOffcuts(part: PartIn): Offcut[] {
    const fittingOffcuts: Offcut[] = [];
    for (const offcutId in this.unusedOffcuts) {
      const offcut = this.unusedOffcuts[offcutId];
      if (
        partFitsOffcut(part, 'horizontal', offcut) ||
        partFitsOffcut(part, 'vertical', offcut)
      )
        fittingOffcuts.push(offcut);
    }
    return fittingOffcuts;
  }
}
