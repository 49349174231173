import { LoginForm } from './LoginForm';
import { Modal } from './Modal';

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  email?: string;
}
export const LoginModal = ({ isOpen, email, onClose }: LoginModalProps) => {
  return (
    <Modal title="" handleClose={onClose} isOpen={isOpen}>
      <div style={{ padding: 'var(--space-xl)', paddingTop: 'var(--space-m)' }}>
        <LoginForm email={email} />
      </div>
    </Modal>
  );
};
