import React from 'react';
import { create } from 'zustand';

import { usePricingStore } from '@/api/pricing';
import { useUpdateCutlist } from '@/queries/crud';
import { useCurrentFeatures } from '@/theme';
import { isWeekend } from '@/utils/date';

import { useIsAddressValid } from './address';
import { useCutlistState } from './store';

export const UserLead = {
  id: '',
  name: '',
  email: '',
  clientNumber: '',
  notClient: false,
  companyName: '',
};

export type UserLeadState = typeof UserLead;

type Actions = {
  init: (state: UserLeadState) => void;
  reset(): void;

  setProp(key: keyof UserLeadState, value: string | boolean): void;
};

export const useLeadDetails = create<UserLeadState & Actions>()((set) => ({
  ...UserLead,
  setProp: (key, value) => set((state) => ({ ...state, [key]: value })),
  reset: () => set(() => ({ ...UserLead })),
  init: (savedState) => set(() => ({ ...savedState })),
}));

export const requiredFields: Partial<keyof UserLeadState>[] = ['name'];

export const useIsValid = () => {
  const isDetailsValid = useLeadDetails(requiredSelector);
  const isAddressValid = useIsAddressValid();
  const isRequestedDeliveryDateValid = useIsRequestedDeliveryDateValid();

  return isDetailsValid && isAddressValid && isRequestedDeliveryDateValid;
};

const requiredSelector = (state: UserLeadState) => {
  const isClientSelected = Boolean(state.notClient || state.clientNumber);
  return isClientSelected && requiredFields.every((field) => state[field]);
};

const useIsRequestedDeliveryDateValid = () => {
  const { hasRequestedDeliveryDate } = useCurrentFeatures();
  const { requestedDeliveryDate } = useCutlistState();

  if (!hasRequestedDeliveryDate) {
    return true;
  }

  const input = document.querySelector(
    'input#requestedDeliveryDate'
  ) as HTMLInputElement;

  if (!input) return false;

  return input.checkValidity() && !isWeekend(requestedDeliveryDate);
};

export function usePricingFromLeadDetails() {
  const pricing = usePricingStore();
  const { clientNumber } = useLeadDetails();
  const { mutateAsync } = useUpdateCutlist();

  React.useEffect(() => {
    mutateAsync({}).then((data) => {
      pricing.setAmountVat(data.totalAmountInclVAT);
      pricing.setAmountNoVat(data.totalAmountExclVAT);
      pricing.setPricing(data.pricingLineItems);
    });
  }, [clientNumber]);
}
