import cn from 'classnames';

import { Button } from '@/primitives/Button';

import styles from './TabNav.module.css';

interface TabNavProps {
  tabs: string[];
  activeTab: number;
  onChangeTab: (tabIndex: number) => void;
  className?: string;
}

const TabNav = ({ tabs, activeTab, onChangeTab, className }: TabNavProps) => {
  const handleTabChange = (tabIndex: number) => {
    onChangeTab(tabIndex);
  };

  return (
    <section className={cn(styles.tabNav, className)}>
      {tabs.map((tab, index) => {
        return (
          <Button
            role="tab"
            aria-selected={activeTab === index ? true : false}
            data-cy="tabButton"
            variant="tab"
            key={`tab-${index}`}
            onClick={() => handleTabChange(index)}
            className={activeTab === index ? styles.activeTab : styles.tab}
          >
            {tab}
          </Button>
        );
      })}
    </section>
  );
};

export default TabNav;
