import { useTranslation } from 'react-i18next';

import { CircledCheckmark2, CutCircle, Icon } from '@/primitives/Icons';

import { useEdgeProcessing } from './EdgeProcessingContext';
import styles from './EdgeSelector.module.css';
import { useSelectedPartsContext } from './SelectedPartsContext';

export const EdgesSelector = () => {
  const { t } = useTranslation();
  const { selectedParts } = useSelectedPartsContext();
  const { edgesToApply: edges, setEdgesToApply } = useEdgeProcessing();

  const ratio = Math.round((1 / 1.64) * 100); // golden ratio
  const quantity = selectedParts.length;

  const edgeOrder = ['width1', 'width2', 'length2', 'length1'] as const;

  type Edges = typeof edges;
  const toggleEdge = (edge: keyof Edges) => {
    setEdgesToApply((prev) => {
      const newEdges = { ...prev, [edge]: !prev[edge] };
      return newEdges;
    });
  };

  const sidesLabel = {
    width1: 'w1',
    width2: 'w2',
    length2: 'l2',
    length1: 'l1',
  };

  return (
    <div
      className={styles.edgesPreview}
      style={{ '--aspectRatio': ratio } as React.CSSProperties}
    >
      <div
        className={styles.contour}
        style={{
          borderTopColor: edges['length2'] ? 'var(--primary)' : undefined,
          borderRightColor: edges['width2'] ? 'var(--primary)' : undefined,
          borderBottomColor: edges['length1'] ? 'var(--primary)' : undefined,
          borderLeftColor: edges['width1'] ? 'var(--primary)' : undefined,
        }}
      >
        {edgeOrder.map((edge) => (
          <label key={edge} className={edges[edge] ? styles.active : undefined}>
            <Icon
              color={edges[edge] ? 'var(--primary)' : undefined}
              icon={edges[edge] ? <CircledCheckmark2 /> : <CutCircle />}
            />
            {t(`cutlist-form.field.edgebanding.sides.${sidesLabel[edge]}`)}
            <input
              className={styles.hiddenCheckbox}
              name={`edge-preview`}
              id={`edge-preview-${edge}`}
              type="checkbox"
              onChange={() => toggleEdge(edge)}
              checked={edges[edge]}
            />
          </label>
        ))}
      </div>
      <div className={styles.details}>
        <strong>{t('agent.preview.willApplyTo')}</strong>
        <br />
        {t('agent.preview.selectedPartsNr', { count: quantity })}
      </div>
    </div>
  );
};
