import { CurrencyOptions } from '@cutr/constants/cutlist';

import i18n from '../i18n';

export const currencyFormatter = (amount: number, currency: string) =>
  new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency,
  }).format(amount);

export const currencyToDisplayMap: Record<CurrencyOptions, string> = {
  EUR: '€',
  CHF: 'CHF',
};

export const dateFormatter = (date: string) => {
  if (!date) return '';

  return new Intl.DateTimeFormat(getFormattingLocale(), {
    dateStyle: 'short',
  }).format(new Date(date));
};

export function getFormattingLocale() {
  const lang = i18n.resolvedLanguage || 'en';
  return lang === 'en' ? 'en-GB' : lang;
}
