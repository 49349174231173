import {
  Edgebanding as EdgebandingType,
  EdgeProfile as EdgeProfileType,
} from '@cutr/constants/cutlist';
import { PartItem } from '@cutr/constants/cutlist';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useActiveGroup } from '@/api/materialsGroup';
import { Modal } from '@/blocks/Modal';
import { useFocusRestore } from '@/hooks';
import EdgebandingInterface, {
  EdgebandingContextProvider,
  EdgebandingIcon,
  parseEdgeBanding,
} from '@/interfaces/Edgebanding';
import { ExportHandler } from '@/interfaces/ImportExport';
import { Button } from '@/primitives/Button';
import { Checkbox } from '@/primitives/Checkbox';
import {
  Edit,
  GrainHorizontal,
  GrainNone,
  Icon,
  SelectedEdges,
} from '@/primitives/Icons';
import { useCurrentFeatures } from '@/theme';
import { isTruthy } from '@/utils/misc';

import EdgeProfilingInterface, {
  EdgeProfileIcon,
  EdgeProfilingContextProvider,
  parseEdgeProfiling,
} from '../EdgeProfiling';
import styles from './styles.module.css';

export const Edgebanding = ({
  part,
  onUpdate,
}: {
  part: PartItem;
  onUpdate: (edge: EdgebandingType) => void;
}) => {
  const currentEdgeband = React.useMemo(() => {
    return parseEdgeBanding(part.edgebanding);
  }, [part.edgebanding]);
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();
  const { edgebandingUI } = useCurrentFeatures();

  const sides: (keyof EdgebandingType)[] = [
    'length1',
    'length2',
    'width1',
    'width2',
  ];

  const activeGroup = useActiveGroup();
  const hasNone =
    part.edgebanding &&
    sides.every((side) => Boolean(part.edgebanding?.[side]));

  const isMixed = Object.values(part.edgebanding || {})
    .filter(isTruthy)
    .some((edge) => edge !== activeGroup?.edgeband);

  const emptyEdgebandingGroup: EdgebandingType = {
    length1: null,
    length2: null,
    width1: null,
    width2: null,
  };

  const handleEdgebandToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    side: keyof EdgebandingType
  ) => {
    const code = event.target.checked ? activeGroup?.edgeband ?? null : null;

    const data: EdgebandingType = {
      ...emptyEdgebandingGroup,
      ...part.edgebanding,
      [side]: code,
    };

    window.analytics.track('Edgeband checkbox edit', {
      side: side,
      material: activeGroup?.edgeband,
    });

    onUpdate(data);
  };

  const handleEdgebandToggleAll = () => {
    if (!activeGroup?.edgeband) return;

    const data = { ...emptyEdgebandingGroup };

    window.analytics.track('Edgeband checkbox edit', {
      material: activeGroup?.edgeband,
      side: hasNone ? 'none' : 'all',
    });

    if (hasNone) {
      return void onUpdate(data);
    }

    for (const side of sides) {
      data[side] = (part.edgebanding || {})[side] || activeGroup.edgeband;
    }

    onUpdate(data);
  };

  useFocusRestore(!isOpen);

  return (
    <EdgebandingContextProvider initialEdges={currentEdgeband}>
      {edgebandingUI && !isMixed ? (
        <>
          {sides.map((side) => (
            <td key={side}>
              <Checkbox
                checked={Boolean(part.edgebanding?.[side])}
                onChange={(e) => handleEdgebandToggle(e, side)}
                disabled={!activeGroup?.edgeband}
              />
            </td>
          ))}

          <td>
            <div className="flexAlign gap-xxs">
              <Button
                tooltip={t(
                  'cutlist-form.edgebandingToggle.' + (hasNone ? 'none' : 'all')
                )}
                style={{ color: ' var(--primary)' }}
                size="xs"
                variant="icon"
                icon={
                  <Icon
                    size={1.5}
                    icon={
                      <SelectedEdges
                        borders={
                          currentEdgeband.all
                            ? [true, true, true, true]
                            : [
                                Boolean(currentEdgeband.l2),
                                Boolean(currentEdgeband.w2),
                                Boolean(currentEdgeband.l1),
                                Boolean(currentEdgeband.w1),
                              ]
                        }
                      />
                    }
                  />
                }
                onClick={() => handleEdgebandToggleAll()}
              />
              <Button
                style={{ color: ' var(--primary)' }}
                size="xs"
                variant="icon"
                icon={<Icon icon={<Edit />} />}
                onClick={() => setIsOpen(true)}
              />
            </div>
          </td>
        </>
      ) : (
        <td colSpan={edgebandingUI ? 5 : 1}>
          <EdgebandingIcon onClick={() => setIsOpen(true)} />
        </td>
      )}

      <Modal
        title={t('cutlist-form.field.edgebanding.modalTitle')}
        handleClose={() => setIsOpen(false)}
        isOpen={isOpen}
      >
        <EdgebandingInterface
          part={{
            label: part.label,
            quantity: part.quantity,
            size: [part.lengthMM, part.widthMM],
          }}
          onSave={(edges) => {
            onUpdate({
              length1: edges.l1?.articleCode ?? null,
              length2: edges.l2?.articleCode ?? null,
              width1: edges.w1?.articleCode ?? null,
              width2: edges.w2?.articleCode ?? null,
            });
            setIsOpen(false);
          }}
        />
      </Modal>
    </EdgebandingContextProvider>
  );
};

export const EdgeProfile = ({
  part,
  onUpdate,
}: {
  part: PartItem;
  onUpdate: (edge: EdgeProfileType) => void;
}) => {
  const { hasPerSideEdgeProfiling } = useCurrentFeatures();

  const currentEdgeProfile = React.useMemo(
    () => parseEdgeProfiling(part.edgeProfile),
    [part.edgeProfile]
  );
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { t } = useTranslation();

  const sides: (keyof EdgeProfileType)[] = [
    'length1',
    'length2',
    'width1',
    'width2',
  ];

  const activeGroup = useActiveGroup()!;
  const hasNone =
    part.edgeProfile &&
    sides.every((side) => part.edgeProfile?.[side] === 'none');

  const emptyEdgeProfileGroup: EdgeProfileType = {
    length1: 'none',
    length2: 'none',
    width1: 'none',
    width2: 'none',
  };

  const handleEdgeProfileToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    side: keyof EdgeProfileType
  ) => {
    const edgeProfile = event.target.checked
      ? activeGroup?.edgeProfile
      : 'none';

    const data: EdgeProfileType = {
      ...emptyEdgeProfileGroup,
      ...part.edgeProfile,
      [side]: edgeProfile,
    };

    window.analytics.track('Edge profile checkbox edit', {
      side: side,
      material: activeGroup?.edgeProfile,
    });

    onUpdate(data);
  };

  const handleEdgeProfileToggleAll = () => {
    if (!activeGroup.edgeProfile) return;

    const data = { ...emptyEdgeProfileGroup };

    window.analytics.track('Edge profile checkbox edit', {
      material: activeGroup?.edgeProfile,
      side: hasNone ? 'none' : 'all',
    });

    if (!hasNone) {
      return void onUpdate(data);
    }
    for (const side of sides) {
      data[side] = activeGroup.edgeProfile;
    }
    onUpdate(data);
  };

  useFocusRestore(!isModalOpen);

  const isMixed = Object.values(part.edgeProfile || {})
    .filter(isTruthy)
    .some((edge) => edge !== 'none' && edge !== activeGroup?.edgeProfile);

  return (
    <EdgeProfilingContextProvider initialEdges={currentEdgeProfile}>
      {!isMixed ? (
        <>
          {sides.map((side) => (
            <td key={side}>
              <Checkbox
                checked={part.edgeProfile?.[side] !== 'none'}
                onChange={(e) => handleEdgeProfileToggle(e, side)}
                disabled={
                  activeGroup?.edgeProfile === 'none' ||
                  (part.partType === 'strip' && side.startsWith('width'))
                }
              />
            </td>
          ))}

          <td>
            <div className="flexAlign gap-xxs">
              <Button
                tooltip={t(
                  'cutlist-form.edgeProfilingToggle.' +
                    (hasNone ? 'all' : 'none')
                )}
                style={{ color: ' var(--primary)' }}
                size="xs"
                variant="icon"
                icon={
                  <Icon
                    size={1.5}
                    icon={
                      <SelectedEdges
                        borders={[
                          Boolean(part.edgeProfile?.length2 !== null),
                          Boolean(part.edgeProfile?.width2 !== null),
                          Boolean(part.edgeProfile?.length1 !== null),
                          Boolean(part.edgeProfile?.width1 !== null),
                        ]}
                      />
                    }
                  />
                }
                onClick={() => handleEdgeProfileToggleAll()}
                disabled={
                  activeGroup?.edgeProfile === 'none' ||
                  part.partType === 'strip'
                }
              />
              {hasPerSideEdgeProfiling && (
                <Button
                  style={{ color: ' var(--primary)' }}
                  size="xs"
                  variant="icon"
                  icon={<Icon icon={<Edit />} />}
                  onClick={() => setIsModalOpen(true)}
                />
              )}
            </div>
          </td>
        </>
      ) : (
        <td colSpan={5}>
          <EdgeProfileIcon onClick={() => setIsModalOpen(true)} />
        </td>
      )}

      {hasPerSideEdgeProfiling && (
        <Modal
          title={t('cutlist-form.field.edgeProfiling.modalTitle')}
          handleClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
        >
          <EdgeProfilingInterface
            part={{
              label: part.label,
              quantity: part.quantity,
              size: [part.lengthMM, part.widthMM],
            }}
            onSave={(edges) => {
              onUpdate({
                length1: edges.l1,
                length2: edges.l2,
                width1: edges.w1,
                width2: edges.w2,
              });
              setIsModalOpen(false);
            }}
          />
        </Modal>
      )}
    </EdgeProfilingContextProvider>
  );
};

export const GrainButton = ({
  direction = 'along',
  disabled = false,
  onUpdate,
}: {
  direction: PartItem['grainDirection'];
  disabled?: boolean;
  onUpdate: (grain: PartItem['grainDirection']) => void;
}) => {
  const { t } = useTranslation();
  const iconsMap = [GrainHorizontal, GrainNone];
  const valueMap: PartItem['grainDirection'][] = ['along', 'none'];

  const [val, setVal] = React.useState(
    valueMap.findIndex((i) => i === direction)
  );

  const onClick = () => {
    let i = val + 1;
    if (i >= iconsMap.length) i = 0;
    setVal(i);
    onUpdate(valueMap[i]);
  };

  const BtnIcon = iconsMap[val];

  return (
    <Button
      onClick={onClick}
      variant="icon"
      tooltip={t(`cutlist-form.grainDirection.${direction}`)}
      icon={<Icon icon={<BtnIcon />} />}
      disabled={disabled}
    />
  );
};

export const Options = () => {
  const { export: hasExport } = useCurrentFeatures();

  return (
    <div className={styles.options}>
      <div className="opposites">
        {hasExport && (
          <div
            className="flexAlign"
            style={{ position: 'relative', zIndex: 2 }}
          >
            <ExportHandler />
          </div>
        )}
      </div>
      <p></p>
    </div>
  );
};
