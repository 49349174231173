import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useMaterialGroupErrorStore, usePartsErrorStore } from '@/api/errors';
import { getMaterial } from '@/api/materials';
import { useIsGroupSaved, useMaterialGroupState } from '@/api/materialsGroup';
import { selectHasMaterials, useCutlistState } from '@/api/store';
import { ConfirmationDialog } from '@/blocks/ConfirmationDialog';
import { Button } from '@/primitives/Button';
import { Alert, Icon, Layer, Trash } from '@/primitives/Icons';
import { useDeleteMaterialGroup } from '@/queries/materialGroup';
import { useCurrentFeatures } from '@/theme';
import { getTrimConfigByType } from '@/utils/features';

import styles from './MaterialGroupTabNav.module.css';

export const QuotingMaterialGroupTabNav = () => {
  const tab = useMaterialGroupState((state) => state.activeGroup);
  const setTab = useMaterialGroupState((state) => state.setActive);
  const { groups, addGroup, removeGroup, setGroupIndex } =
    useMaterialGroupState();
  const hasMaterials = useCutlistState(selectHasMaterials);
  const { materialGroupErrors, removeGroupErrors } =
    useMaterialGroupErrorStore();
  const isGroupSaved = useIsGroupSaved();
  const select = (id: string) => () => setTab(id);
  const { t } = useTranslation();
  const { partErrors } = usePartsErrorStore();
  const [groupToRemoveData, setGroupToRemoveData] = useState<{
    ulid?: string;
    groupId: string;
  } | null>(null);
  const { supportedSheetEdgeTrims, defaultSheetEdgeTrimTypeForPartGroup } =
    useCurrentFeatures();
  const { id } = useParams();
  const { mutateAsync: deleteMaterialGroup } = useDeleteMaterialGroup(
    id as string
  );

  const parts = useCutlistState((state) => state.parts);

  const onRemoveGroup = async (
    data: { ulid?: string; groupId: string } | null
  ) => {
    if (!data) return;
    const { ulid, groupId } = data;
    window.analytics.track('Cutlist Material Group Deleted', {
      groupId: groupId,
    });

    if (ulid) {
      const backendGroups = await deleteMaterialGroup(ulid);

      backendGroups.forEach((g) => {
        setGroupIndex(g.groupId, g.index);
      });
    }

    removeGroupErrors(groupId);
    removeGroup(groupId);
  };

  return (
    <section className={styles.tabNav}>
      {groups
        .sort((a, b) => (a.index ?? Infinity) - (b.index ?? Infinity))
        .map((g) => {
          const core1Material = getMaterial(g.core1);
          const coreName =
            g.sheetSizeSelection === 'automatic'
              ? `${
                  core1Material?.variationGroup?.name || core1Material?.name
                } ${core1Material?.thicknessMM}mm`
              : core1Material?.name;
          const title = coreName || g.core1 || g.name;
          const partIds = parts
            .filter((part) => part.groupId === g.id)
            .map((p) => p.id);

          const partialCore = g.core1 && !core1Material;
          const pErrors = partErrors.some((p) =>
            partIds.includes(p.partItemId)
          );
          const errors = materialGroupErrors.some(
            (e) => e.materialGroupId === g.id
          );
          const isSaved = isGroupSaved(g.id);

          return (
            <div
              key={`tab-${g.id}`}
              className={cn(
                isSaved ? null : styles.unsavedTab,
                'flexAlign gap-xs pad-sm'
              )}
            >
              <Button
                role="tab"
                icon={
                  partialCore || errors || pErrors ? (
                    <Icon icon={<Alert />} />
                  ) : undefined
                }
                aria-selected={tab === g.id ? true : false}
                data-cy="tabButton"
                variant="tab"
                onClick={select(g.id)}
                className={tab === g.id ? styles.activeTab : styles.tab}
              >
                {g.index && `${g.index}. `}
                {title || t('cutlist-form.empty')}
              </Button>
              <Button
                variant="icon"
                className="delete"
                style={{
                  border: '1px solid var(--gray-5)',
                }}
                icon={<Icon icon={<Trash />} />}
                onClick={() =>
                  setGroupToRemoveData({ ulid: g.ulid, groupId: g.id })
                }
              />
            </div>
          );
        })}

      <Button
        disabled={!hasMaterials}
        name="add-group"
        icon={<Icon icon={<Layer />} />}
        onClick={() => {
          window.analytics.track('Cutlist Material Group Added');

          const sheetEdgeTrimConfig = getTrimConfigByType(
            supportedSheetEdgeTrims,
            defaultSheetEdgeTrimTypeForPartGroup
          );
          addGroup({ sheetEdgeTrimConfig });
        }}
        variant="secondaryPill"
      >
        {t('cutlist-form.cta.addGroup')}
      </Button>

      <ConfirmationDialog
        title={t('cutlist-form.confirmationDialog.title')}
        onConfirm={() => {
          onRemoveGroup(groupToRemoveData);
          setGroupToRemoveData(null);
        }}
        onClose={() => setGroupToRemoveData(null)}
        isOpen={!!groupToRemoveData}
        variant="alert"
      >
        {t('cutlist-form.confirmationDialog.content')}
      </ConfirmationDialog>
    </section>
  );
};
