import {
  defaultNestingConfig,
  NestingConfig,
} from '@cutr/constants/cutlist-nesting';

export function createNestingConfig(
  nestingConfig: Partial<NestingConfig> = {}
): NestingConfig {
  return {
    ...defaultNestingConfig,
    ...nestingConfig,
  };
}
