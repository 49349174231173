import { Material, PartItem } from '@cutr/constants/cutlist';
import { EdgeProfileType } from '@cutr/constants/edge-profiling';
import React from 'react';
import { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreApi } from 'zustand';
import { createStore, useStore as useZustandStore } from 'zustand';

import { useActiveGroup } from '@/api/materialsGroup';
import { EdgesPreview } from '@/blocks/EdgesPreview';
import { Button } from '@/primitives/Button';
import { Checkbox } from '@/primitives/Checkbox';
import { Icon, SelectedEdges } from '@/primitives/Icons';
import { isTruthy } from '@/utils/misc';

import styles from './EdgeProfiling.module.css';
import { EdgeProfileDropdown } from './PartTable/EdgeProfileDropdown';

export const EDGEPROFILING_SIDES: (keyof EdgeProfilingModalState['edges'])[] = [
  'l1',
  'l2',
  'w1',
  'w2',
];
export type EdgeProfilingSide = 'l1' | 'l2' | 'w1' | 'w2';

const useStore = () => {
  const storeApi = useContext(Context);
  if (!storeApi) throw new Error('Missing Context.Provider in the tree.');
  return useZustandStore(storeApi);
};

interface EdgeProfilingModalState {
  edges: {
    l1: EdgeProfileType;
    l2: EdgeProfileType;
    w1: EdgeProfileType;
    w2: EdgeProfileType;
    all: EdgeProfileType;
  };
  matchAll: boolean;
  setMatchAll: (match: boolean) => void;
  setEdge: (
    key: keyof EdgeProfilingModalState['edges'],
    id: Material['articleCode'] | null
  ) => void;
  setEdges: (state: InitialEdges) => void;
}

const Context = createContext<StoreApi<EdgeProfilingModalState> | null>(null);

const INITIAL_STATE = { matchAll: false } as InitialEdges;
export const useEdgeProfilingState = ({
  matchAll,
  ...edges
}: InitialEdges = INITIAL_STATE) =>
  createStore<EdgeProfilingModalState>((set) => ({
    edges,
    matchAll: matchAll,
    setEdges: ({ matchAll, ...edges }) => set(() => ({ matchAll, edges })),
    setMatchAll: (matchAll) => set(() => ({ matchAll })),
    setEdge: (key, type) =>
      set((state) => {
        const edges = { ...state.edges, [key]: type };
        return { edges };
      }),
  }));

export const useEdgeProfiling = () => {
  const { edges, matchAll } = useStore();

  if (matchAll) {
    const edgeProfilingType = edges.all;
    return {
      matchAll,
      l1: edgeProfilingType,
      l2: edgeProfilingType,
      w1: edgeProfilingType,
      w2: edgeProfilingType,
    };
  }

  const { l1, l2, w1, w2 } = edges;
  return {
    matchAll: false,
    l1,
    l2,
    w1,
    w2,
  };
};

export type UseEdgeProfiling = ReturnType<typeof useEdgeProfiling>;

export const EdgeProfilingContextProvider = ({
  children,
  initialEdges,
}: {
  children: React.ReactNode;
  initialEdges?: InitialEdges;
}) => {
  const storeRef = React.useRef<StoreApi<EdgeProfilingModalState>>();
  if (!storeRef.current) {
    storeRef.current = useEdgeProfilingState(initialEdges as InitialEdges);
  }

  return (
    <Context.Provider value={storeRef.current}>
      {children}
      <Syncer initialEdges={initialEdges as InitialEdges} />
    </Context.Provider>
  );
};

// it syncs the main app store with the context when edgeProfiling changes
const Syncer = ({ initialEdges }: { initialEdges: InitialEdges }) => {
  const { setEdges } = useStore();
  React.useEffect(() => {
    setEdges(initialEdges);
  }, [initialEdges]);
  return null;
};

export const EdgeProfileIcon = ({ onClick }: { onClick: () => void }) => {
  const edges = useEdgeProfiling();
  const { t } = useTranslation();

  const sides = [edges.l1, edges.l2, edges.w1, edges.w2].filter(isTruthy);
  const hasEdges = Boolean(sides.length);
  const codes = sides.filter((v, i, a) => a.indexOf(v) === i);

  const multipleEdgesLabel = (() => {
    if (!hasEdges) return t('cutlist-form.field.edgeProfiling.status.notSet');
    if (codes.length === 1)
      return t('cutlist-form.field.edgeProfiling.status.custom');

    return t('cutlist-form.field.edgeProfiling.status.mixed');
  })();

  const matchAllLabel = (() => {
    if (edges.l1) return t(`cutlist-form.edgeProfiling.${edges.l1}`);

    return t('cutlist-form.edgeProfiling.none');
  })();

  return (
    <Button onClick={onClick} variant="icon" className="outline full">
      <div className="flexAlign gap-xs">
        <Icon
          size={1.5}
          icon={
            <SelectedEdges
              borders={[
                Boolean(edges.l2),
                Boolean(edges.w2),
                Boolean(edges.l1),
                Boolean(edges.w1),
              ]}
            />
          }
        />
        {edges.matchAll ? matchAllLabel : multipleEdgesLabel}
      </div>
    </Button>
  );
};

type EdgeProfilingModalProps = {
  onSave: (edges: EdgeProfilingEdges) => void;
  part: {
    label: string;
    size: [number, number];
    quantity: number;
  };
};

const EdgeProfilingInterface = ({ onSave, part }: EdgeProfilingModalProps) => {
  const { matchAll, setMatchAll } = useStore();
  const edges = useEdgeProfiling();
  const { t } = useTranslation();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMatchAll(e.target.checked);
  };

  return (
    <div>
      <EdgesPreview
        label={part.label}
        size={part.size}
        quantity={part.quantity}
        edges={edges}
      />
      <div className="stack">
        <div className="fitWidth">
          <Checkbox isSwitch defaultChecked={matchAll} onChange={onChange}>
            <strong>
              {t('cutlist-form.field.edgeProfiling.matchAllToggle')}
            </strong>
          </Checkbox>
        </div>
        {matchAll && <Selector side="all" />}
        {!matchAll && <EdgeProfilingGrid />}

        <Button onClick={() => onSave(edges)}>{t('common.cta.save')}</Button>
      </div>
    </div>
  );
};

const EdgeProfilingGrid = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.grid}>
      {EDGEPROFILING_SIDES.map((label) => (
        <div key={label}>
          <label>{t(`cutlist-form.field.edgebanding.sides.${label}`)}</label>
          <Selector side={label} />
        </div>
      ))}
    </div>
  );
};

const Selector = ({
  side,
}: {
  side: keyof EdgeProfilingModalState['edges'];
}) => {
  const { edges, setEdge } = useStore();
  const value = edges[side];
  const activeGroup = useActiveGroup()!;

  const groupEdgeProfiling = activeGroup.edgeProfile;

  const onEdgeProfileTypeUpdate = (value: string) => {
    setEdge(side, value);
  };
  return (
    <EdgeProfileDropdown
      group={activeGroup}
      onEdgeProfileTypeUpdate={onEdgeProfileTypeUpdate}
      value={value}
      materialGroupValue={groupEdgeProfiling}
    />
  );
};
export type EdgeProfilingEdges = ReturnType<typeof useEdgeProfiling>;
export default EdgeProfilingInterface;

export function parseEdgeProfiling(edges: PartItem['edgeProfile']) {
  const sides = [edges?.length1, edges?.length2, edges?.width1, edges?.width2];
  const matchAll =
    Boolean(sides.filter(Boolean).length) &&
    sides.every((val) => val === sides[0]);

  const res = {
    l1: (!matchAll && sides[0]) || 'none',
    l2: (!matchAll && sides[1]) || 'none',
    w1: (!matchAll && sides[2]) || 'none',
    w2: (!matchAll && sides[3]) || 'none',
    all: (matchAll && sides[0]) || 'none',
    matchAll,
  };

  return res;
}
export type InitialEdges = ReturnType<typeof parseEdgeProfiling>;
