import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { api, ApiError } from '@/api/backend';
import { AuthState, useAuthStore } from '@/api/login';
import { Button } from '@/primitives/Button';
import { Input } from '@/primitives/Input';
import { useThemeConfig } from '@/theme';

import { ErrorMessage } from './ErrorMessage';
import styles from './LoginForm.module.css';
const ENVIRONMENT = import.meta.env.VITE_CUTR_ENV;

interface LoginFormProps {
  email?: string;
}

export const LoginForm = ({ email }: LoginFormProps) => {
  const { login } = useAuthStore();
  const { source, newAccountUrl, seeCustomerNumberUrl } = useThemeConfig();
  const { t } = useTranslation();
  const [error, setError] = React.useState('');
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const params = useParams();

  // login form is aware of its location in the app and can use this to determine if it is being used in a cutlist context
  const cutlistId = pathname.includes('/cutlist') ? params.id : undefined;

  const navigate = useNavigate();
  const redirect = decodeURIComponent(
    searchParams.get('redirect') ||
      (cutlistId && `/cutlist/${cutlistId}/parts`) ||
      '/'
  );

  const devLogin: React.FormEventHandler<HTMLFormElement> = (e) => {
    setError('');
    api
      .devLogin()
      .then((data) => {
        const { email, clientNumber, token } = data as AuthState;
        login({ email, clientNumber, token });
        navigate(redirect);
      })
      .catch((e) => {
        handleError(e);
        console.error(e);
      });
    e.preventDefault();
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    const formData = new FormData(e.currentTarget);
    setError('');
    api
      .login(
        formData.get('clientNumber') as string,
        email || (formData.get('email') as string),
        source,
        cutlistId
      )
      .then((data) => {
        const { email, clientNumber, token } = data as AuthState;
        login({ email, clientNumber, token });
        navigate(redirect);
      })
      .catch((e) => {
        handleError(e);
        console.error(e);
      });
    e.preventDefault();
  };

  const handleError = (error: ApiError) => {
    const code = error.response?.status;
    if (code === 500) {
      setError(t(`landing-page.login.errors.notAvailable`));
      return;
    }
    if (code === 404) {
      setError(t(`landing-page.login.errors.notFound`));
      return;
    }
    if (code === 429) {
      setError(t(`landing-page.login.errors.tooManyRequests`));
      return;
    }

    setError(t(`landing-page.login.errors.notAvailable`));
    return;
  };

  const stripPrefix = (url: string) =>
    url.replace(/^(https?:\/\/)?(www\.)?/, '');

  return (
    <div className={styles.formContainer}>
      <div className={styles.header}>
        <h2>{t(`landing-page.login.title`)}</h2>
        {email && <p>{t(`unauth-quote.existingUserSignInPrompt`)}</p>}
        {!email && (
          <p>
            {t(`landing-page.login.body`)}{' '}
            <a href={newAccountUrl} target="_blank" rel="noreferrer">
              {stripPrefix(newAccountUrl || '')}
            </a>
          </p>
        )}
        {Boolean(error) && <ErrorMessage message={error} />}
      </div>

      <form onSubmit={onSubmit}>
        <div>
          <label htmlFor="email">
            {t(`landing-page.login.email`)} {'*'}
          </label>
          <div className={styles.help}>{t(`landing-page.login.emailHelp`)}</div>
          <Input
            type="email"
            name="email"
            disabled={!!email}
            value={email ? email : undefined}
            required
          />
        </div>
        <div>
          <label htmlFor="clientNumber">
            {t(`landing-page.login.customerNumber`)} {'*'}
          </label>
          <div className={styles.help}>
            {t(`landing-page.login.customerNumberHelp`)}{' '}
            <a
              href={`${seeCustomerNumberUrl}`}
              target="_blank"
              rel="noreferrer"
            >
              {stripPrefix(seeCustomerNumberUrl || '')}
            </a>
          </div>
          <Input
            type="text"
            name="clientNumber"
            required
            pattern="[0-9]{3,7}"
          />
        </div>
        <Button
          type="submit"
          style={{ marginTop: '1rem' }}
          data-cy="loginButton"
        >
          {t(`landing-page.login.submit`)}
        </Button>
      </form>
      {ENVIRONMENT === 'development' && (
        <form onSubmit={devLogin}>
          <Button type="submit">
            {'Sign in as a developer (only visible in dev environment)'}
          </Button>
        </form>
      )}
    </div>
  );
};
