import { Outlet, useLocation } from 'react-router-dom';

import { NotFound } from './Errors';

export const AgentQuoteFlow = () => {
  const { pathname } = useLocation();

  if (pathname.includes('/quote')) {
    return (
      <main>
        <Outlet />
      </main>
    );
  }

  return <NotFound />;
};
