import { MaterialGroup, SheetEdgeTrimType } from '@cutr/constants/cutlist';
import { useTranslation } from 'react-i18next';

import { Dropdown } from '@/primitives/Dropdown';
import {
  Icon,
  SheetEdgeTrimAllSides,
  SheetEdgeTrimLength2Only,
  SheetEdgeTrimNoSide,
  Yep,
} from '@/primitives/Icons';
import { useCurrentFeatures } from '@/theme';

import styles from './dropdown.module.css';

export const sheetEdgeTrimIconMap = {
  'no-side': <SheetEdgeTrimNoSide />,
  'length2-only': <SheetEdgeTrimLength2Only />,
  'all-sides': <SheetEdgeTrimAllSides />,
};

export const SheetEdgeTrimDropdown = ({
  group,
  onSheetEdgeTrimTypeUpdate,
}: {
  group: MaterialGroup;
  onSheetEdgeTrimTypeUpdate: (sheetEdgeTrimType: SheetEdgeTrimType) => void;
}) => {
  const { t } = useTranslation();
  const { supportedSheetEdgeTrims } = useCurrentFeatures();
  const isSheetOnly = group.type === 'sheets-only';

  const options = supportedSheetEdgeTrims
    .filter((supportedSheetEdgeTrim) => {
      if (isSheetOnly) {
        return supportedSheetEdgeTrim.allowForFullSheets;
      }

      return true;
    })
    .map(({ sheetEdgeTrimType }) => ({
      sheetEdgeTrimType,
      label: t(`cutlist-form.sheetEdgeTrimType.${sheetEdgeTrimType}`),
      isSelected:
        group.sheetEdgeTrimConfig?.sheetEdgeTrimType === sheetEdgeTrimType,
    }));

  return (
    <Dropdown
      id={`sheet-edges-${group.id}`}
      onSelected={(value) =>
        onSheetEdgeTrimTypeUpdate(value as SheetEdgeTrimType)
      }
      options={options.map((option) => {
        return {
          value: option.sheetEdgeTrimType,
          selected: option.isSelected,
          component: ({ isSelected }) => (
            <SheetEdgeTrimItem
              sheetEdgeTrimType={option.sheetEdgeTrimType}
              label={option.label}
              isSelected={isSelected}
            />
          ),
        };
      })}
    />
  );
};

export const SheetEdgeTrimItem = ({
  sheetEdgeTrimType,
  label,
  isSelected,
}: {
  sheetEdgeTrimType: SheetEdgeTrimType;
  label: string;
  isSelected?: boolean;
}) => {
  return (
    <div className={styles.dropdownItem}>
      <div>
        <Icon icon={sheetEdgeTrimIconMap[sheetEdgeTrimType]} size={4} />
      </div>

      <div className={styles.dropdownName}>
        <div>
          <strong>{label}</strong>
        </div>
      </div>
      <div>{isSelected && <Icon icon={<Yep />} />}</div>
    </div>
  );
};
