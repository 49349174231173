import {
  NestingConfig,
  Orientation,
  PartIn,
  Sheet,
  SheetConfig,
} from '@cutr/constants/cutlist-nesting';
import { defaultSheetEdgeTrimConfigNoSide } from '@cutr/constants/cutlist-theme';

import { Offcut } from './offcut';
import { sheetTrimmedDimensions } from './sheet';

export type PanelSortScenario = 'length' | 'area';
export const PANEL_SORT_SCENARIOS: PanelSortScenario[] = ['length', 'area'];

export function initSheetConfig(sheetConfig: SheetConfig): SheetConfig {
  if (sheetConfig.hasGrain) return sheetConfig;
  else
    return {
      ...sheetConfig,
      lengthMM: Math.max(sheetConfig.lengthMM, sheetConfig.widthMM),
      widthMM: Math.min(sheetConfig.lengthMM, sheetConfig.widthMM),
      hasGrain: false,
    };
}

export function maxReloadDepth(sheets: Sheet[]): number {
  return sheets
    .flatMap((sheet) => sheet.parts)
    .reduce((maxReloadDepth, part) => {
      return Math.max(maxReloadDepth, part.reloadDepth);
    }, 0);
}

// Checks if a 'PartIn' fits into a 'SheetConfig' in given orientation of the part and grains.
export function partFitsSheet(
  part: PartIn,
  partOrientation: Orientation,
  sheetConfig: SheetConfig
): boolean {
  // part cares about grain direction, but offcut does not. This should not be allowed.
  if (part.hasGrain && !sheetConfig.hasGrain)
    throw new Error('Fitting a grain into a non-grain is not allowed.');

  // Grain directions mismatch, does not fit.
  if (partOrientation === 'vertical' && part.hasGrain && sheetConfig.hasGrain)
    return false;

  // In all other cases, perform a rectangle size comparison.
  const partLength =
    partOrientation === 'horizontal' ? part.lengthMM : part.widthMM;
  const partWidth =
    partOrientation === 'horizontal' ? part.widthMM : part.lengthMM;

  // Trims the damaged edges of the sheet.
  const { lengthMM: sheetLength, widthMM: sheetWidth } =
    sheetTrimmedDimensions(sheetConfig);

  return partLength <= sheetLength && partWidth <= sheetWidth;
}

// Checks if a 'PartIn' fits into an 'Offcut' in given orientation of the part and grains.
export function partFitsOffcut(
  part: PartIn,
  partOrientation: Orientation,
  offcut: Offcut
): boolean {
  // Grain directions mismatch, does not fit.
  if (
    partOrientation === 'vertical' &&
    part.hasGrain &&
    offcut.sheetConfig.hasGrain
  )
    return false;

  // Rotate part in the given orientation.
  const partLength =
    partOrientation === 'horizontal' ? part.lengthMM : part.widthMM;
  const partWidth =
    partOrientation === 'horizontal' ? part.widthMM : part.lengthMM;

  return partLength <= offcut.lengthMM && partWidth <= offcut.widthMM;
}

export function fittingCount(
  partToSaw: PartIn,
  offcutToSaw: Offcut,
  partOrientation: Orientation,
  sawOrientation: Orientation,
  nestingConfig: NestingConfig
) {
  const partSideA =
    partOrientation == sawOrientation ? partToSaw.lengthMM : partToSaw.widthMM;

  const partSideB =
    partOrientation == sawOrientation ? partToSaw.widthMM : partToSaw.lengthMM;

  const offcutSideA =
    sawOrientation == 'horizontal' ? offcutToSaw.lengthMM : offcutToSaw.widthMM;

  const offcutSideB =
    sawOrientation == 'horizontal' ? offcutToSaw.widthMM : offcutToSaw.lengthMM;

  return {
    amountsToSaw: Math.min(
      partToSaw.quantity,
      Math.floor(
        (offcutSideA + nestingConfig.bladeThickness) /
          (partSideA + nestingConfig.bladeThickness)
      )
    ),
    amountsFit: Math.min(
      partToSaw.quantity,
      Math.floor(
        (offcutSideA + nestingConfig.bladeThickness) /
          (partSideA + nestingConfig.bladeThickness)
      ) *
        Math.floor(
          (offcutSideB + nestingConfig.bladeThickness) /
            (partSideB + nestingConfig.bladeThickness)
        )
    ),
  };
}

export function area(rect: { widthMM: number; lengthMM: number }): number {
  return rect.widthMM * rect.lengthMM;
}

export type PartFitErrorData = {
  name: string;
  id: string;
  partLengthMM: number;
  partWidthMM: number;
  sheetLengthMM: number;
  sheetWidthMM: number;
  hint?: 'fullSheetHint' | 'edgeTrimHint';
};
export class PartFitError extends Error {
  constructor(message: string, public data: PartFitErrorData) {
    super(message);
    this.name = 'PartFitError';
    this.data = data;
  }
}

export function partsFitSheet(parts: PartIn[], sheetConfig: SheetConfig) {
  const sheetConfigWithoutEdgeTrim: SheetConfig = {
    ...sheetConfig,
    sheetEdgeTrimConfig: defaultSheetEdgeTrimConfigNoSide,
  };
  const fitsWithoutEdgeTrim = (part: PartIn) => {
    return (
      partFitsSheet(part, 'horizontal', sheetConfigWithoutEdgeTrim) ||
      partFitsSheet(part, 'vertical', sheetConfigWithoutEdgeTrim)
    );
  };
  const partSameAsFullSheet = (part: PartIn) => {
    return (
      part.lengthMM === sheetConfig.lengthMM &&
      part.widthMM === sheetConfig.widthMM
    );
  };

  // Requested parts should all fit into material sheets, in at least 1 orientation.
  parts.forEach((part) => {
    if (
      !partFitsSheet(part, 'horizontal', sheetConfig) &&
      !partFitsSheet(part, 'vertical', sheetConfig)
    ) {
      const partFitErrorData: PartFitErrorData = {
        name: part.name,
        id: part.id,
        partLengthMM: part.lengthMM,
        partWidthMM: part.widthMM,
        sheetLengthMM: sheetConfig.lengthMM,
        sheetWidthMM: sheetConfig.widthMM,
      };
      if (partSameAsFullSheet(part)) {
        partFitErrorData.hint = 'fullSheetHint';
      } else if (fitsWithoutEdgeTrim(part)) {
        partFitErrorData.hint = 'edgeTrimHint';
      }

      throw new PartFitError(
        `Part "${part.name}" (${part.lengthMM}x${part.widthMM}) does not fit sheet (${sheetConfig.lengthMM}x${sheetConfig.widthMM}).`,
        partFitErrorData
      );
    }
  });
}
