import './Select.css';

import cn from 'classnames';

type SelectOption = {
  disabled?: boolean;
  selected?: boolean;
  label: string;
  value: string;
};
type SelectCustomProps = {
  options: SelectOption[];
};
type SelectProps = React.InputHTMLAttributes<HTMLSelectElement> &
  SelectCustomProps;

export const Select = ({ className, options, ...props }: SelectProps) => {
  const hasSelected = options.find((opt) => opt.selected);

  return (
    <select
      className={cn('select', className)}
      {...props}
      defaultValue={hasSelected ? hasSelected.value : ''}
    >
      {!hasSelected && <option disabled />}
      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
      {options.map(({ label, selected, ...props }) => (
        <option {...props} key={props.value}>
          {label}
        </option>
      ))}
    </select>
  );
};
