import { BackendCutlistGroup } from '@cutr/constants/cutlist-backend';
import { useQueryClient } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import { agentApi } from '@/api/backend/agent';

import { cutlistsKeys } from './keys';
import { agentKeys } from './keys';

export const useCreateMaterialGroup = (cutlistId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (materialGroup: BackendCutlistGroup) =>
      agentApi.createMaterialGroup(cutlistId, materialGroup),
    mutationKey: agentKeys.createMaterialGroup,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: cutlistsKeys.getOne(cutlistId),
        refetchType: 'all',
      });
    },
  });
};

export const useUpdateMaterialGroup = (cutlistId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      materialGroupId,
      materialGroup,
    }: {
      materialGroupId: string;
      materialGroup: BackendCutlistGroup;
    }) =>
      agentApi.updateMaterialGroup(cutlistId, materialGroupId, materialGroup),
    mutationKey: agentKeys.updateMaterialGroup,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: cutlistsKeys.getOne(cutlistId),
        refetchType: 'all',
      });
    },
  });
};

export const useDeleteMaterialGroup = (cutlistId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (materialGroupId: string) =>
      agentApi.deleteMaterialGroup(cutlistId, materialGroupId),
    mutationKey: agentKeys.deleteMaterialGroup,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: cutlistsKeys.getOne(cutlistId),
        refetchType: 'all',
      });
    },
  });
};
