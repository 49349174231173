import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  useActiveGroupGrainDirection,
  useEdgebandSelected,
} from '@/api/materialsGroup';
import { Checkbox } from '@/primitives/Checkbox';
import { Icon, Layer } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { useCurrentFeatures } from '@/theme';

import styles from '../PartTableCommon/styles.module.css';
import { TableRowActions } from '../PartTableCommon/TableRowActions';
import { TableRowProps } from '../PartTableCommon/types';

export const TableRowSheet = ({
  part,
  hasError,
  onPartUpdate,
  onPartDelete,
  isFieldValid,
  validateNumericInput,
}: TableRowProps) => {
  const { t } = useTranslation();
  const hasGrainDirection = useActiveGroupGrainDirection();
  const { edgebandingUI, hasEdgeProfiling, hasCreateLabels } =
    useCurrentFeatures();
  const isEdgebandSelected = useEdgebandSelected();
  const hasEdgebanding = isEdgebandSelected && edgebandingUI;

  const paddingCellsCount = [
    hasEdgeProfiling || hasEdgebanding ? 5 : 0,
    hasGrainDirection ? 1 : 0,
  ].reduce((a, b) => a + b, 0);

  return (
    <tr
      id={`sheetRow-${part.id}`}
      className={hasError ? styles.errors : undefined}
    >
      <td>
        {
          <span className="flexAlign gap-xs">
            <Icon
              className={cn(styles.unflex, 'tooltip')}
              data-tooltip={t('cutlist-form.sheet')}
              icon={<Layer />}
              color="var(--gray-7)"
            />
            {t('cutlist-form.field.fullSheet')}
          </span>
        }
      </td>
      <td>
        <Input
          className={isFieldValid('quantity') ? 'snug' : 'snug invalid'}
          type="number"
          value={part.quantity}
          name={`quantity_${part.id}`}
          key={`quantity_${part.id}`}
          onInput={(e) => {
            onPartUpdate(
              'quantity',
              validateNumericInput('quantity', e.currentTarget.value)
            );
          }}
        />
      </td>
      <td>
        <Input
          className="snug"
          value={part.lengthMM}
          disabled={true}
          name={`length_${part.id}`}
          key={`length_${part.id}`}
        />
      </td>
      <td>
        <Input
          className="snug"
          value={part.widthMM}
          disabled={true}
          name={`width_${part.id}`}
          key={`width_${part.id}`}
        />
      </td>
      {/* add padding so delete buttons align */}
      {[...Array(paddingCellsCount).keys()].map((nr) => (
        <td key={nr}></td>
      ))}
      {hasCreateLabels === 'part' && (
        <td>
          <Checkbox
            checked={Boolean(part.createLabel)}
            onChange={(e) => onPartUpdate('createLabel', e.target.checked)}
          />
        </td>
      )}
      <td style={{ '--spaceX': '0.1rem' } as React.CSSProperties}>
        <TableRowActions part={part} onPartDelete={onPartDelete} />
      </td>
    </tr>
  );
};
