// @ts-nocheck - mock is not typed properly
import { Material } from '@cutr/constants/cutlist';
import React from 'react';
import { PropsWithChildren } from 'react';

import { cacheMaterials, materialsCached } from '@/api/materials';

export const MaterialsProvider = ({ children }: PropsWithChildren) => {
  React.useEffect(() => {
    if (!materialsCached()) {
      cacheMaterials(mockMaterials);
    }
  }, []);
  return <>{children}</>;
};

export const mockMaterials: Material[] = [
  {
    articleCode: '117461',
    thicknessMM: 18,
    unitAmount: 6552,
    m2Amount: 2148,
    nameWithCOCClaim: 'Populieren multiplex 250x122cm (PEFC 70%)',
    supplierCode: 'BNB',
    name: 'Populieren multiplex 250x122cm',
    originalName: 'Populieren multiplex 250x122cm PEFC',
    brand: null,
    series: null,
    colorCode: null,
    structureName: null,
    structureCode: null,
    shortDescription: 'K2 Populieren multiplex b/bb mr 250x122cm 18mm',
    longDescription: null,
    imageUrl: null,
    sourceName: null,
    sourceLink: null,
    supplierERPCode: null,
    type: 'plywood',
    lengthMM: 2500,
    widthMM: 1220,
    thicknessUM: 18000,
    priceSyncedAt: '2024-07-05T12:06:24.826Z',
    priceAmount: 6552,
    priceUnit: 'piece',
    weightMG: 24705000,
    weightUnit: 'piece',
    sawingAllowed: null,
    paintingAllowed: null,
    facetEdgeProfileAllowed: null,
    roundEdgeProfileAllowed: null,
    angledEdgeProfileAllowed: null,
    angledEdgeProfileType: null,
    allowedEdgeProfiles: [],
    hasGrainDirection: true,
    hasTextureDirection: false,
    hasVisibleSide: null,
    gluingAndPressingAllowed: true,
    length1TrimThicknessMM: null,
    length2TrimThicknessMM: null,
    width1TrimThicknessMM: null,
    width2TrimThicknessMM: null,
    fscClaim: null,
    pefcClaim: 'pefc_70',
    id: '01J2BEPNXSGHG4CBPQWE2PP0CP',
    createdAt: '2024-07-09T09:55:24.217Z',
    updatedAt: '2024-07-09T09:55:24.217Z',
    archivedAt: null,
    variationGroupId: null,
  },
  {
    articleCode: '372589',
    thicknessMM: 0.8,
    unitAmount: 2800,
    m2Amount: 2435,
    nameWithCOCClaim: 'ABS KB bestemd voor Pfleiderer R34032 NW',
    supplierCode: 'BNB',
    name: 'ABS KB bestemd voor Pfleiderer R34032 NW',
    originalName: 'ABS KB bestemd voor Pfleiderer R34032 NW',
    brand: 'Rehau',
    series: null,
    colorCode: 'R34032',
    structureName: 'Wood',
    structureCode: 'NW',
    shortDescription: 'Kantenband ABS Rehau R34032 NW 23x0,8mm 50 meter rol',
    longDescription: null,
    imageUrl: null,
    sourceName: null,
    sourceLink: null,
    supplierERPCode: null,
    type: 'edgeband',
    lengthMM: 50000,
    widthMM: 23,
    thicknessUM: 800,
    priceSyncedAt: '2024-07-05T12:06:24.826Z',
    priceAmount: 2800,
    priceUnit: 'piece',
    weightMG: 1000000,
    weightUnit: 'piece',
    sawingAllowed: null,
    paintingAllowed: null,
    facetEdgeProfileAllowed: null,
    roundEdgeProfileAllowed: null,
    angledEdgeProfileAllowed: null,
    angledEdgeProfileType: null,
    allowedEdgeProfiles: [],
    hasGrainDirection: false,
    hasTextureDirection: false,
    hasVisibleSide: null,
    gluingAndPressingAllowed: false,
    length1TrimThicknessMM: null,
    length2TrimThicknessMM: null,
    width1TrimThicknessMM: null,
    width2TrimThicknessMM: null,
    fscClaim: null,
    pefcClaim: null,
    id: '01J2BEPNZDADXF4CDW87HZPZFF',
    createdAt: '2024-07-09T09:55:24.217Z',
    updatedAt: '2024-07-09T09:55:24.217Z',
    archivedAt: null,
    variationGroupId: null,
  },
  {
    articleCode: '354656',
    thicknessMM: 18,
    unitAmount: 9890,
    m2Amount: 1682,
    nameWithCOCClaim: 'Thermopal Spaanplaat R34032 NW (PEFC 100%)',
    supplierCode: 'BNB',
    name: 'Thermopal Spaanplaat R34032 NW',
    originalName: 'Thermopal Spaanplaat R34032 NW',
    brand: 'Thermopal',
    series: 'Repro',
    colorCode: 'R34032',
    structureName: 'Wood',
    structureCode: 'NW',
    shortDescription: 'K1 Spaan Thermopal R34032 NW (4) 2800x2100x18mm',
    longDescription: null,
    imageUrl: null,
    sourceName: null,
    sourceLink: null,
    supplierERPCode: null,
    type: 'melamine-chipboard',
    lengthMM: 2800,
    widthMM: 2100,
    thicknessUM: 18000,
    priceSyncedAt: '2024-07-05T12:06:24.826Z',
    priceAmount: 9890,
    priceUnit: 'piece',
    weightMG: 74088000,
    weightUnit: 'piece',
    sawingAllowed: null,
    paintingAllowed: null,
    facetEdgeProfileAllowed: null,
    roundEdgeProfileAllowed: null,
    angledEdgeProfileAllowed: null,
    angledEdgeProfileType: null,
    allowedEdgeProfiles: [],
    hasGrainDirection: true,
    hasTextureDirection: false,
    hasVisibleSide: null,
    gluingAndPressingAllowed: false,
    length1TrimThicknessMM: null,
    length2TrimThicknessMM: null,
    width1TrimThicknessMM: null,
    width2TrimThicknessMM: null,
    fscClaim: null,
    pefcClaim: 'pefc_100',
    id: '01J2BEPNZ3DX2J3AXPVYCT1JSD',
    createdAt: '2024-07-09T09:55:24.217Z',
    updatedAt: '2024-07-09T09:55:24.217Z',
    archivedAt: null,
    variationGroupId: null,
  },
  // @ts-expect-error - This is a mock function
  {
    articleCode: '221105',
    thicknessMM: 19,
    unitAmount: 11788,
    m2Amount: 3168,
    nameWithCOCClaim: 'MDF Gefineerd Allegro A/B',
    supplierCode: 'BNB',
    name: 'MDF Gefineerd Allegro A/B',
    brand: 'Decospan',
    series: 'Querkus',
    colorCode: null,
    structureName: null,
    structureCode: null,
    shortDescription:
      'MDF Gef. Allegro eiken A mixmatch/B 3050x1220x19mm 0,6 fin',
    imageUrl: null,
    sourceLink: null,
    type: 'veneer-mdf',
    lengthMM: 3050,
    widthMM: 1220,
    thicknessUM: 19000,
    priceSyncedAt: '2024-07-05T12:06:24.826Z',
    priceAmount: 11788,
    priceUnit: 'piece',
    sawingAllowed: null,
    paintingAllowed: null,
    facetEdgeProfileAllowed: null,
    roundEdgeProfileAllowed: null,
    angledEdgeProfileAllowed: null,
    angledEdgeProfileType: null,
    allowedEdgeProfiles: [],
    hasGrainDirection: true,
    hasTextureDirection: false,
    hasVisibleSide: null,
    gluingAndPressingAllowed: false,
    length1TrimThicknessMM: null,
    length2TrimThicknessMM: null,
    width1TrimThicknessMM: null,
    width2TrimThicknessMM: null,
    fscClaim: null,
    pefcClaim: null,
    id: '01J2BEPNY77NZ8J7YJJ57HAZY1',
    updatedAt: '2024-07-09T09:55:24.217Z',
    variationGroupId: null,
    variationGroup: null,
  },
  // @ts-expect-error - This is a mock function
  {
    articleCode: '170878',
    thicknessMM: 25,
    unitAmount: 7438,
    m2Amount: 1999,
    nameWithCOCClaim: 'MDF Medite V313 (FSC Mix 70%)',
    supplierCode: 'BNB',
    name: 'MDF Medite V313',
    brand: 'Medite',
    series: null,
    colorCode: null,
    structureName: null,
    structureCode: null,
    shortDescription: 'FM MDF Medite V313 305x122cm 25mm',
    imageUrl: null,
    sourceLink: null,
    type: 'mdf',
    lengthMM: 3050,
    widthMM: 1220,
    thicknessUM: 25000,
    priceSyncedAt: '2024-07-05T12:06:24.826Z',
    priceAmount: 7438,
    priceUnit: 'piece',
    sawingAllowed: null,
    paintingAllowed: null,
    facetEdgeProfileAllowed: null,
    roundEdgeProfileAllowed: null,
    angledEdgeProfileAllowed: null,
    angledEdgeProfileType: null,
    allowedEdgeProfiles: [],
    hasGrainDirection: false,
    hasTextureDirection: false,
    hasVisibleSide: null,
    gluingAndPressingAllowed: true,
    length1TrimThicknessMM: null,
    length2TrimThicknessMM: null,
    width1TrimThicknessMM: null,
    width2TrimThicknessMM: null,
    fscClaim: 'fsc_mix_70',
    pefcClaim: null,
    id: '01J2BEPNY5V6Y0134W3XYZPP46',
    updatedAt: '2024-07-09T09:55:24.217Z',
    variationGroupId: null,
    variationGroup: null,
  },
];
