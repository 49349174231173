import React from 'react';
import { Trans } from 'react-i18next';

import { Cutr, Icon } from '../primitives/Icons';
import styles from './CutrLabel.module.css';

const CutrLabel: React.FC = () => {
  return (
    <div className={styles.label}>
      <a href="https://www.cutr.com/cutlist" target="_blank" rel="noreferrer">
        <Trans
          i18nKey={'common.poweredBy'}
          components={[<Icon icon={<Cutr />} key="cutrIcon" />]}
        />
      </a>
    </div>
  );
};

export default CutrLabel;
