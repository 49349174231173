import { CutlistOrder, CutlistRequestType } from '@cutr/constants/cutlist';

import { api, checkError, isAuthorized, logError, serializeCutlist } from './';

export const cutlistsApi = {
  duplicateCutlist: (cutlistId: string): Promise<CutlistOrder> => {
    return fetch(api.createUrl(`${cutlistId}/copy`), {
      headers: { ...api.getHeaders() },
      credentials: 'include',
      method: 'POST',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  createCutlist: (title: string): Promise<CutlistOrder> => {
    const body = JSON.stringify({ title });
    const url = isAuthorized() ? 'new' : 'unauthenticated/new';

    return fetch(api.createUrl(url), {
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
      body,
      credentials: 'include',
      method: 'POST',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  getCutlist: (cutlistId: string): Promise<CutlistOrder> => {
    return fetch(api.createUrl(cutlistId), {
      headers: { ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  deleteCutlist: (cutlistId: string): Promise<Response> => {
    return fetch(api.createUrl(`${cutlistId}`), {
      headers: { ...api.getHeaders() },
      credentials: 'include',
      method: 'DELETE',
    })
      .then(checkError)
      .catch(logError);
  },
  getCutlists: (): Promise<CutlistOrder[]> => {
    return fetch(api.createUrl(), {
      headers: { ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  updateCutlist: (
    requestType: CutlistRequestType,
    submit: boolean,
    cutlistId: string,
    syncThirdParty: boolean
  ): Promise<CutlistOrder> => {
    const url = isAuthorized() ? cutlistId : `unauthenticated/${cutlistId}`;

    const body = JSON.stringify({
      ...serializeCutlist(),
      submit,
      requestType,
      syncThirdParty,
    });
    return fetch(api.createUrl(url), {
      method: 'POST',
      credentials: 'include',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },

  acceptQuote: (cutlistId: string): Promise<CutlistOrder> => {
    return fetch(api.createUrl(`${cutlistId}/accept-quote`), {
      headers: { ...api.getHeaders() },
      credentials: 'include',
      method: 'PUT',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
};
