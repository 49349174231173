import cn from 'classnames';
import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePricingStore, useQuotePriceAggregate } from '@/api/pricing';
import { useCutlistState } from '@/api/store';
import Card from '@/blocks/Card';
import { onFocusSelect } from '@/hooks';
import { Input } from '@/primitives/Input';
import { Select } from '@/primitives/Select';
import { currencyFormatter, currencyToDisplayMap } from '@/utils/format';
import { cleanNumberInput } from '@/utils/input';

import styles from './PriceSummary.module.css';

export const PriceSummary = ({
  children,
  isReadOnly = false,
}: PropsWithChildren & { isReadOnly?: boolean }) => {
  const { t } = useTranslation();
  const { vatRate, currency } = useCutlistState();
  const priceStore = usePricingStore();
  const { totalAmountExclVAT, totalAmountInclVAT } = priceStore;
  const { pricePerGroup, additionalCosts, discount, markup } =
    useQuotePriceAggregate();

  // @ts-ignore
  const hasInvalidDiscount = priceStore.error?.response.status === 412;

  return (
    <Card>
      <div className="opposites">
        <h3>{t('agent.quoteFlow.price')}</h3>
        <h3 className="isPrimary" data-cy="total-order-price">
          {currencyFormatter(totalAmountExclVAT / 100, currency)}
        </h3>
      </div>
      <div className={styles.divider}>
        {t('cutlist-summary.price.total.excludingTaxes')}
      </div>

      {priceStore.error && (
        <p className="errors">
          {hasInvalidDiscount
            ? priceStore.error.message
            : t('cutlist-summary.price.error')}
        </p>
      )}

      <ul className={cn(styles.cardPrice)}>
        {pricePerGroup.map(({ groupId, index, partCount, cost }) => {
          return (
            <React.Fragment key={`${groupId}-price-aggregate`}>
              <li className="flexAlign opposites">
                <div className="flexAlign gap-xs">
                  <span>
                    {t('agent.quoteEditor.materialNr', { count: index })}
                  </span>
                  {'•'}
                  <span>
                    {t('agent.quoteEditor.parts', { count: partCount })}
                  </span>
                </div>
                <div>{currencyFormatter(cost / 100, currency)}</div>
              </li>
            </React.Fragment>
          );
        })}
      </ul>
      <strong>{t('agent.quoteEditor.additionalCosts')}</strong>
      <ul className={styles.cardPrice}>
        {additionalCosts.map(({ id, categoryName, totalAmount }) => {
          return (
            <React.Fragment key={id}>
              <li className="flexAlign opposites">
                <span>{categoryName}</span>
                <span>{!totalAmount && '€ ---.--'}</span>
                <span>
                  {Boolean(totalAmount) &&
                    currencyFormatter(totalAmount / 100, currency)}
                </span>
              </li>
            </React.Fragment>
          );
        })}
      </ul>

      {(discount || markup) && (
        <>
          <strong>{t('agent.quoteEditor.priceAdjustments')}</strong>
          <ul className={styles.cardPrice}>
            {markup && (
              <li className="flexAlign opposites">
                <span>{markup.description}</span>
                <span>
                  {Boolean(markup.totalAmount) &&
                    currencyFormatter(markup.totalAmount / 100, currency)}
                </span>
              </li>
            )}
            {discount && (
              <li className="flexAlign opposites">
                <span>{discount.description}</span>
                <span>
                  {Boolean(discount.totalAmount) &&
                    currencyFormatter(discount.totalAmount / 100, currency)}
                </span>
              </li>
            )}
          </ul>
        </>
      )}

      {!isReadOnly && <MarkupForm />}
      {!isReadOnly && <DiscountForm />}

      <ul className={styles.totalPrice}>
        <li className="flexAlign opposites">
          <span>
            {t('cutlist-summary.price.vatPercent', {
              vatRate,
            })}
          </span>
          <span>
            {currencyFormatter(
              (totalAmountInclVAT - totalAmountExclVAT) / 100,
              currency
            )}
          </span>
        </li>

        <li className={styles.bolder}>
          <strong>{t('cutlist-summary.price.total.includingVAT')}</strong>
          <strong>
            {currencyFormatter(totalAmountInclVAT / 100, currency)}
          </strong>
        </li>
      </ul>

      {children}
    </Card>
  );
};

const DiscountForm = () => {
  const { t } = useTranslation();
  const {
    discountAmount,
    discountPercentage,
    setDiscountAmount,
    setDiscountPercentage,
  } = useCutlistState();
  const [discountType, setDiscountType] = useState<'percentage' | 'amount'>(
    discountPercentage ? 'percentage' : 'amount'
  );

  const clearDiscounts = () => {
    setDiscountAmount(undefined);
    setDiscountPercentage(undefined);
  };

  const handleDiscountPercentageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = Number(cleanNumberInput(e.target.value));

    if (value < 1) return;
    if (value > 100) return;

    setDiscountPercentage(value);
    setDiscountAmount(undefined);
  };

  const handleDiscountAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = Number(cleanNumberInput(e.target.value));

    if (value < 0) return;
    setDiscountAmount(value * 100);
    setDiscountPercentage(undefined);
  };

  const handleDiscountTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setDiscountType(e.currentTarget.value as 'percentage' | 'amount');
    clearDiscounts();
  };

  return (
    <div className="flexAlign opposites">
      <strong>{t('agent.discount.discount')}</strong>
      <div className="flexAlign gap-xs">
        {discountType === 'percentage' ? (
          <PercentageInput
            value={discountPercentage || 0}
            onChange={handleDiscountPercentageChange}
          />
        ) : (
          <AmountInput
            value={discountAmount ? discountAmount / 100 : 0}
            onChange={handleDiscountAmountChange}
          />
        )}
        <PriceAdjustmentTypeSelector
          type={discountType}
          onChange={handleDiscountTypeChange}
        />
      </div>
    </div>
  );
};

const MarkupForm = () => {
  const { t } = useTranslation();
  const {
    markupAmount,
    markupPercentage,
    setMarkupAmount,
    setMarkupPercentage,
  } = useCutlistState();
  const [markupType, setMarkupType] = useState<'percentage' | 'amount'>(
    markupPercentage ? 'percentage' : 'amount'
  );

  const clearMarkups = () => {
    setMarkupAmount(undefined);
    setMarkupPercentage(undefined);
  };

  const handleMarkupPercentageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = Number(cleanNumberInput(e.target.value));

    if (value < 1) return;
    if (value > 100) return;

    setMarkupPercentage(value);
    setMarkupAmount(undefined);
  };

  const handleMarkupAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(cleanNumberInput(e.target.value));

    if (value < 0) return;

    setMarkupAmount(value * 100);
    setMarkupPercentage(undefined);
  };

  const handleMarkupTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMarkupType(e.currentTarget.value as 'percentage' | 'amount');
    clearMarkups();
  };

  return (
    <div className="flexAlign opposites">
      <strong>{t('agent.markup')}</strong>
      <div className="flexAlign gap-xs">
        {markupType === 'percentage' ? (
          <PercentageInput
            value={markupPercentage || 0}
            onChange={handleMarkupPercentageChange}
          />
        ) : (
          <AmountInput
            value={markupAmount ? markupAmount / 100 : 0}
            onChange={handleMarkupAmountChange}
          />
        )}
        <PriceAdjustmentTypeSelector
          type={markupType}
          onChange={handleMarkupTypeChange}
        />
      </div>
    </div>
  );
};

const PercentageInput = ({
  value,
  onChange,
}: {
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Input
      style={{ textAlign: 'right', maxWidth: '5rem', lineHeight: '1' }}
      value={value}
      onFocus={onFocusSelect}
      onChange={onChange}
    />
  );
};

const AmountInput = ({
  value,
  onChange,
}: {
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Input
      style={{ textAlign: 'right', maxWidth: '5rem', lineHeight: '1' }}
      value={value}
      onFocus={onFocusSelect}
      onChange={onChange}
    />
  );
};

const PriceAdjustmentTypeSelector = ({
  type,
  onChange,
}: {
  type: 'percentage' | 'amount';
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}) => {
  const { currency } = useCutlistState();
  return (
    <Select
      options={[
        {
          label: '%',
          value: 'percentage',
          selected: type === 'percentage',
        },
        {
          label: currencyToDisplayMap[currency] || '',
          value: 'amount',
          selected: type === 'amount',
        },
      ]}
      onChange={onChange}
      style={{ width: 'fit-content', paddingRight: '2rem', height: '100%' }}
    />
  );
};
