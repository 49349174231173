import { UserLead } from '@cutr/constants/cutlist';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useLeadDetails } from '@/api/account';
import { useDeliveryAddress } from '@/api/address';
import { ApiError } from '@/api/backend';
import { Button } from '@/primitives/Button';
import { AddUser, Icon, UserAvatar } from '@/primitives/Icons';
import { SearchInput } from '@/primitives/SearchInput';
import { getSearchOwnerQuery } from '@/queries/agent';
import { useAssignCutlistOwner } from '@/queries/crud';
import { useDebounce } from '@/utils/hooks';

import styles from './CutlistOwnerButton.module.css';
import { Modal } from './Modal';

export const CutlistOwnerButton = ({ readOnly }: { readOnly: boolean }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      {readOnly ? (
        <OwnerLabel />
      ) : (
        <OwnerButton onClick={() => setIsModalOpen(true)} />
      )}

      <Modal
        title=""
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <ModalContent setIsModalOpen={setIsModalOpen} />
      </Modal>
    </>
  );
};

const ModalContent = ({
  setIsModalOpen,
}: {
  setIsModalOpen: (isOpen: boolean) => void;
}) => {
  const [loading, setLoading] = React.useState(false);

  const { id } = useParams();
  const { mutateAsync: assignCutlistOwner } = useAssignCutlistOwner(
    id as string
  );
  const { t } = useTranslation();
  const { init } = useLeadDetails();
  const deliveryAddressStore = useDeliveryAddress();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    try {
      setLoading(true);
      const data = new FormData(e.currentTarget);
      const email = data.get('email');
      const cutlist = await assignCutlistOwner(email as string);

      const deliveryAddress = cutlist.addresses[0];
      if (deliveryAddress) {
        deliveryAddressStore.init({
          ...deliveryAddress,
          contactName: deliveryAddress.name,
        });
      }

      init({
        ...cutlist.userLeadDetail,
        ...cutlist.userLead,
        notClient: false,
      });
      toast.success(t('agent.setCutlistOwnership.setOwnerSuccess', { email }));
      setLoading(false);
      setIsModalOpen(false);
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        toast.error(error.message);
      } else {
        toast.error(t('common.errors.unexpected'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.modalContent}>
      <div>
        <h2>{t('agent.setCutlistOwnership.setCutlistOwner')}</h2>
        <p>{t('agent.setCutlistOwnership.setOwnerInstructions')}</p>
      </div>
      <form
        className={styles.formContainer}
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          onSubmit(e);
        }}
      >
        <div>
          <label htmlFor="email">
            {t(`agent.setCutlistOwnership.email`)} {'*'}
          </label>
          <div>{t('agent.setCutlistOwnership.searchInstructions')}</div>
          <p />
          <OwnerSearch />
        </div>

        <Button type="submit" disabled={loading}>
          {t('agent.setCutlistOwnership.setOwner')}
        </Button>
      </form>
    </div>
  );
};

const OwnerSearch = () => {
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = React.useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500, '');
  const [data, setData] = React.useState<UserLead[]>([]);

  const [selectedOwner, setSelectedOwner] = React.useState<UserLead | null>(
    null
  );

  const getOwnerLabel = (userLead: UserLead) => {
    const defaultAddress = userLead.cutlistAddresses?.[0];
    return [
      userLead.email,
      userLead.companyName,
      userLead.clientNumber,
      defaultAddress?.city,
      defaultAddress?.postalCode,
      defaultAddress?.line1,
    ]
      .filter(Boolean)
      .join(', ');
  };

  const options = React.useMemo(() => {
    return data.map((userLead) => {
      return {
        value: userLead.id,
        component: () => <div>{getOwnerLabel(userLead)}</div>,
      };
    });
  }, [data]);

  React.useEffect(() => {
    if (!debouncedSearchQuery) return;
    if (debouncedSearchQuery.length < 3) return;

    const query = getSearchOwnerQuery(debouncedSearchQuery);

    queryClient.fetchQuery(query).then((data) => {
      setData(data);
    });
  }, [debouncedSearchQuery]);

  return (
    <>
      <SearchInput
        id="owner-search"
        value={searchQuery}
        onValueChange={(value) => setSearchQuery(value)}
        onSelectionChange={(value) => {
          const owner = data.find((userLead) => userLead.id === value);
          setSelectedOwner(owner ?? null);
          setSearchQuery(owner?.email ?? '');
        }}
        options={options}
        required
      />

      {selectedOwner && (
        <>
          <p>{getOwnerLabel(selectedOwner)}</p>
          <input type="hidden" name="email" value={selectedOwner.email} />
        </>
      )}
    </>
  );
};

const OwnerButton = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  const { t } = useTranslation();
  const { email } = useLeadDetails();

  return (
    <Button
      className="pill"
      variant="header"
      style={{
        background: 'var(--header-cta-background)',
        color: 'var(--header-cta-color)',
        minWidth: 'fit-content',
        pointerEvents: 'auto',
      }}
      icon={
        email ? <Icon icon={<UserAvatar />} /> : <Icon icon={<AddUser />} />
      }
      onClick={onClick}
    >
      <strong>
        {email
          ? t('cutlist-form.owner', { owner: email })
          : t('agent.setCutlistOwnership.setCutlistOwner')}
      </strong>
    </Button>
  );
};

const OwnerLabel = () => {
  const { t } = useTranslation();
  const { email } = useLeadDetails();

  return (
    <div className="flexAlign gap-xs">
      <Icon icon={<UserAvatar />} />
      <strong>{t('cutlist-form.owner', { owner: email })}</strong>
    </div>
  );
};
