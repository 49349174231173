import { MaterialGroup } from '@cutr/constants/cutlist';
import { useTranslation } from 'react-i18next';

import { Dropdown } from '@/primitives/Dropdown';
import { Icon, PaintingBullet, Yep } from '@/primitives/Icons';

import styles from './dropdown.module.css';

export const LabelSettingDropdown = ({
  group,
  onLabelSettingUpdate,
  value,
}: {
  group: MaterialGroup;
  onLabelSettingUpdate: (createLabels: boolean) => void;
  value: boolean;
  materialGroupValue?: boolean;
}) => {
  const { t } = useTranslation();

  const mapEdgeProfileTypeToComponent = (createLabels: boolean) => ({
    value: createLabels ? 'yes' : 'no',
    label: t(`cutlist-form.groupLabels.${createLabels}`),
    selected: value === createLabels,
    component: ({ isSelected }: { isSelected?: boolean }) => (
      <LabelSettingItem
        shouldAddLabels={createLabels ? 'yes' : 'no'}
        label={t(`cutlist-form.groupLabels.${createLabels}`)}
        isSelected={isSelected}
      />
    ),
  });
  const options = [true, false].map(mapEdgeProfileTypeToComponent);

  return (
    <Dropdown
      id={`edge-profile-${group.id}`}
      value={value ? 'yes' : 'no'}
      onSelected={(value) =>
        onLabelSettingUpdate(value === 'yes' ? true : false)
      }
      options={options}
    />
  );
};

export const LabelSettingItem = ({
  label,
  isSelected,
}: {
  shouldAddLabels: 'yes' | 'no';
  label: string;
  isSelected?: boolean;
}) => {
  return (
    <div className={styles.dropdownItem}>
      <IconPlaceholder />
      <div className={styles.dropdownName}>
        <strong>{label}</strong>
      </div>
      <div>{isSelected && <Icon icon={<Yep />} />}</div>
    </div>
  );
};

const IconPlaceholder = () => (
  <Icon
    style={{
      visibility: 'hidden',
      width: 0,
    }}
    icon={<PaintingBullet />}
    size={4}
  />
);
