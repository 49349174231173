import './Input.css';

import { withProfiler } from '@sentry/react';
import cn from 'classnames';
import React from 'react';

type InputCustomProps = {
  icon?: JSX.Element;
};
export type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  InputCustomProps;

const InputBase = ({ className, icon, ...props }: InputProps) => (
  <div className={cn('inputWrapper', className)}>
    <input
      className="input"
      {...props}
      onDragStart={(e) => e.preventDefault()}
      onDrop={(e) => e.preventDefault()}
    />
    {icon && <div className="inputIcon">{icon}</div>}
  </div>
);

const TextBase = ({
  className,
  ...props
}: React.TextareaHTMLAttributes<HTMLTextAreaElement>) => (
  <div className={cn('inputWrapper', className)}>
    <textarea
      className="input"
      {...props}
      onDragStart={(e) => e.preventDefault()}
      onDrop={(e) => e.preventDefault()}
    ></textarea>
  </div>
);

//Wrap withProfile to prevent spamming spans when typing
export const Input = withProfiler(InputBase, { includeUpdates: false });
export const Textarea = withProfiler(TextBase, { includeUpdates: false });
