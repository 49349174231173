import {
  Material,
  MaterialSandwichType,
  sandwichHasHPL,
} from '@cutr/constants/cutlist';
import cn from 'classnames';
import React from 'react';

import {
  getCoreMaterials,
  getCoreMaterialsForGluingAndPressing,
  getEdgebandingMaterials,
  getFuseInstance,
  getHPLMaterials,
} from '@/api/materials';
import {
  useUsedBandingMaterials,
  useUsedCoreMaterials,
  useUsedHplMaterials,
} from '@/api/store';
import { onFocusSelect } from '@/hooks';
import { Icon, Search } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { useCurrentSource } from '@/theme';

import styles from './styles.module.css';

interface AutocompleteProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: Material['articleCode'];
  placeholder: string;
  id: string;
  materialType?: 'core' | 'hpl' | 'edgeband'; // falls back to core
  materialSandwichType: MaterialSandwichType;
  inputClass?: string;
  onResults?: (results: Material[]) => void;
}

export const Autocomplete = ({
  placeholder,
  value,
  id,
  materialType,
  materialSandwichType,
  inputClass,
  onResults,
  ...props
}: AutocompleteProps) => {
  const [val, setVal] = React.useState<Material['articleCode']>(
    (value as string) || ''
  );
  const [sendTrackBlur, setSendTrackBlur] = React.useState(false);
  const source = useCurrentSource();

  // In the case where a customer types in their search, accidentally clicks out of
  // the field and then back in, we don't want to treat that as a search failure.
  // This gives a two second buffer for them to click back in before we track
  // the blur as a failed search.
  React.useEffect(() => {
    if (sendTrackBlur) {
      const cancellation = setTimeout(() => {
        window.analytics.track('Searched for material', {
          searchInput: val,
          field: materialType,
          success: false,
          ticker: source,
          resultCount: materials.length,
        });
        setSendTrackBlur(false);
      }, 2000);

      return () => {
        clearTimeout(cancellation);
      };
    }
  }, [sendTrackBlur]);

  const sandwichWithHPL = sandwichHasHPL(materialSandwichType);
  const materialsList =
    materialType === 'hpl'
      ? getHPLMaterials()
      : materialType === 'edgeband'
      ? getEdgebandingMaterials()
      : sandwichWithHPL
      ? getCoreMaterialsForGluingAndPressing()
      : getCoreMaterials();

  const coreMaterials = useUsedCoreMaterials();
  const hplMaterials = useUsedHplMaterials();
  const edgebandingMaterials = useUsedBandingMaterials();
  const recentMaterials =
    materialType === 'edgeband'
      ? edgebandingMaterials
      : materialType === 'hpl'
      ? hplMaterials
      : coreMaterials;

  const fuse = getFuseInstance(materialType || 'core');

  const filteredMaterials = (input: string) => {
    // replace `20mm` with `20 mm` so that material dimensions are matched properly
    input = input.replace(/(\d)(m)/, (match, g1, g2) => `${g1} ${g2}`);
    const searchTerms = input.split(' ');

    const search = searchTerms.reduce((acc, val) => {
      return acc + (Number.isNaN(parseInt(val)) ? `^${val}|` : `=${val}|`);
    }, '');

    return fuse.search(search).map(({ item, score }) => {
      return { item, score };
    });
  };

  let materials = val
    ? materialsList
        .filter((m) => !val || m.articleCode.startsWith(val))
        .splice(0, 5)
    : recentMaterials;

  if (val) {
    const filterMaterials = filteredMaterials(val);
    const mappedMaterials = materialsList
      .filter((m) =>
        filterMaterials.some((f) => f.item.articleCode === m.articleCode)
      )
      .sort((a, b) => {
        const item1 = filterMaterials.find(
          (m) => m.item.articleCode === a.articleCode
        );
        const item2 = filterMaterials.find(
          (m) => m.item.articleCode === b.articleCode
        );
        const score1 = item1 ? Number(item1.score) : 0;
        const score2 = item2 ? Number(item2.score) : 0;
        return score1 - score2;
      });
    materials = val ? mappedMaterials : recentMaterials;
  }

  React.useEffect(() => {
    if (onResults) {
      onResults(materials);
    }
  }, [val]);

  return (
    <div className={styles.autocomplete}>
      <Input
        id={`autocomplete-input-${id}`}
        icon={<Icon icon={<Search />} />}
        placeholder={placeholder}
        value={val}
        aria-autocomplete="both"
        aria-controls={`autocomplete-${id}`}
        aria-haspopup="listbox"
        role="combobox"
        className={cn(inputClass, 'w-border')}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
          setVal(String(e.target.value));
        }}
        onFocus={(e) => {
          onFocusSelect(e);
          setSendTrackBlur(false);
        }}
        {...props}
      />
    </div>
  );
};
