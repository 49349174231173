import { MaterialGroup } from '@cutr/constants/cutlist';
import { useTranslation } from 'react-i18next';

import { useCutlistState } from '@/api/store';

import styles from './EmptyTableNudge.module.css';

export const EmptyTableNudge = ({ group }: { group: MaterialGroup }) => {
  const { t } = useTranslation();

  const parts = useCutlistState((state) =>
    state.parts.filter((part) => part.groupId === group.id)
  );

  if (parts.length > 0) return null;

  return (
    <div className={styles.nudgeText}>
      <h4>{t('cutlist-form.nudge.noParts')}</h4>
    </div>
  );
};
