import './index.css';
import './utils.css';
import './i18n';

import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { router } from './App';
import { FeatureProvider } from './FeatureProvider';
import { getCurrentFeatures } from './theme';
import { ToastProvider } from './ToastProvider';
const ENVIRONMENT = import.meta.env.VITE_CUTR_ENV;
const RELEASE = import.meta.env.VITE_GIT_HASH;
const SENTRY_ACTIVE = ENVIRONMENT !== 'development';
const IS_PRODUCTION = ENVIRONMENT === 'production';

window.analytics.load(import.meta.env.VITE_SEGMENT_WRITE_KEY);

Sentry.init({
  release: RELEASE,
  environment: ENVIRONMENT,
  dsn: SENTRY_ACTIVE
    ? 'https://9898aee78fc547889dff93f9ab853e22@o880313.ingest.sentry.io/4504050035851264'
    : undefined,
  integrations: [
    new Sentry.Replay(),
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        'localhost',
        'http://local.cutr.com',
        'https://local.cutr.com',
        'https://apistaging.cutr.ai',
        'https://api.aws-staging.cutr.ai',
        'https://api.cutr.ai',
        'https://apistaging.cutr.com',
        'https://api.gcp-staging.cutr.com',
        'https://api.gcp-prod.cutr.com',
        'https://api.cutr.com',
      ],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampler: (samplingContext) => {
    // inherit parent
    if (samplingContext.parentSampled !== undefined) {
      return samplingContext.parentSampled;
    }
    return 0.25;
  },
  replaysSessionSampleRate: IS_PRODUCTION ? 0.1 : 0.01,
  replaysOnErrorSampleRate: IS_PRODUCTION ? 1.0 : 0.1,
});

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <FeatureProvider initialState={getCurrentFeatures()}>
        <ToastProvider />
        <RouterProvider router={router(queryClient)} />
      </FeatureProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

declare global {
  interface Window {
    analytics: {
      identify(
        userId?: string,
        traits?: { [key: string]: unknown },
        options?: { [key: string]: unknown },
        callback?: () => void
      ): void;
      track(
        event: string,
        properties?: { [key: string]: unknown },
        options?: { [key: string]: unknown },
        callback?: () => void
      ): void;
      page(
        category?: string,
        name?: string,
        properties?: { [key: string]: unknown },
        options?: { [key: string]: unknown },
        callback?: () => void
      ): void;
      load(
        category?: string,
        name?: string,
        properties?: { [key: string]: unknown },
        options?: { [key: string]: unknown },
        callback?: () => void
      ): void;
    };
  }
}
