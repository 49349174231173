import './Checkbox.css';

import cn from 'classnames';

type CheckboxCustomProps = {
  isSwitch?: boolean;
  errorMessage?: string;
};
type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> &
  CheckboxCustomProps;

export const Checkbox = ({
  className,
  isSwitch,
  errorMessage = '',
  children,
  ...props
}: CheckboxProps) => (
  <label
    className={cn(
      'form-control',
      { disabled: props.disabled, isSwitch },
      className
    )}
  >
    <input type="checkbox" name="checkbox" {...props} />
    {children}
    {Boolean(errorMessage) && (
      <span className="errorMessage">{errorMessage}</span>
    )}
  </label>
);
