import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { api } from '@/api/backend';
import { resetErrors } from '@/api/errors';
import { dotCutlist, exportCutlist } from '@/api/importExport';
import { useMaterialGroupState } from '@/api/materialsGroup';
import { useNestingStore } from '@/api/nesting';
import { usePricingStore } from '@/api/pricing';
import { useCutlistState } from '@/api/store';
import { Button, RouterButton } from '@/primitives/Button';
import { Download, Icon, Illustration, Pdf } from '@/primitives/Icons';
import { useThemeConfig } from '@/theme';

// this form is owned by BNB, show it only in BNB source
const BNB_FEEDBACK_FORM_URL =
  'https://app.mopinion.com/survey/public/take-survey/2cb7f8d48cdf40a26f1ba0b185bc3650256788d2';

export default function Done() {
  const { t } = useTranslation();
  const priceStore = usePricingStore();
  const cutlistState = useCutlistState();
  const { setNesting } = useNestingStore();
  const groupStore = useMaterialGroupState();
  const { source } = useThemeConfig();
  const isBnb = source === 'BNB';

  const [tempState] = React.useState(cutlistState);
  const url = api.createUrl(`${tempState.orderId}/downloads/pdf`, true);
  const location = useLocation();
  const requestType = location.state.requestType;

  const onDownload = () => {
    exportCutlist(dotCutlist(tempState));
  };

  React.useEffect(() => {
    resetErrors();
    cutlistState.reset();
    setNesting([]);
    priceStore.reset();
    groupStore.clear();
  }, []);

  return (
    <div
      className="content"
      style={{
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <p style={{ width: '15rem' }}>
        <Illustration />
      </p>
      <h2>
        {requestType === 'place_order'
          ? t('translation.done.placeOrder.title')
          : t('translation.done.requestQuote.title')}
      </h2>
      <p style={{ maxWidth: '55ch', textAlign: 'center' }}>
        {requestType === 'place_order'
          ? t('translation.done.placeOrder.message')
          : t('translation.done.requestQuote.message')}
      </p>

      <div className="flexAlign">
        <Button
          onClick={onDownload}
          size="s"
          className="outline"
          tooltip={t('upload-download.download.buttonTooltip')}
          icon={<Icon icon={<Download />} />}
        >
          {t('translation.done.downloadCTA')}
        </Button>

        <Button
          href={url.href}
          as="a"
          target="_blank"
          size="s"
          className="outline"
          icon={<Icon icon={<Pdf />} />}
        >
          {t('translation.done.downloadPDFCTA')}
        </Button>
      </div>

      <p />

      {isBnb && (
        <div>
          <h2>{t('translation.feedback.title')}</h2>
          <p style={{ maxWidth: '55ch', textAlign: 'center' }}>
            {t('translation.feedback.message')}
          </p>
          <Button
            className="alignCenter"
            as="a"
            href={BNB_FEEDBACK_FORM_URL}
            target="_blank"
            style={{ width: '18em' }}
          >
            {t('translation.feedback.button')}
          </Button>
        </div>
      )}

      <div>
        <RouterButton to="/" className="outline" style={{ width: '18em' }}>
          {t('translation.done.backToOrders')}
        </RouterButton>
      </div>
    </div>
  );
}
