import { EdgeGroove } from '@cutr/constants/cutlist';

type EdgePreviewProps = {
  position: EdgeGroove['position'];
};

export const GroovePreview = ({ position }: EdgePreviewProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      {position === 'top' && <TopGroove />}
      {position === 'side' && <SideGroove />}
      {position === 'bottom' && <BottomGroove />}
      {position === 'none' && <SideGroove partColor="var(--gray-5)" />}
    </div>
  );
};

const SideGroove = ({
  partColor = 'var(--gray-9)',
}: {
  partColor?: string;
}) => {
  const grooveBorderColor = 'var(--white)';
  const grooveColor = 'var(--gray-1)';
  const arrowColor = 'var(--white)';

  return (
    <svg width="184" height="144" viewBox="0 0 184 144">
      <rect width="184" height="144" fill={partColor} />
      <rect
        y="44"
        width="64"
        height="48"
        fill={grooveColor}
        stroke={grooveBorderColor}
      />
      <path
        d="M14.3536 0.646447C14.1583 0.451184 13.8417 0.451184 13.6464 0.646447L10.4645 3.82843C10.2692 4.02369 10.2692 4.34027 10.4645 4.53553C10.6597 4.7308 10.9763 4.7308 11.1716 4.53553L14 1.70711L16.8284 4.53553C17.0237 4.7308 17.3403 4.7308 17.5355 4.53553C17.7308 4.34027 17.7308 4.02369 17.5355 3.82843L14.3536 0.646447ZM13.6464 43.3536C13.8417 43.5488 14.1583 43.5488 14.3536 43.3536L17.5355 40.1716C17.7308 39.9763 17.7308 39.6597 17.5355 39.4645C17.3403 39.2692 17.0237 39.2692 16.8284 39.4645L14 42.2929L11.1716 39.4645C10.9763 39.2692 10.6597 39.2692 10.4645 39.4645C10.2692 39.6597 10.2692 39.9763 10.4645 40.1716L13.6464 43.3536ZM13.5 1L13.5 43L14.5 43L14.5 1L13.5 1Z"
        fill={arrowColor}
      />
      <path
        d="M63.3536 98.3536C63.5488 98.1583 63.5488 97.8417 63.3536 97.6464L60.1716 94.4645C59.9763 94.2692 59.6597 94.2692 59.4645 94.4645C59.2692 94.6597 59.2692 94.9763 59.4645 95.1716L62.2929 98L59.4645 100.828C59.2692 101.024 59.2692 101.34 59.4645 101.536C59.6597 101.731 59.9763 101.731 60.1716 101.536L63.3536 98.3536ZM0.646446 97.6464C0.451183 97.8417 0.451183 98.1583 0.646446 98.3536L3.82843 101.536C4.02369 101.731 4.34027 101.731 4.53553 101.536C4.7308 101.34 4.7308 101.024 4.53553 100.828L1.70711 98L4.53553 95.1716C4.7308 94.9763 4.7308 94.6597 4.53553 94.4645C4.34027 94.2692 4.02369 94.2692 3.82843 94.4645L0.646446 97.6464ZM63 97.5L1 97.5V98.5L63 98.5V97.5Z"
        fill={arrowColor}
      />
      <path
        d="M72.3536 44.6464C72.1583 44.4512 71.8417 44.4512 71.6464 44.6464L68.4645 47.8284C68.2692 48.0237 68.2692 48.3403 68.4645 48.5355C68.6597 48.7308 68.9763 48.7308 69.1716 48.5355L72 45.7071L74.8284 48.5355C75.0237 48.7308 75.3403 48.7308 75.5355 48.5355C75.7308 48.3403 75.7308 48.0237 75.5355 47.8284L72.3536 44.6464ZM71.6464 91.3536C71.8417 91.5488 72.1583 91.5488 72.3536 91.3536L75.5355 88.1716C75.7308 87.9763 75.7308 87.6597 75.5355 87.4645C75.3403 87.2692 75.0237 87.2692 74.8284 87.4645L72 90.2929L69.1716 87.4645C68.9763 87.2692 68.6597 87.2692 68.4645 87.4645C68.2692 87.6597 68.2692 87.9763 68.4645 88.1716L71.6464 91.3536ZM71.5 45L71.5 91L72.5 91L72.5 45L71.5 45Z"
        fill={arrowColor}
      />
      <path
        d="M17.368 28L21.608 16.64H23.496L27.752 28H25.736L24.872 25.552H20.232L19.384 28H17.368ZM20.824 23.888H24.296L22.568 18.96H22.536L20.824 23.888Z"
        fill={arrowColor}
      />
      <path
        d="M33.608 114.096C30.52 114.096 27.88 112.08 27.88 108.352C27.88 105.12 29.928 102.512 33.576 102.512C35.24 102.512 36.872 103.232 38.056 104.688L36.632 105.888C35.784 104.848 34.776 104.336 33.576 104.336C31.416 104.336 29.896 105.952 29.896 108.352C29.896 110.688 31.448 112.288 33.608 112.288C34.888 112.288 35.944 111.632 36.664 110.736L38.088 111.952C37.112 113.168 35.608 114.096 33.608 114.096Z"
        fill={arrowColor}
      />
      <path
        d="M80.392 73V61.64H84.344C86.68 61.64 88.04 62.552 88.04 64.408C88.04 65.464 87.528 66.52 86.36 66.984V67C87.688 67.32 88.616 68.312 88.616 69.88C88.616 71.688 87.4 73 84.888 73H80.392ZM82.328 66.344H84.2C85.288 66.344 86.072 65.912 86.072 64.84C86.072 63.944 85.464 63.352 84.136 63.352L82.328 63.368V66.344ZM82.328 71.272H84.648C85.848 71.272 86.6 70.744 86.6 69.672C86.6 68.664 85.848 68.008 84.344 68.008H82.328V71.272Z"
        fill={arrowColor}
      />
    </svg>
  );
};

const TopGroove = () => {
  const grooveBorderColor = 'var(--white)';
  const grooveColor = 'var(--gray-1)';
  const partColor = 'var(--gray-9)';
  const arrowColor = 'var(--white)';

  return (
    <svg
      width="184"
      height="144"
      viewBox="0 0 184 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="184" height="144" fill={partColor} />
      <rect
        x="48"
        width="48"
        height="64"
        fill={grooveColor}
        stroke={grooveBorderColor}
      />
      <path
        d="M0.646447 7.64645C0.451184 7.84171 0.451184 8.15829 0.646447 8.35355L3.82843 11.5355C4.02369 11.7308 4.34027 11.7308 4.53553 11.5355C4.7308 11.3403 4.7308 11.0237 4.53553 10.8284L1.70711 8L4.53553 5.17157C4.7308 4.97631 4.7308 4.65973 4.53553 4.46447C4.34027 4.2692 4.02369 4.2692 3.82843 4.46447L0.646447 7.64645ZM47.3536 8.35355C47.5488 8.15829 47.5488 7.84171 47.3536 7.64645L44.1716 4.46447C43.9763 4.2692 43.6597 4.2692 43.4645 4.46447C43.2692 4.65973 43.2692 4.97631 43.4645 5.17157L46.2929 8L43.4645 10.8284C43.2692 11.0237 43.2692 11.3403 43.4645 11.5355C43.6597 11.7308 43.9763 11.7308 44.1716 11.5355L47.3536 8.35355ZM1 8.5L47 8.5L47 7.5L1 7.5L1 8.5Z"
        fill={arrowColor}
      />
      <path
        d="M95.3536 70.3536C95.5488 70.1583 95.5488 69.8417 95.3536 69.6464L92.1716 66.4645C91.9763 66.2692 91.6597 66.2692 91.4645 66.4645C91.2692 66.6597 91.2692 66.9763 91.4645 67.1716L94.2929 70L91.4645 72.8284C91.2692 73.0237 91.2692 73.3403 91.4645 73.5355C91.6597 73.7308 91.9763 73.7308 92.1716 73.5355L95.3536 70.3536ZM48.6464 69.6464C48.4512 69.8417 48.4512 70.1583 48.6464 70.3536L51.8284 73.5355C52.0237 73.7308 52.3403 73.7308 52.5355 73.5355C52.7308 73.3403 52.7308 73.0237 52.5355 72.8284L49.7071 70L52.5355 67.1716C52.7308 66.9763 52.7308 66.6597 52.5355 66.4645C52.3403 66.2692 52.0237 66.2692 51.8284 66.4645L48.6464 69.6464ZM95 69.5H49V70.5H95V69.5Z"
        fill={arrowColor}
      />
      <path
        d="M102.354 0.646447C102.158 0.451184 101.842 0.451184 101.646 0.646447L98.4645 3.82843C98.2692 4.02369 98.2692 4.34027 98.4645 4.53553C98.6597 4.7308 98.9763 4.7308 99.1716 4.53553L102 1.70711L104.828 4.53553C105.024 4.7308 105.34 4.7308 105.536 4.53553C105.731 4.34027 105.731 4.02369 105.536 3.82843L102.354 0.646447ZM101.646 63.3536C101.842 63.5488 102.158 63.5488 102.354 63.3536L105.536 60.1716C105.731 59.9763 105.731 59.6597 105.536 59.4645C105.34 59.2692 105.024 59.2692 104.828 59.4645L102 62.2929L99.1716 59.4645C98.9763 59.2692 98.6597 59.2692 98.4645 59.4645C98.2692 59.6597 98.2692 59.9763 98.4645 60.1716L101.646 63.3536ZM101.5 1L101.5 63L102.5 63L102.5 1L101.5 1Z"
        fill={arrowColor}
      />
      <path
        d="M18.368 24L22.608 12.64H24.496L28.752 24H26.736L25.872 21.552H21.232L20.384 24H18.368ZM21.824 19.888H25.296L23.568 14.96H23.536L21.824 19.888Z"
        fill={arrowColor}
      />
      <path
        d="M68.392 86V74.64H72.344C74.68 74.64 76.04 75.552 76.04 77.408C76.04 78.464 75.528 79.52 74.36 79.984V80C75.688 80.32 76.616 81.312 76.616 82.88C76.616 84.688 75.4 86 72.888 86H68.392ZM70.328 79.344H72.2C73.288 79.344 74.072 78.912 74.072 77.84C74.072 76.944 73.464 76.352 72.136 76.352L70.328 76.368V79.344ZM70.328 84.272H72.648C73.848 84.272 74.6 83.744 74.6 82.672C74.6 81.664 73.848 81.008 72.344 81.008H70.328V84.272Z"
        fill={arrowColor}
      />
      <path
        d="M112.608 38.096C109.52 38.096 106.88 36.08 106.88 32.352C106.88 29.12 108.928 26.512 112.576 26.512C114.24 26.512 115.872 27.232 117.056 28.688L115.632 29.888C114.784 28.848 113.776 28.336 112.576 28.336C110.416 28.336 108.896 29.952 108.896 32.352C108.896 34.688 110.448 36.288 112.608 36.288C113.888 36.288 114.944 35.632 115.664 34.736L117.088 35.952C116.112 37.168 114.608 38.096 112.608 38.096Z"
        fill={arrowColor}
      />
    </svg>
  );
};

const BottomGroove = () => {
  const grooveBorderColor = 'var(--white)';
  const grooveColor = 'var(--gray-1)';
  const partColor = 'var(--gray-9)';
  const arrowColor = 'var(--white)';

  return (
    <svg width="184" height="144" viewBox="0 0 184 144">
      <rect width="184" height="144" fill={partColor} />
      <rect
        x="48"
        y="80"
        width="48"
        height="64"
        fill={grooveColor}
        stroke={grooveBorderColor}
      />
      <path
        d="M0.646447 135.646C0.451184 135.842 0.451184 136.158 0.646447 136.354L3.82843 139.536C4.02369 139.731 4.34027 139.731 4.53553 139.536C4.7308 139.34 4.7308 139.024 4.53553 138.828L1.70711 136L4.53553 133.172C4.7308 132.976 4.7308 132.66 4.53553 132.464C4.34027 132.269 4.02369 132.269 3.82843 132.464L0.646447 135.646ZM47.3536 136.354C47.5488 136.158 47.5488 135.842 47.3536 135.646L44.1716 132.464C43.9763 132.269 43.6597 132.269 43.4645 132.464C43.2692 132.66 43.2692 132.976 43.4645 133.172L46.2929 136L43.4645 138.828C43.2692 139.024 43.2692 139.34 43.4645 139.536C43.6597 139.731 43.9763 139.731 44.1716 139.536L47.3536 136.354ZM1 136.5L47 136.5L47 135.5L1 135.5L1 136.5Z"
        fill={arrowColor}
      />
      <path
        d="M95.3536 74.3536C95.5488 74.1583 95.5488 73.8417 95.3536 73.6464L92.1716 70.4645C91.9763 70.2692 91.6597 70.2692 91.4645 70.4645C91.2692 70.6597 91.2692 70.9763 91.4645 71.1716L94.2929 74L91.4645 76.8284C91.2692 77.0237 91.2692 77.3403 91.4645 77.5355C91.6597 77.7308 91.9763 77.7308 92.1716 77.5355L95.3536 74.3536ZM48.6464 73.6464C48.4512 73.8417 48.4512 74.1583 48.6464 74.3536L51.8284 77.5355C52.0237 77.7308 52.3403 77.7308 52.5355 77.5355C52.7308 77.3403 52.7308 77.0237 52.5355 76.8284L49.7071 74L52.5355 71.1716C52.7308 70.9763 52.7308 70.6597 52.5355 70.4645C52.3403 70.2692 52.0237 70.2692 51.8284 70.4645L48.6464 73.6464ZM95 73.5H49V74.5H95V73.5Z"
        fill={arrowColor}
      />
      <path
        d="M102.354 80.6464C102.158 80.4512 101.842 80.4512 101.646 80.6464L98.4645 83.8284C98.2692 84.0237 98.2692 84.3403 98.4645 84.5355C98.6597 84.7308 98.9763 84.7308 99.1716 84.5355L102 81.7071L104.828 84.5355C105.024 84.7308 105.34 84.7308 105.536 84.5355C105.731 84.3403 105.731 84.0237 105.536 83.8284L102.354 80.6464ZM101.646 143.354C101.842 143.549 102.158 143.549 102.354 143.354L105.536 140.172C105.731 139.976 105.731 139.66 105.536 139.464C105.34 139.269 105.024 139.269 104.828 139.464L102 142.293L99.1716 139.464C98.9763 139.269 98.6597 139.269 98.4645 139.464C98.2692 139.66 98.2692 139.976 98.4645 140.172L101.646 143.354ZM101.5 81L101.5 143L102.5 143L102.5 81L101.5 81Z"
        fill={arrowColor}
      />
      <path
        d="M18.368 132L22.608 120.64H24.496L28.752 132H26.736L25.872 129.552H21.232L20.384 132H18.368ZM21.824 127.888H25.296L23.568 122.96H23.536L21.824 127.888Z"
        fill={arrowColor}
      />
      <path
        d="M68.392 70V58.64H72.344C74.68 58.64 76.04 59.552 76.04 61.408C76.04 62.464 75.528 63.52 74.36 63.984V64C75.688 64.32 76.616 65.312 76.616 66.88C76.616 68.688 75.4 70 72.888 70H68.392ZM70.328 63.344H72.2C73.288 63.344 74.072 62.912 74.072 61.84C74.072 60.944 73.464 60.352 72.136 60.352L70.328 60.368V63.344ZM70.328 68.272H72.648C73.848 68.272 74.6 67.744 74.6 66.672C74.6 65.664 73.848 65.008 72.344 65.008H70.328V68.272Z"
        fill={arrowColor}
      />
      <path
        d="M112.608 118.096C109.52 118.096 106.88 116.08 106.88 112.352C106.88 109.12 108.928 106.512 112.576 106.512C114.24 106.512 115.872 107.232 117.056 108.688L115.632 109.888C114.784 108.848 113.776 108.336 112.576 108.336C110.416 108.336 108.896 109.952 108.896 112.352C108.896 114.688 110.448 116.288 112.608 116.288C113.888 116.288 114.944 115.632 115.664 114.736L117.088 115.952C116.112 117.168 114.608 118.096 112.608 118.096Z"
        fill={arrowColor}
      />
    </svg>
  );
};
