import { User } from '@cutr/constants/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { ApiError } from '@/api/backend';
import { useCutlistState } from '@/api/store';
import { Button } from '@/primitives/Button';
import { AddUser, AgentAvatar, Icon } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { useSearchAgent } from '@/queries/agent';
import { useAssignCutlistAgent } from '@/queries/crud';
import { useDebounce } from '@/utils/hooks';

import styles from './CutlistAgentButton.module.css';
import { Modal } from './Modal';

export const CutlistAgentButton = () => {
  const { id } = useParams();
  const { mutateAsync: assignCutlistAgent } = useAssignCutlistAgent(
    id as string
  );
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const { setAuthorAgent, status } = useCutlistState();
  const [searchQuery, setSearchQuery] = React.useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const isReadOnly = status !== 'draft' && status !== 'new';

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    try {
      setLoading(true);
      const data = new FormData(e.currentTarget);
      const email = data.get('email');
      const cutlist = await assignCutlistAgent(email as string);

      setAuthorAgent(cutlist.authorAgent);

      toast.success(t('agent.setCutlistAgent.setAgentSuccess', { email }));
      setLoading(false);
      setIsModalOpen(false);
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        toast.error(error.message);
      } else {
        toast.error(t('common.errors.unexpected'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isReadOnly ? (
        <AgentLabel />
      ) : (
        <AgentButton onClick={() => setIsModalOpen(true)} />
      )}

      <Modal
        title=""
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <div className={styles.modalContent}>
          <div>
            <h2>{t('agent.setCutlistAgent.setCutlistAgent')}</h2>
            <p>{t('agent.setCutlistAgent.setAgentInstructions')}</p>
          </div>
          <form
            className={styles.formContainer}
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              onSubmit(e);
            }}
          >
            <div>
              <label htmlFor="email">
                {t(`agent.setCutlistAgent.email`)} {'*'}
              </label>
              <Input
                type="text"
                name="email"
                onChange={onChange}
                required
                data-1p-ignore
                autoComplete="off"
                list="agents"
              />
              <datalist id="agents">
                {debouncedSearchQuery && debouncedSearchQuery.length > 2 && (
                  <AvailableAgents searchQuery={debouncedSearchQuery} />
                )}
              </datalist>
            </div>
            <Button type="submit" disabled={loading}>
              {t('agent.setCutlistAgent.setAgent')}
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
};

const AgentButton = ({ onClick }: { onClick: () => void }) => {
  const { authorAgent } = useCutlistState();
  const { t } = useTranslation();
  return (
    <Button
      className="pill"
      variant="header"
      style={{
        minWidth: 'fit-content',
        pointerEvents: 'auto',
      }}
      icon={
        authorAgent?.email ? (
          <Icon icon={<AgentAvatar />} />
        ) : (
          <Icon icon={<AddUser />} />
        )
      }
      onClick={onClick}
    >
      <strong>
        {authorAgent?.email
          ? t('cutlist-form.agent', { agent: authorAgent.email })
          : t('agent.setCutlistAgent.setCutlistAgent')}
      </strong>
    </Button>
  );
};

const AgentLabel = () => {
  const { authorAgent } = useCutlistState();
  const { t } = useTranslation();
  return (
    <div className="flexAlign gap-xs">
      <Icon icon={<AgentAvatar />} />
      <strong>{t('cutlist-form.agent', { agent: authorAgent?.email })}</strong>
    </div>
  );
};

const AvailableAgents = ({ searchQuery }: { searchQuery: string }) => {
  const { data } = useSearchAgent(searchQuery);
  if (!data) return null;
  return (
    <>
      {data.map((agent: User) => {
        return (
          <option value={agent.email} key={agent.id}>
            {agent.email}
          </option>
        );
      })}
    </>
  );
};
