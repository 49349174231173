import { MaterialGroup, SheetEdgeTrimType } from '@cutr/constants/cutlist';
import { SheetEdgeTrimConfig } from '@cutr/constants/cutlist-theme';
import { SupplierMaterial } from '@cutr/constants/material';

export const getTrimConfigByType = (
  supportedConfigs: SheetEdgeTrimConfig[],
  sheetEdgeTrymType: SheetEdgeTrimType
): SheetEdgeTrimConfig => {
  const config = supportedConfigs.find(
    (trim) => trim.sheetEdgeTrimType === sheetEdgeTrymType
  );

  if (!config) {
    throw new Error(`Unsupported sheet edge trim config: ${sheetEdgeTrymType}`);
  }

  return config;
};

export const applyCustomTrimThickness = (
  updatedGroup: MaterialGroup,
  material?: SupplierMaterial
) => {
  if (updatedGroup.sheetEdgeTrimConfig && material) {
    const trimThicknessFields: (keyof SheetEdgeTrimConfig['trimThickness'])[] =
      [
        'length1TrimThicknessMM',
        'length2TrimThicknessMM',
        'width1TrimThicknessMM',
        'width2TrimThicknessMM',
      ];
    if (trimThicknessFields.every((key) => material[key] !== null)) {
      updatedGroup.sheetEdgeTrimConfig.trimThickness =
        trimThicknessFields.reduce((acc, key) => {
          return { ...acc, [key]: material[key] };
        }, {}) as SheetEdgeTrimConfig['trimThickness'];
    }
  }
};
