import { Material, MaterialGroup } from '@cutr/constants/cutlist';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getMaterial } from '@/api/materials';
import {
  MaterialComponent,
  useEdgebandingMaterialForGroup,
} from '@/api/materialsGroup';
import { ConfirmationDialog } from '@/blocks/ConfirmationDialog';
import { materialDimensions } from '@/blocks/MaterialRow';
import {
  emptySrc,
  getSrcFromMaterial,
  onImgError,
} from '@/blocks/materialRowUtils';
import { Button } from '@/primitives/Button';
import { Edit, Icon, Trash } from '@/primitives/Icons';
import { useCurrentFeatures } from '@/theme';

import { useActiveGroup } from '../../api/materialsGroup';
import styles from './MaterialGroupCard.module.css';
import SelectMaterialModal from './SelectMaterialModal';
import { MaterialRemoval, MaterialUpdate } from './types';

interface MaterialGroupCardProps {
  i18nKey: string;
  group: MaterialGroup;
  field: MaterialComponent;
  isDouble?: boolean;
  onUpdateMaterial: MaterialUpdate;
  onRemoveMaterial: MaterialRemoval;
  confirmationDialog?: boolean;
}

const MaterialGroupCard = ({
  i18nKey,
  group,
  field,
  isDouble,
  onUpdateMaterial,
  onRemoveMaterial,
  confirmationDialog = false,
}: MaterialGroupCardProps) => {
  const { t } = useTranslation();

  const fieldPlaceholderMap: Record<MaterialComponent, string> = {
    core1: 'cutlist-form.materialRowSkeleton.core1',
    topHpl: 'cutlist-form.materialRowSkeleton.topHpl',
    bottomHpl: 'cutlist-form.materialRowSkeleton.bottomHpl',
    edgeband: 'cutlist-form.materialRowSkeleton.edgeband',
  };

  const activeGroup = useActiveGroup();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const item = group[field] as Material['articleCode'];
  const material = getMaterial(item);

  const openModal = () => {
    setModalVisible(true);
  };

  const handleModalSelect: MaterialUpdate = (fields) => {
    onUpdateMaterial(fields);
    setModalVisible(false);
  };

  const removeMaterial = (field: MaterialComponent) => {
    onRemoveMaterial(field);
    setShowConfirmModal(false);
  };

  let materialContent;

  if (!material) {
    materialContent = (
      <MaterialItemSkeleton placeholder={t(fieldPlaceholderMap[field])} />
    );
  } else if (activeGroup?.sheetSizeSelection === 'automatic') {
    materialContent = (
      <MaterialVariationItem
        automaticSheetSizeMaterials={activeGroup.automaticSheetSizeMaterials}
      />
    );
  } else {
    materialContent = <MaterialItem item={material} />;
  }

  return (
    <>
      <div
        className={styles.materialCard}
        onClick={() => openModal()}
        role="button"
        tabIndex={0}
      >
        <MaterialFieldTitle i18nKey={i18nKey} isDouble={isDouble} />
        {materialContent}

        {!material ? (
          <Button
            className="smaller pill snug outline"
            name={'select-material-' + field}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              openModal();
            }}
          >
            <Icon icon={<Edit />} />
          </Button>
        ) : (
          <Button
            variant="icon"
            className="outline delete"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              confirmationDialog
                ? setShowConfirmModal(true)
                : removeMaterial(field);
            }}
          >
            <Icon icon={<Trash />} />
          </Button>
        )}
      </div>

      <SelectMaterialModal
        field={field}
        group={group}
        onUpdateMaterial={handleModalSelect}
        isOpen={modalVisible}
        handleClose={() => setModalVisible(false)}
      />

      {confirmationDialog && (
        <ConfirmationDialog
          title={t('cutlist-form.cta.removeEdgebandMaterial.title')}
          onConfirm={() => removeMaterial(field)}
          onClose={() => setShowConfirmModal(false)}
          isOpen={showConfirmModal}
        >
          {t('cutlist-form.cta.removeEdgebandMaterial.body')}
        </ConfirmationDialog>
      )}
    </>
  );
};

const MaterialFieldTitle = ({
  i18nKey,
  isDouble,
}: {
  i18nKey: string;
  isDouble?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <p className={styles.materialHeader}>
      <strong>{t(`cutlist-form.field.${i18nKey}.label`)}</strong>
      {isDouble && <span>{'x2'}</span>}
    </p>
  );
};

interface RowProps {
  item: Material;
}

export const MaterialItem = ({ item }: RowProps) => {
  const itemDimensions = materialDimensions(item);
  return (
    <div className={styles.materialContent} data-id={item.articleCode}>
      <img
        src={getSrcFromMaterial(item)}
        alt={item.name}
        onError={onImgError}
      />
      <div className="flexAlign gap-xs">
        <p>{item.name}</p>
        {'•'}
        <span>{itemDimensions}</span>
      </div>
    </div>
  );
};

const MaterialVariationItem = ({
  automaticSheetSizeMaterials,
}: {
  automaticSheetSizeMaterials: Material['articleCode'][];
}) => {
  if (automaticSheetSizeMaterials.length === 0) return null;
  const item = getMaterial(automaticSheetSizeMaterials[0]);
  if (!item) return null;
  const thicknessMM = item.thicknessUM / 1000;
  return (
    <div className={styles.materialContent} data-id={item.articleCode}>
      <img
        src={getSrcFromMaterial(item)}
        alt={item.variationGroup?.name || item.name}
        onError={onImgError}
      />
      <div className="flexAlign gap-xs">
        <p>{item.variationGroup?.name || item.name}</p>
        {'•'}
        <span>{`${thicknessMM} mm`}</span>
      </div>
    </div>
  );
};

export const MaterialItemSkeleton = ({
  placeholder,
}: {
  placeholder: string;
}) => {
  return (
    <div className={cn(styles.materialContent, styles.skeleton)}>
      <img src={emptySrc} alt={placeholder} />
      <p>{placeholder}</p>
    </div>
  );
};

export const CustomEdgebandGroup = ({ group }: { group: MaterialGroup }) => {
  const { edgebandingUI } = useCurrentFeatures();

  const edgebandingMaterials = useEdgebandingMaterialForGroup();
  const index = edgebandingMaterials.indexOf(group.edgeband);
  if (index > -1) {
    edgebandingMaterials.splice(index, 1);
  }

  return (
    <>
      {edgebandingUI &&
        edgebandingMaterials.map((m) => {
          const item = m as Material['articleCode'];
          const edgebandMaterial = getMaterial(item);

          return (
            <div
              key={item}
              className={cn(styles.materialCard, styles.disabled)}
            >
              {edgebandMaterial && <MaterialItem item={edgebandMaterial} />}
            </div>
          );
        })}
    </>
  );
};

export default MaterialGroupCard;
