import { Material } from '@cutr/constants/cutlist';
import {
  CUSTOM_ARTICLES_NARROW,
  CUSTOM_ARTICLES_WIDE,
  EDGEBANDING_TYPE,
  MATERIAL_TYPE,
} from '@cutr/constants/material';

import { getMaterial } from '@/api/materials';

import { isTruthy } from './misc';
import { getSizes } from './nesting';

const THICKNESS_SLACK_MM = 3;

export type SheetMaterials = {
  core1?: Material;
  core2?: Material;
  topHpl?: Material;
  bottomHpl?: Material;
};

export function findMatchingMaterialsWithFallback(
  sheetMaterials: SheetMaterials,
  ebMaterials: Material[]
) {
  const matching = findMatchingMaterials(sheetMaterials, ebMaterials);
  if (matching.length) {
    return matching;
  }

  const materialType = getFindMyMaterialType(sheetMaterials);

  if (
    !materialType ||
    ![EDGEBANDING_TYPE.abs_melamine, EDGEBANDING_TYPE.veneer_shinnoki].includes(
      materialType
    )
  ) {
    return [];
  }
  const { thickness } = getSizes(sheetMaterials);
  const matchingNarrowCustomEdgebands = CUSTOM_ARTICLES_NARROW[materialType]
    .map(getMaterial)
    .filter(isTruthy)
    .filter(
      (m) => m.widthMM * 1000 > thickness + THICKNESS_SLACK_MM * 1000
    ) as Material[];

  if (matchingNarrowCustomEdgebands.length)
    return matchingNarrowCustomEdgebands;

  // if sheet thickness is higher than the narrowest edgeband, return the widest
  return CUSTOM_ARTICLES_WIDE[materialType]
    .map(getMaterial)
    .filter(isTruthy) as Material[];
}

function findMatchingMaterials(
  sheetMaterials: SheetMaterials,
  ebMaterials: Material[]
) {
  const { thickness } = getSizes(sheetMaterials);
  const sheetMaterial = sheetMaterials.topHpl || sheetMaterials.core1;

  if (!sheetMaterial) return [];
  if (!sheetMaterial.colorCode) return [];

  const matching = ebMaterials.filter((material) => {
    return (
      material.colorCode === sheetMaterial.colorCode &&
      material.widthMM * 1000 > thickness + THICKNESS_SLACK_MM * 1000
    );
  });

  if (!matching.length) return [];

  const minWidth = Math.min(...matching.map((m) => m.widthMM));
  const hasStructure = Boolean(
    matching.find((m) => m.structureName === sheetMaterial.structureName)
  );
  const filterWidth = (m: Material) => m.widthMM === minWidth;
  const filterStructure = (m: Material) =>
    !hasStructure || m.structureName === sheetMaterial.structureName;
  const filteredMatching = matching.filter(filterWidth).filter(filterStructure);

  return filteredMatching.sort((a, b) => a.thicknessUM - b.thicknessUM);
}

// melamine sheets and hpl -> abs
// veneer -> veneer
function getFindMyMaterialType(materials: SheetMaterials) {
  const hplMaterial = materials.topHpl;
  const core1Material = materials.core1;

  if (!core1Material) return;

  // hpl
  if (hplMaterial && core1Material !== hplMaterial) {
    return EDGEBANDING_TYPE.abs_melamine;
  }

  // core
  const melamineSheets = [
    MATERIAL_TYPE.melamine_chipboard,
    MATERIAL_TYPE.melamine_mdf,
  ] as Material['type'][];

  if (melamineSheets.includes(core1Material.type)) {
    return EDGEBANDING_TYPE.abs_melamine;
  }

  if (core1Material.type === MATERIAL_TYPE.veneer_mdf) {
    return EDGEBANDING_TYPE.veneer_shinnoki;
  }
}
