import { Attachment } from '@cutr/constants/cutlist';
// @ts-ignore
import filthy from 'filthy-clean';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';

import { Dropdown } from '@/primitives/Dropdown';
import {
  Email,
  FileImage,
  FileSpreadsheet,
  FileText,
  Icon,
} from '@/primitives/Icons';
import { Textarea } from '@/primitives/Input';
import { CutlistEmailLoaderType } from '@/queries/loaders';

import styles from './CutlistEmailPreview.module.css';

const ALLOWED_ATTRS = [
  'href',
  'title',
  'alt',
  'src',
  'width',
  'height',
  'style',
  'span',
  'align',
];

const ALLOWED_NODES = [
  'div',
  'p',
  'span',
  'a',
  'br',
  'i',
  'em',
  'strong',
  'b',
  'img',
  'table',
  'thead',
  'th',
  'tr',
  'td',
  'tbody',
];

export const CutlistEmailPreview = () => {
  const { t } = useTranslation();
  const emailData = useLoaderData() as CutlistEmailLoaderType;

  const dropdownOptions = [
    {
      label: t('agent.emailPreview.email'),
      value: 'email',
      component: () => <DropdownOption label={t(`agent.emailPreview.email`)} />,
    },
  ].concat(
    emailData.attachments?.map((attachment) => ({
      label: attachment.name,
      value: attachment.signedUrl,
      isSelected: false,
      component: () => <AttachmentOption attachment={attachment} />,
    })) || []
  );

  if (!emailData || !emailData.htmlBody || !emailData.body) return null;

  return (
    <div className={styles.container}>
      <div className="flexAlign gap-s">
        <h3>{t('agent.emailPreview.title')}</h3>
        {Boolean(emailData.attachments?.length) && (
          <>
            <div style={{ width: '18rem' }}>
              <Dropdown
                readOnly
                style={{ borderRadius: '0.75rem' }}
                id="download-attachments"
                value={'email'}
                options={dropdownOptions}
              />
            </div>
            {/* <Button
              className="outline smaller"
              icon={<Icon icon={<Download />} />}
              size="s"
              onClick={() => {
                console.log('download attachments');
              }}
            >
              {t('agent.emailPreview.downloadAttachments')}
            </Button> */}
          </>
        )}
      </div>

      <EmailContent emailData={emailData} />
    </div>
  );
};

const EmailContent = ({ emailData }: { emailData: CutlistEmailLoaderType }) => {
  if (emailData.htmlBody) {
    const clean = filthy(emailData.htmlBody, {
      allowedNodes: ALLOWED_NODES,
      allowedAttrs: ALLOWED_ATTRS,
    });
    return (
      <div
        className={styles.body}
        dangerouslySetInnerHTML={{ __html: clean }}
      />
    );
  }

  return (
    <Textarea value={emailData.body} style={{ height: '20rem' }} readOnly />
  );
};

export const DropdownOption = ({ label }: { label: string }) => {
  return (
    <div className={styles.dropdownItem}>
      <div>{<Icon icon={<Email />} />}</div>

      <div className={styles.dropdownName}>
        <div>
          <strong>{label}</strong>
        </div>
      </div>
    </div>
  );
};

const AttachmentOption = ({ attachment }: { attachment: Attachment }) => {
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.svg'];
  const spreadsheetExtensions = ['.xlx', '.xlsx', '.xls'];
  const textExtensions = ['.pdf'];

  const extension =
    '.' + (attachment.name.split('.').pop() || 'pdf').toLowerCase();

  const isImage = imageExtensions.includes(extension);
  const isSpreadsheet = spreadsheetExtensions.includes(extension);
  const isText = textExtensions.includes(extension);

  return (
    <a
      href={attachment.signedUrl}
      className={styles.dropdownItem}
      download={attachment.name}
    >
      <div>
        {isImage && <Icon icon={<FileImage />} />}
        {isSpreadsheet && <Icon icon={<FileSpreadsheet />} />}
        {isText && <Icon icon={<FileText />} />}
      </div>

      <div className={styles.dropdownName}>
        <strong>{attachment.name}</strong>
      </div>
    </a>
  );
};
