import { CutlistOrder } from '@cutr/constants/cutlist';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useLeadDetails } from '@/api/account';
import { useCutlistState } from '@/api/store';
import Card from '@/blocks/Card';
import { ConfirmationDialog } from '@/blocks/ConfirmationDialog';
import { FullPriceDataCard } from '@/blocks/Price';
import { hydrateStoreFn, useFetchMaterials } from '@/hooks';
import { MaterialGroupTabNav } from '@/interfaces/MaterialGroupTabNav';
import { Nesting } from '@/interfaces/Nesting';
import { PartTable } from '@/interfaces/PartTable';
import { Button } from '@/primitives/Button';
import {
  useAgentFinaliseQuote,
  useIsPriceLoading,
  useUpdateCutlistAgent,
} from '@/queries/agent';
import { getCutlistQuery } from '@/queries/crud';

import { DetailsForm } from './AccountDetails';
import AgentDiscountModal from './AgentDiscountModal';
import { NotFound } from './Errors';
import { Header, SubHeader } from './Order';

export const AgentEditOrderPage = () => {
  useFetchMaterials();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const hydrateStore = hydrateStoreFn();
  const [error, setError] = React.useState<string>();
  const { state, pathname } = useLocation();
  const [loading, setLoading] = React.useState<boolean>(
    state?.newOrder ? false : true
  );

  React.useEffect(() => {
    if (!id) {
      setError('Not found');
      return;
    }

    if (state?.newOrder) {
      return;
    }

    setLoading(true);

    queryClient
      .fetchQuery<CutlistOrder>(getCutlistQuery(id))
      .then((cutlist) => {
        hydrateStore(cutlist);
      })
      .catch((e) => {
        setError(e?.message || 'Could not fetch cutlist');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return null;
  if (error) return <NotFound redirectTo="/admin/orders" />;

  if (pathname.includes('/edit')) {
    return <AgentEditOrder />;
  }

  return <NotFound />;
};

export const AgentEditOrder = (): JSX.Element => {
  const { email } = useLeadDetails();

  return (
    <main>
      <Header />
      <SubHeader />
      <MaterialGroupTabNav />
      <section className="layout">
        <div>
          <PartTable />
          {email && <DetailsForm />}
        </div>
        <Aside />
      </section>
    </main>
  );
};

const Aside = () => {
  const { email } = useLeadDetails();
  return (
    <aside style={{ top: 0 }}>
      <Nesting />
      {email && <PriceBlock />}
    </aside>
  );
};

const PriceBlock = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isPending, setIsPending] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const { mutateAsync: saveQuote } = useUpdateCutlistAgent();
  const { mutateAsync: finaliseQuote } = useAgentFinaliseQuote();

  const handleSubmitQuote = async () => {
    try {
      if (!id) return;

      setIsPending(true);
      const saveResult = await saveQuote({ id });
      if (saveResult) {
        await finaliseQuote({ id });
        toast.success(t('agent.submitQuote.success'));
        navigate(`/admin/orders`);
      }
      setIsPending(false);

      toast.success(t('agent.submitQuote.success'));
      navigate(`/admin/orders`);
    } catch (e) {
      toast.error(t('agent.submitQuote.error'));
    } finally {
      setIsPending(false);
    }
  };

  return (
    <>
      <Card>
        <FullPriceDataCard title={t('cutlist-summary.price.title')} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 'var(--space-s)',
            paddingTop: 'var(--space-m)',
          }}
        >
          <AddDiscount />
          <Button
            onClick={() => setShowConfirmModal(true)}
            disabled={isPending}
          >
            {t('cutlist-summary.price.total.saveAndSubmitQuote')}
          </Button>
        </div>
      </Card>
      <ConfirmationDialog
        title={t('agent.submitQuote.confirmationDialog.title')}
        onConfirm={handleSubmitQuote}
        onClose={() => setShowConfirmModal(false)}
        isOpen={showConfirmModal}
      >
        <div
          style={{
            paddingBottom: '1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '0',
          }}
        >
          <p style={{ margin: '0 0 var(--space-xs) 0' }}>
            {t('agent.submitQuote.confirmationDialog.line1')}
          </p>
          <p style={{ margin: '0' }}>
            {t('agent.submitQuote.confirmationDialog.line2')}
          </p>
        </div>
      </ConfirmationDialog>
    </>
  );
};

const AddDiscount = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { email: hasOwner } = useLeadDetails();
  const {
    discountAmount,
    discountPercentage,
    setDiscountAmount,
    setDiscountPercentage,
  } = useCutlistState();
  const { id } = useParams();
  const isPriceLoading = useIsPriceLoading(id!);

  const hasDiscount = discountAmount || discountPercentage;

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const removeDiscount = () => {
    setDiscountAmount(undefined);
    setDiscountPercentage(undefined);
  };

  if (!hasOwner) return null;

  return (
    <>
      {!hasDiscount && (
        <Button
          variant="secondary"
          className="full"
          onClick={handleOpenModal}
          disabled={isPriceLoading}
        >
          {t('agent.discount.buttonLabel')}
        </Button>
      )}
      {hasDiscount && (
        <Button
          variant="secondary"
          className="full"
          onClick={removeDiscount}
          disabled={isPriceLoading}
        >
          {t('agent.discount.removeButtonLabel')}
        </Button>
      )}
      <AgentDiscountModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};
