import { CutlistRequestType } from '@cutr/constants/cutlist';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAggregatedPrice } from '@/api/pricing';
import { useCutlistState } from '@/api/store';
import { ErrorMessage } from '@/blocks/ErrorMessage';
import { EstimatedTotal } from '@/blocks/Price';
import { StepNavigation } from '@/blocks/StepNavigation';
import { AddressRecap } from '@/interfaces/AddressRecap';
import { CutlistPartsRecap } from '@/interfaces/CutlistPartsRecap';
import { RouterButton } from '@/primitives/Button';
import { Edit, Icon } from '@/primitives/Icons';
import { useUpdateCutlist } from '@/queries/crud';
import { useCurrentSource } from '@/theme';

import styles from './OrderReview.module.css';

const OrderReview = () => {
  const { orderId } = useCutlistState();
  const { t } = useTranslation();

  return (
    <div className="content">
      <Header />
      <section className="layout">
        <main className={styles.review}>
          <div className="opposites">
            <h3>{t('review.subtitle')}</h3>
            <RouterButton
              to={`/cutlist/${orderId}/parts`}
              variant="secondary"
              icon={<Icon icon={<Edit />} />}
            >
              {t('review.cta.editOrder')}
            </RouterButton>
          </div>

          <CutlistPartsRecap />

          <AddressRecap />
        </main>
        <Aside />
      </section>
    </div>
  );
};

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className="opposites">
      <h1>{t('review.title')}</h1>
      <StepNavigation />
    </div>
  );
};

const Aside = () => {
  const priceData = useAggregatedPrice();
  const navigate = useNavigate();
  const source = useCurrentSource();
  const { mutateAsync } = useUpdateCutlist();
  const [error, setError] = React.useState('');

  return (
    <aside>
      {Boolean(error) && <ErrorMessage message={error} />}
      <EstimatedTotal
        onAction={(requestType: CutlistRequestType) =>
          mutateAsync({ submit: true, requestType })
            .then((cutlist) => {
              const event =
                requestType === 'place_order'
                  ? 'Order placed'
                  : 'Quote requested';
              window.analytics.track(event, { priceData, ticker: source });

              navigate(`/cutlist/${cutlist.id}/done`, {
                state: { requestType },
                replace: true,
              });
            })
            .catch((error) => {
              setError(error.message);
            })
        }
      />
    </aside>
  );
};

export default OrderReview;
