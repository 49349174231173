import { MaterialGroup } from '@cutr/constants/cutlist';
import {
  EdgeProfileType,
  GROUPED_EDGE_PROFILE_TYPES,
} from '@cutr/constants/edge-profiling';
import { useTranslation } from 'react-i18next';

import { getMaterial } from '@/api/materials';
import { Dropdown, DropdownOption } from '@/primitives/Dropdown';
import { edgeProfilesIconMap } from '@/primitives/EdgeProfileIcons';
import { Icon, Yep } from '@/primitives/Icons';
import { useCurrentFeatures, useCurrentSource } from '@/theme';
import { isTruthy } from '@/utils/misc';

import styles from './dropdown.module.css';

export const EdgeProfileDropdown = ({
  group,
  onEdgeProfileTypeUpdate,
  value,
  materialGroupValue,
}: {
  group: MaterialGroup;
  onEdgeProfileTypeUpdate: (edgeProfileType: EdgeProfileType) => void;
  value: EdgeProfileType;
  materialGroupValue?: EdgeProfileType;
}) => {
  const { t } = useTranslation();
  const { supportedEdgeProfiles } = useCurrentFeatures();
  const source = useCurrentSource();
  const core1Material = getMaterial(group.core1);

  const enabledOptions = supportedEdgeProfiles.filter((edgeProfileType) => {
    if (!core1Material) return false;

    if (source === 'VERDOUW') {
      if (edgeProfileType === 'none') return true;

      return core1Material.allowedEdgeProfiles.includes(edgeProfileType);
    }

    if (edgeProfileType === 'none') return true;

    if (
      core1Material.facetEdgeProfileAllowed &&
      ['facet-top', 'facet-both-sides'].includes(edgeProfileType)
    )
      return true;

    if (
      core1Material.roundEdgeProfileAllowed &&
      ['rounded-top', 'rounded-both-sides'].includes(edgeProfileType)
    )
      return true;

    if (core1Material.angledEdgeProfileAllowed && edgeProfileType === 'angle45')
      return true;
    if (
      core1Material.angledEdgeProfileAllowed &&
      core1Material.angledEdgeProfileType === '2-sides' &&
      edgeProfileType === 'angle135'
    )
      return true;

    return false;
  });

  const mapEdgeProfileTypeToComponent = (edgeProfileType: EdgeProfileType) => ({
    value: edgeProfileType,
    label: t(`cutlist-form.edgeProfiling.${edgeProfileType}`),
    selected: value === edgeProfileType,
    disabled: !enabledOptions.includes(edgeProfileType),
    component: ({ isSelected }: { isSelected?: boolean }) => (
      <EdgeProfileItem
        edgeProfileType={edgeProfileType}
        label={t(`cutlist-form.edgeProfiling.${edgeProfileType}`)}
        isSelected={isSelected}
      />
    ),
  });

  const groupedOptions = [
    materialGroupValue && materialGroupValue !== 'none'
      ? (['materialGroup', [materialGroupValue]] as [string, EdgeProfileType[]])
      : null,
    ...Object.entries(GROUPED_EDGE_PROFILE_TYPES),
  ]
    .filter(isTruthy)
    .map(([key, value]) => ({
      key: t(`cutlist-form.edgeProfiling.groups.${key}`),
      components: value
        .filter((edgeProfileType) =>
          supportedEdgeProfiles.includes(edgeProfileType)
        )
        .map((edgeProfileType) =>
          mapEdgeProfileTypeToComponent(edgeProfileType)
        ),
    }))
    .reduce(
      (acc, { key, components }) => ({ ...acc, [key]: components }),
      {} as Record<keyof typeof GROUPED_EDGE_PROFILE_TYPES, DropdownOption[]>
    );

  return (
    <Dropdown
      id={`edge-profile-${group.id}`}
      onSelected={(value) => onEdgeProfileTypeUpdate(value as EdgeProfileType)}
      groupedOptions={groupedOptions}
    />
  );
};

export const EdgeProfileItem = ({
  edgeProfileType,
  label,
  isSelected,
}: {
  edgeProfileType: EdgeProfileType;
  label: string;
  isSelected?: boolean;
}) => {
  return (
    <div className={styles.dropdownItem}>
      <Icon icon={edgeProfilesIconMap[edgeProfileType]} size={4} />

      <div className={styles.dropdownName}>
        <strong>{label}</strong>
      </div>
      <div>{isSelected && <Icon icon={<Yep />} />}</div>
    </div>
  );
};
