import {
  NestingConfig,
  NestingInput,
  NestingOutput,
  Orientation,
  PartIn,
  Sheet,
  SheetConfig,
} from '@cutr/constants/cutlist-nesting';

import { createNestingConfig } from './config';
import {
  area,
  fittingCount,
  initSheetConfig,
  maxReloadDepth,
  PANEL_SORT_SCENARIOS,
  PanelSortScenario,
  partFitsOffcut,
  partsFitSheet,
} from './helpers';
import { Offcut, Offcuts, removeDuplicateSizeOffcuts } from './offcut';
import { saw, SawingResult, SawingStep } from './sawing';
import {
  sheetEdgeTrimmingMM,
  sheetOffset,
  sheetTrimmedDimensions,
} from './sheet';

const THREAD_YIELD_THRESHOLD = 80;

export class CutlistNesting {
  nestingConfig: NestingConfig;

  // nesting input
  sheetConfig: SheetConfig;
  parts: PartIn[] = [];
  fullSheetParts: PartIn[] = [];
  stripsAndPanels: PartIn[] = [];

  // running nesting result
  sheets: Sheet[] = [];
  fullSheets: Sheet[] = [];
  sawingLengthMM = 0;
  stripSawingLengthMM = 0;
  sheetSawingLengthMM = 0;

  // most optimal nesting result found so far
  optimalNestingOutput: NestingOutput | null = null;

  // internal data structure
  offcuts: Offcuts = new Offcuts();

  nestingResultFoundCallback?: (nestingOutput: NestingOutput) => void;
  nestingFinalResultFoundCallback?: (nestingOutput: NestingOutput) => void;
  nestingCompletedCallback?: (value: unknown) => void;

  shouldAbort = false;
  loopIterations = 0;

  constructor({
    nestingInput,
    nestingConfig = createNestingConfig(),
  }: {
    nestingInput: NestingInput;
    nestingConfig?: NestingConfig;
  }) {
    this.nestingConfig = nestingConfig;
    this.sheetConfig = initSheetConfig(nestingInput.sheetConfig);
    this.parts = nestingInput.parts;
    this.fullSheetParts = [
      ...this.parts.filter((part) => part.partType === 'sheet'),
    ];
    this.stripsAndPanels = [
      ...this.parts.filter((part) => part.partType !== 'sheet'),
    ];
  }

  resetNestingState() {
    this.sheets = [];
    this.fullSheets = [];
    this.sawingLengthMM = 0;
    this.stripSawingLengthMM = 0;
    this.sheetSawingLengthMM = 0;
    this.offcuts = new Offcuts();
  }

  resetNestingOutput() {
    this.optimalNestingOutput = null;
    this.loopIterations = 0;
  }

  runFullSheets() {
    const SHEET_ID_OFFSET = 9999;
    let sheetIdCounter = 0;
    this.fullSheetParts.forEach((fullSheetPart) => {
      for (let i = 0; i < fullSheetPart.quantity; i++) {
        const sheetId = SHEET_ID_OFFSET + sheetIdCounter;
        sheetIdCounter += 1;

        this.fullSheets.push({
          sheetId: sheetId,
          parts: [
            {
              id: fullSheetPart.id,
              name: fullSheetPart.name,
              ...sheetOffset(this.sheetConfig),
              lengthMM: fullSheetPart.lengthMM,
              widthMM: fullSheetPart.widthMM,
              sheetId,
              orientation: 'horizontal',
              partType: fullSheetPart.partType,
              reloadDepth: 0,
            },
          ],
          usedAreaMM2: area(fullSheetPart),
          totalAreaMM2: area(this.sheetConfig),
        });
      }
    });
  }

  sheetCountLowerBound(parts: PartIn[]) {
    let totalRawArea = 0;
    parts.forEach((part) => {
      totalRawArea += area(part) * part.quantity;
    });
    return Math.ceil(totalRawArea / area(this.sheetConfig));
  }

  async runStripsAndPanels(panelSortScenario: PanelSortScenario) {
    // Start with theoretical lower bound for total sheets count
    const lowerBound = this.sheetCountLowerBound(this.stripsAndPanels);
    for (let i = 0; i < lowerBound; i++) this.addNewSheet();

    // Sort parts in descending order of their area size.
    // Don't modify the input, copy over into a new array.
    this.stripsAndPanels.sort((c1, c2) => {
      if (panelSortScenario === 'length')
        return c2.lengthMM != c1.lengthMM
          ? c2.lengthMM - c1.lengthMM
          : c2.widthMM - c1.widthMM;

      // panelSortScenario === 'area'
      return area(c2) - area(c1);
    });

    // First we set the depth limit where we start picking greedy choices to 0, then to 1, then 2, etc.
    // This ensures that we get a greedy solution fast, and then keep searching for better options.
    for (
      let i = 0;
      i <= this.nestingConfig.exponentialBranchingLevelLimit;
      i++
    ) {
      await this.recursiveSearchForNesting(i);
    }
  }

  addNewSheet(): number {
    const sheetId = this.sheets.length;
    // Adding a new sheet is just adding it as a new offcut ready to be used.
    this.sheets.push({
      sheetId,
      parts: [],
      usedAreaMM2: 0,
      totalAreaMM2: area(this.sheetConfig),
    });

    this.sawingLengthMM += sheetEdgeTrimmingMM(this.sheetConfig);

    return this.offcuts.addOffcut({
      ...sheetTrimmedDimensions(this.sheetConfig),
      sheetId,
      ...sheetOffset(this.sheetConfig),
      sheetConfig: this.sheetConfig,
      fullSheetOffcut: true,
      previousSawOrientation: null,
      reloadDepth: 0,
    });
  }

  revertAddingNewSheet(sheetOffcutId: number) {
    this.sawingLengthMM -= sheetEdgeTrimmingMM(this.sheetConfig);
    this.sheets.pop();
    this.offcuts.deleteOffcut(sheetOffcutId);
  }

  greedyPickSawingStep(edges: SawingStep[]): SawingStep {
    // Greedy scoring and picking happens in this function
    // Currently
    //   - it selects the sawing step that can fit the most parts
    //   - in case of a tie, it selects the smallest offcut
    return edges.reduce((prev, cur) => {
      const prevAmountsFit = prev.amountsFit;
      const curAmountsFit = cur.amountsFit;
      if (prevAmountsFit < curAmountsFit) return cur;
      if (prevAmountsFit > curAmountsFit) return prev;

      const prevArea = area(prev.offcutToSaw);
      const curArea = area(cur.offcutToSaw);
      return prevArea < curArea ? prev : cur;
    });
  }

  updateOptimalResult() {
    // deep copy lists to capture their snapshots
    const sheets = this.sheets.map((sheet): Sheet => {
      return {
        sheetId: sheet.sheetId,
        parts: [...sheet.parts],
        usedAreaMM2: sheet.usedAreaMM2,
        totalAreaMM2: sheet.totalAreaMM2,
      };
    });

    const stripPartsOnly = sheets
      .flatMap((sheet) => sheet.parts)
      .every((part) => part.partType === 'strip');

    const sawingLengths = stripPartsOnly
      ? {
          sawingLengthMM: 0,
          stripSawingLengthMM:
            Math.round(this.sawingLengthMM) +
            Math.round(this.stripSawingLengthMM),
          sheetSawingLengthMM: Math.round(this.sheetSawingLengthMM),
        }
      : {
          sawingLengthMM:
            Math.round(this.sawingLengthMM) +
            Math.round(this.stripSawingLengthMM),
          stripSawingLengthMM: 0,
          sheetSawingLengthMM: Math.round(this.sheetSawingLengthMM),
        };

    this.optimalNestingOutput = {
      sheets: [...sheets, ...this.fullSheets],
      ...sawingLengths,
      sheetConfig: this.sheetConfig,
    };

    this.nestingResultFoundCallback?.(this.optimalNestingOutput);
  }

  public abort() {
    this.shouldAbort = true;
  }

  public async run(
    nestingResultFoundCallback: (nestingOutput: NestingOutput) => void
  ) {
    this.nestingResultFoundCallback = nestingResultFoundCallback;
    this.resetNestingOutput();
    partsFitSheet(this.parts, this.sheetConfig);

    for (const panelSortScenario of PANEL_SORT_SCENARIOS)
      await this.runWithScenario(panelSortScenario as PanelSortScenario);

    if (this.optimalNestingOutput != null)
      this.nestingFinalResultFoundCallback?.(this.optimalNestingOutput);

    this.nestingCompletedCallback?.(this.loopIterations);
  }

  async runWithScenario(panelSortScenario: PanelSortScenario) {
    this.resetNestingState();
    this.runFullSheets();
    await this.runStripsAndPanels(panelSortScenario);
  }

  applySawing(sawingStep: SawingStep): SawingResult {
    const sawingResult = saw(sawingStep);

    if (sawingResult == null) {
      throw new Error('Incorrect sawing step has been detected.');
    }

    this.offcuts.useOffcut(sawingStep.offcutToSaw.offcutId as number);
    sawingResult.newOffcuts.forEach((newOffcut) => {
      this.offcuts.addOffcut(newOffcut);
    });
    sawingResult.parts.forEach((partOut) => {
      this.sheets[partOut.sheetId].parts.push(partOut);
      this.sheets[partOut.sheetId].usedAreaMM2 += area(partOut);
    });
    sawingStep.partToSaw.quantity -= sawingStep.amountsToSaw;
    this.sawingLengthMM += sawingResult.sawingLengthMM;
    this.stripSawingLengthMM += sawingResult.stripSawingLengthMM;

    return sawingResult;
  }

  revertSawing(sawingStep: SawingStep, sawingResult: SawingResult) {
    // reverts actions in applySawing() method in reverse order
    this.stripSawingLengthMM -= sawingResult.stripSawingLengthMM;
    this.sawingLengthMM -= sawingResult.sawingLengthMM;
    sawingStep.partToSaw.quantity += sawingStep.amountsToSaw;
    sawingResult.parts.forEach((partOut) => {
      this.sheets[partOut.sheetId].usedAreaMM2 -= area(partOut);
      this.sheets[partOut.sheetId].parts.pop();
    });
    sawingResult.newOffcuts
      .slice()
      .reverse()
      .forEach((newOffcut) => {
        if (!newOffcut.offcutId)
          throw new Error(`Offcut (${newOffcut}) does not have id.`);
        this.offcuts.deleteOffcut(newOffcut.offcutId);
      });
    this.offcuts.unuseOffcut(sawingStep.offcutToSaw.offcutId as number);
  }

  async recursiveSearchForNesting(remainingDepth: number) {
    if (this.shouldAbort) return;

    const sheetCount = this.sheets.length + this.fullSheets.length;

    if (this.optimalNestingOutput != null) {
      // We already used more sheets than in the optimal solution, so further search will not help.
      if (sheetCount > this.optimalNestingOutput.sheets.length) return;

      if (
        sheetCount === this.optimalNestingOutput.sheets.length &&
        maxReloadDepth(this.sheets) >=
          maxReloadDepth(this.optimalNestingOutput.sheets)
      )
        return;
    }

    const nextPart = this.stripsAndPanels.find((p) => p.quantity > 0);
    if (nextPart == undefined) {
      // End of recursion. Store the result if it's better than the optimal.
      if (this.optimalNestingOutput === null) {
        this.updateOptimalResult();
      } else if (sheetCount < this.optimalNestingOutput.sheets.length) {
        this.updateOptimalResult();
      } else if (
        sheetCount === this.optimalNestingOutput.sheets.length &&
        maxReloadDepth(this.sheets) <
          maxReloadDepth(this.optimalNestingOutput.sheets)
      ) {
        // when the sheet count is same, optimize for less total sawing length
        this.updateOptimalResult();
      }
      return;
    }

    this.loopIterations++;
    if (this.loopIterations % THREAD_YIELD_THRESHOLD === 0) {
      await new Promise((res) => setTimeout(res, 0));
    }

    // 1. Find set of candidate states that we can move onto.
    let offcutCandidates: Offcut[] = this.offcuts.pickFittingOffcuts(nextPart);
    // Prune out same size offcut candidates.
    // Especially speeds the algorithm in the beginning where all new sheets/offcuts have same sizes.
    offcutCandidates = removeDuplicateSizeOffcuts(offcutCandidates);
    // If no fitting offcut candidates found, add a new sheet
    // and store information to roll it back.
    let newSheetAdded = false;
    let newSheetOffcutId = 0;
    if (offcutCandidates.length === 0) {
      newSheetOffcutId = this.addNewSheet();
      newSheetAdded = true;
      offcutCandidates = this.offcuts.pickFittingOffcuts(nextPart);
      if (offcutCandidates.length === 0) {
        // this should never hit, as we do pre-processing on parts.
        throw new Error('Part can not be placed into a new sheet.');
      }
    }
    const allOrientations: Orientation[] = ['horizontal', 'vertical'];

    // 2. Compute set of edges that moves us onto those candidate states.
    let sawingSteps: SawingStep[] = [];
    offcutCandidates.forEach((offcutCandidate) => {
      allOrientations.forEach((partOrientation) => {
        if (partFitsOffcut(nextPart, partOrientation, offcutCandidate)) {
          const sawOrientations = offcutCandidate.fullSheetOffcut
            ? this.nestingConfig.supportedFirstCutOrientations
            : allOrientations;

          sawOrientations.forEach((sawOrientation) => {
            if (!offcutCandidate.offcutId)
              throw new Error(`Offcut (${offcutCandidate}) does not have id.`);

            const offcutToSaw = this.offcuts.getOffcut(
              offcutCandidate.offcutId
            );

            // try to fit as many of identical parts next to each other as possible
            const { amountsToSaw, amountsFit } = fittingCount(
              nextPart,
              offcutToSaw,
              partOrientation,
              sawOrientation,
              this.nestingConfig
            );

            sawingSteps.push({
              partToSaw: nextPart,
              amountsToSaw,
              amountsFit,
              offcutToSaw: offcutToSaw,
              partOrientation: partOrientation,
              sawOrientation: sawOrientation,
              nestingConfig: this.nestingConfig,
            });
          });
        }
      });
    });

    // 3. Do not branch exponentially past below some search depth.
    if (remainingDepth === 0) {
      sawingSteps = [this.greedyPickSawingStep(sawingSteps)];
    }

    // 4. For each edge, apply sawing, call recursion and rollback sawing after recursive call.
    for (const sawingStep of sawingSteps) {
      // a. apply sawing = changes search state
      const sawingResult = this.applySawing(sawingStep);
      // b. call recursion = move into a new state
      await this.recursiveSearchForNesting(Math.max(0, remainingDepth - 1));
      // c. rollback after recursive call = revert changes on search state
      this.revertSawing(sawingStep, sawingResult);
    }

    // If a new sheet was added, roll it back.
    if (newSheetAdded) {
      this.revertAddingNewSheet(newSheetOffcutId);
    }
  }
}
