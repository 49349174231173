import { useIsNextDisabled } from '@/hooks';

import { useIsValid } from './account';
import { useCutlistState } from './store';

export const useCanProceedToReview = () => {
  const isValid = useIsValid();
  const isNextDisabled = useIsNextDisabled();

  return !isNextDisabled && isValid;
};

export const useStepNavigation = () => {
  // returns whether Parts form next button is disabled
  const isNextDisabled = useIsNextDisabled();
  const canProceed = useCanProceedToReview();
  const store = useCutlistState();

  return [
    {
      labelKey: 'common.navigation.cutlist',
      pathname: `/cutlist/${store.orderId}/parts`,
      isClickable: true,
    },
    {
      labelKey: 'common.navigation.yourDetails',
      pathname: `/cutlist/${store.orderId}/details`,
      isClickable: !isNextDisabled,
    },
    {
      labelKey: 'common.navigation.review',
      pathname: `/cutlist/${store.orderId}/review`,
      isClickable: canProceed,
      final: true,
    },
  ];
};
