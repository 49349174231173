import { useTranslation } from 'react-i18next';

type Props = {
  customerReference: string;
  notes: string;
  deliverLeftoverMaterials: boolean;
};

export const CutlistNotesRecap = ({
  customerReference,
  notes,
  deliverLeftoverMaterials,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      {' '}
      {customerReference && (
        <>
          <p>
            <strong>{t('review.customerReference.title')}</strong>
          </p>
          <p>{customerReference}</p>
        </>
      )}
      {notes && (
        <>
          <p>
            <strong>{t('review.notes.title')}</strong>
          </p>
          <p>{notes}</p>
        </>
      )}
      <p>
        <strong>{t('review.leftoverMaterials.title')}</strong>
      </p>
      <p>
        {t(
          `review.${
            deliverLeftoverMaterials
              ? 'leftoverMaterials'
              : 'noLeftoverMaterials'
          }.body`
        )}
      </p>
    </div>
  );
};
