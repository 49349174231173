import { Material } from '@cutr/constants/cutlist';

const THUMBNAIL_PARTNERS = ['BNB', 'STIHO'];

const getImageFromCode = (code: Material['articleCode']) =>
  `https://bablo.nl.imgeng.in/INTERSHOP/static/WFS/stihoGroep-BaarsEnBloemhoff-Site/-/stihoGroep/nl_NL/ProductAfbeelding/M/${code}.jpg`;

export const emptySrc =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAQAAADa613fAAAAaElEQVR42u3PQREAAAwCoNm/9CL496ABuREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREWkezG8AZQ6nfncAAAAASUVORK5CYII=';

export const getSrcFromMaterial = (material: Material) => {
  if (material.imageUrl) return material.imageUrl;
  if (THUMBNAIL_PARTNERS.includes(material.supplierCode))
    return getImageFromCode(material.articleCode);

  return emptySrc;
};

export const onImgError: React.ReactEventHandler<HTMLImageElement> = (e) => {
  e.currentTarget.src = emptySrc;
  e.currentTarget.onerror = null;
};
