import { EdgeGroove, Material } from '@cutr/constants/cutlist';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type Edges = {
  length1: boolean;
  length2: boolean;
  width1: boolean;
  width2: boolean;
};

interface EdgeProcessingContextType {
  position: EdgeGroove['position'];
  setPosition: (position: EdgeGroove['position']) => void;
  dimensions: {
    offset: string;
    width: string;
    depth: string;
  };
  setDimensions: ({ offset, width, depth }: Partial<EdgeGroove>) => void;
  edgeband: Material['articleCode'];
  setEdgeband: (edgeband: Material['articleCode']) => void;
  roundedEdgeband: boolean;
  setRoundedEdgeband: (rounded: boolean) => void;
  edgesToApply: Edges;
  setEdgesToApply: Dispatch<SetStateAction<Edges>>;
  clearState: () => void;
}

const EdgeProcessingContext = createContext<
  EdgeProcessingContextType | undefined
>(undefined);

export const EdgeProcessingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [position, setPosition] = useState<EdgeGroove['position']>('none');
  const [dimensions, setDimensions] = useState({
    offset: '',
    width: '',
    depth: '',
  });
  const [edgeband, setEdgeband] = useState<Material['articleCode']>('');
  const [roundedEdgeband, setRoundedEdgeband] = useState(false);
  const [edgesToApply, setEdgesToApply] = useState({
    length1: false,
    length2: false,
    width1: false,
    width2: false,
  });

  const clearState = () => {
    setPosition('none');
    setDimensions({ offset: '', width: '', depth: '' });
    setEdgeband('');
    setRoundedEdgeband(false);
    setEdgesToApply({
      length1: false,
      length2: false,
      width1: false,
      width2: false,
    });
  };

  return (
    <EdgeProcessingContext.Provider
      value={{
        position,
        setPosition,
        dimensions,
        // @ts-expect-error - need to pass empty strings here so placeholders are visible
        setDimensions,
        edgeband,
        setEdgeband,
        roundedEdgeband,
        setRoundedEdgeband,
        edgesToApply,
        setEdgesToApply,
        clearState,
      }}
    >
      {children}
    </EdgeProcessingContext.Provider>
  );
};

export const useEdgeProcessing = () => {
  const context = useContext(EdgeProcessingContext);
  if (context === undefined) {
    throw new Error(
      'useEdgeProcessing must be used within an EdgeProcessingProvider'
    );
  }
  return context;
};
