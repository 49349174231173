import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import {
  Address,
  useAddressPretty,
  useDeliveryAddress,
  useIsAddressValid,
} from '@/api/address';
import { agentApi } from '@/api/backend/agent';
import { useCutlistState } from '@/api/store';
import { AddressInfo } from '@/blocks/AddressInfo';
import { AgentQuoteHeader } from '@/blocks/AgentOrderHeader';
import Card from '@/blocks/Card';
import { OwnerInfo } from '@/blocks/OwnerInfo';
import { PriceSummary } from '@/blocks/PriceSummary';
import { Button } from '@/primitives/Button';
import { Field } from '@/primitives/Field';
import { ArrowLeft, Download, Icon } from '@/primitives/Icons';
import { Textarea } from '@/primitives/Input';
import {
  useAgentCutlistPatch,
  useAgentFinaliseQuote,
  useAgentUpdateCutlistAddress,
} from '@/queries/agent';
import { saveBlob } from '@/utils/file';

import { DeliveryAddressForm } from './AccountDetails';
import styles from './AgentQuoteCheckout.module.css';

export const AgentQuoteCheckout = () => {
  const isAddressValid = useIsAddressValid();
  const [isAddressEditing, setIsAddressEditing] = useState(!isAddressValid);
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const handleBackToPricing = () => {
    navigate(`/admin/orders/${id}/quote/price`);
  };

  return (
    <div>
      <AgentQuoteHeader />
      <section className="layout">
        {/* Main content */}
        <div className={styles.orderInfo}>
          <div className="flexAlign gap-m gutter">
            <Button
              className="outline smaller"
              icon={<Icon icon={<ArrowLeft />} />}
              onClick={handleBackToPricing}
            >
              {t('agent.orderCheckout.backToPricing')}
            </Button>
            <h2>{t('agent.orderCheckout.checkout')}</h2>
          </div>
          <OwnerBlock />
          <DeliveryAddressBlock
            isAddressEditing={isAddressEditing}
            setIsAddressEditing={setIsAddressEditing}
          />
          <CustomerReferenceBlock />
          <InternalPointOfContactBlock />
          <OrderNotesBlock />
        </div>

        <Aside finaliseQuoteDisabled={isAddressEditing || !isAddressValid} />
      </section>
    </div>
  );
};

const Aside = ({
  finaliseQuoteDisabled,
}: {
  finaliseQuoteDisabled: boolean;
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { shortId } = useCutlistState();
  const { mutateAsync: finaliseQuote } = useAgentFinaliseQuote();
  const { mutateAsync: patchCutlist } = useAgentCutlistPatch(id!);
  const { title, notes, customerReference, internalPointOfContact } =
    useCutlistState();

  const [isLoading, setIsLoading] = useState(false);

  const handleFinaliseQuote = async () => {
    if (!id) return;
    setIsLoading(true);
    try {
      await patchCutlist({
        id,
        fields: {
          title,
          notes,
          customerReference,
          internalPointOfContact,
        },
      });

      await finaliseQuote({ id });
      toast.success(t('agent.orderCheckout.finalizeQuoteSuccess'));
    } catch (error) {
      toast.error(t('agent.orderCheckout.finalizeQuoteError'));
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadSap = async () => {
    if (!id) return;

    try {
      setIsLoading(true);
      await patchCutlist({
        id,
        fields: {
          title,
          notes,
          customerReference,
          internalPointOfContact,
        },
      });
      toast.success(t('common.cta.orderSaved'));

      const result = await agentApi.getCutlistSap(id);
      saveBlob(result, `${shortId}_sap.xlsx`);
    } catch (error) {
      toast.error(t('agent.toasts.fileDownloadError'));
    } finally {
      setIsLoading(false);
    }
  };

  const downloadPricingPDF = async () => {
    if (!id) return;

    try {
      setIsLoading(true);
      await patchCutlist({
        id,
        fields: {
          title,
          notes,
          customerReference,
          internalPointOfContact,
        },
      });
      toast.success(t('common.cta.orderSaved'));

      const result = await agentApi.getCutlistPricingPDF(id);
      saveBlob(result, `${shortId}_pricing.pdf`);
    } catch (error) {
      toast.error(t('agent.toasts.fileDownloadError'));
    } finally {
      setIsLoading(false);
    }
  };

  const downloadPricingSheet = async () => {
    if (!id) return;

    try {
      setIsLoading(true);
      await patchCutlist({
        id,
        fields: {
          title,
          notes,
          customerReference,
          internalPointOfContact,
        },
      });
      toast.success(t('common.cta.orderSaved'));

      const result = await agentApi.getCutlistPricingSheet(id);
      saveBlob(result, `${shortId}_pricing.xlsx`);
    } catch (error) {
      toast.error(t('agent.toasts.fileDownloadError'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PriceSummary isReadOnly={true}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--space-s)',
          paddingTop: 'var(--space-m)',
        }}
      >
        <Button
          variant="secondaryPill"
          className="full"
          style={{ fontWeight: 600 }}
          icon={<Icon icon={<Download />} />}
          onClick={downloadPricingSheet}
          disabled={isLoading || finaliseQuoteDisabled}
        >
          {t('agent.orderCheckout.exportPricingSheet')}
        </Button>
        <Button
          variant="secondaryPill"
          className="full"
          style={{ fontWeight: 600 }}
          icon={<Icon icon={<Download />} />}
          onClick={downloadPricingPDF}
          disabled={isLoading || finaliseQuoteDisabled}
        >
          {t('agent.orderCheckout.exportPDF')}
        </Button>
        <Button
          variant="secondaryPill"
          className="full"
          style={{ fontWeight: 600 }}
          icon={<Icon icon={<Download />} />}
          onClick={downloadSap}
          disabled={isLoading || finaliseQuoteDisabled}
        >
          {t('agent.orderCheckout.exportSAP')}
        </Button>
        <Button
          onClick={handleFinaliseQuote}
          disabled={isLoading || finaliseQuoteDisabled}
        >
          {t('agent.orderCheckout.finalizeQuote')}
        </Button>
      </div>
    </PriceSummary>
  );
};

const OwnerBlock = () => {
  const { t } = useTranslation();
  return (
    <div className={'opposites'}>
      <h3>{t('agent.orderCheckout.owner')}</h3>
      <div className={styles.blockInfo}>
        <Card>
          <OwnerInfo />
        </Card>
      </div>
    </div>
  );
};

const DeliveryAddressBlock = ({
  isAddressEditing,
  setIsAddressEditing,
}: {
  isAddressEditing: boolean;
  setIsAddressEditing: (value: boolean) => void;
}) => {
  const deliveryInfo = useAddressPretty();
  const { t } = useTranslation();
  const {
    setProp: setDeliveryProp,
    init,
    ...deliveryAddress
  } = useDeliveryAddress();
  const { mutate: updateAddress } = useAgentUpdateCutlistAddress();
  const { id } = useParams();
  const [initialAddress, setInitialAddress] =
    useState<Address>(deliveryAddress);
  const isAddressValid = useIsAddressValid();
  const [isCancelEnabled, setIsCancelEnabled] = useState(isAddressValid);

  const handleSave = async () => {
    if (!id) return;
    try {
      updateAddress({ id, address: deliveryAddress });
      setInitialAddress(deliveryAddress);
      toast.success(t('agent.orderCheckout.deliveryAddressUpdated'));
    } catch (error) {
      toast.error(t('agent.orderCheckout.deliveryAddressUpdateError'));
    } finally {
      setIsAddressEditing(false);
      setIsCancelEnabled(true);
    }
  };
  return (
    <div className={'opposites'}>
      <h3>{t('agent.orderCheckout.deliveryTo')}</h3>
      <div className={styles.blockInfo}>
        {isAddressEditing ? (
          <Card>
            <form onSubmit={handleSave}>
              <DeliveryAddressForm
                title={''}
                address={deliveryAddress}
                setProp={setDeliveryProp}
              />
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  justifyContent: 'flex-end',
                  marginTop: '-1rem',
                }}
              >
                <Button type="submit" variant="straight">
                  {t('common.cta.save')}
                </Button>

                <Button
                  variant="secondary"
                  disabled={!isCancelEnabled}
                  onClick={() => {
                    init(initialAddress as Address);
                    setIsAddressEditing(false);
                  }}
                >
                  {t('common.cta.cancel')}
                </Button>
              </div>
            </form>
          </Card>
        ) : (
          <Card>
            <AddressInfo
              info={deliveryInfo}
              onEditAddress={() => setIsAddressEditing(true)}
            />
          </Card>
        )}
      </div>
    </div>
  );
};

const OrderNotesBlock = () => {
  const { notes, setNotes } = useCutlistState();
  const { t } = useTranslation();

  return (
    <div className={'opposites'}>
      <h3>{t('agent.orderCheckout.orderNotes')}</h3>
      <div className={styles.blockInfo}>
        <Textarea
          className="lighterBorder"
          name="notes"
          onInput={(e) => setNotes(e.currentTarget.value)}
          value={notes || ''}
          placeholder={t('review.notes.description')}
          rows={4}
        />
      </div>
    </div>
  );
};

const CustomerReferenceBlock = () => {
  const { customerReference, setCustomerReference } = useCutlistState();
  const { t } = useTranslation();
  return (
    <div className={'opposites'}>
      <h3>{t('agent.orderCheckout.customerReference')}</h3>
      <Field
        className={cn('fullWidth', 'lighterBorder')}
        label={''}
        name="customerReference"
        type="text"
        setProp={(value) => {
          setCustomerReference(value);
        }}
        value={customerReference || ''}
        maxLength={255}
      />
    </div>
  );
};

const InternalPointOfContactBlock = () => {
  const { internalPointOfContact, setInternalPointOfContact } =
    useCutlistState();
  const { t } = useTranslation();

  return (
    <div className={'opposites'}>
      <h3>{t('agent.orderCheckout.internalPointOfContact')}</h3>
      <Field
        className={cn('fullWidth', 'lighterBorder')}
        label={''}
        name="internalPointOfContact"
        type="text"
        setProp={(value) => {
          setInternalPointOfContact(value);
        }}
        value={internalPointOfContact || ''}
        maxLength={255}
      />
    </div>
  );
};
