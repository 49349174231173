import { Material } from '@cutr/constants/cutlist';
import { ALL_CUSTOM_ARTICLES } from '@cutr/constants/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useAggregatedMaterials, usePricingStore } from '@/api/pricing';
import { useCutlistState } from '@/api/store';
import { useCurrentFeatures } from '@/theme';
import { currencyFormatter } from '@/utils/format';

import styles from './MaterialRow.module.css';
import { getSrcFromMaterial, onImgError } from './materialRowUtils';

type MaterialRow = {
  item: Material;
  inputString?: string;
  onSelected?: (code: Material['articleCode'], input?: string) => void;
};
type RowProps = React.ButtonHTMLAttributes<HTMLDivElement> & MaterialRow;

export const materialDimensions = (item: Material): string => {
  return item.lengthMM && item.widthMM && item.thicknessUM
    ? `${item.lengthMM} x ${item.widthMM} x ${item.thicknessUM / 1000} mm`
    : item.shortDescription;
};

const RowOption = ({ item }: { item: Material }) => (
  <div>
    <div>
      <strong>{item.name}</strong>
    </div>
    {item.shortDescription}
  </div>
);

const RowItem = ({ item }: { item: Material }) => {
  const { showPricing } = usePricingStore();
  const { showMaterialListPrice } = useCurrentFeatures();
  const { t } = useTranslation();
  const { currency } = useCutlistState();

  const itemDimensions = materialDimensions(item);

  return (
    <>
      <div>
        <img
          onError={onImgError}
          src={getSrcFromMaterial(item)}
          alt={item.shortDescription}
          loading="lazy"
        />
      </div>
      <div>
        <div>
          <strong>{item.name}</strong>
        </div>

        {[
          itemDimensions,
          item.fscClaimLabel,
          item.pefcClaimLabel,
          `${t('common.article')}: ${item.articleCode}`,
        ]
          .filter(Boolean)
          .join(', ')}
      </div>
      <div>
        {showPricing && showMaterialListPrice && (
          <div>
            <strong>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {currencyFormatter(item.priceAmount / 100, currency)} /{' '}
              {item.priceUnit === 'piece' ? t('common.piece') : item.priceUnit}
            </strong>
          </div>
        )}
        <span className={cn([styles.badge, styles.pill, styles.outline])}>
          {t(`cutlist-form.materialTypes.${item.type}`)}
        </span>
      </div>
    </>
  );
};

export const MaterialRow = ({
  item,
  inputString,
  onSelected,
  className,
  ...props
}: RowProps) => {
  const isOption = (ALL_CUSTOM_ARTICLES as string[]).includes(item.articleCode);

  return (
    <div
      className={cn(styles.materialRow, className)}
      data-id={item.articleCode}
      data-cy={item.articleCode}
      onClick={() => onSelected?.(item.articleCode, inputString)}
      {...props}
    >
      {isOption ? <RowOption item={item} /> : <RowItem item={item} />}
    </div>
  );
};

type AggregatedMaterialsProps = {
  items: ReturnType<typeof useAggregatedMaterials>;
};
export const AggregatedMaterials = (props: AggregatedMaterialsProps) => {
  const { items, ...otherProps } = props;

  return (
    <>
      {items.map((material) => (
        <div
          key={material.id}
          className={cn(styles.materialRow, styles.spaced)}
          {...otherProps}
        >
          <div>
            <img
              onError={onImgError}
              src={getSrcFromMaterial(material)}
              alt={material.name}
            />
          </div>
          <div>
            <div>{material.name}</div>
            <div>{materialDimensions(material)}</div>
            <span style={{ fontSize: 'smaller' }}>
              {material.shortDescription}
            </span>
          </div>
          <div style={{ textAlign: 'right' }}>{material.articleCode}</div>
        </div>
      ))}
    </>
  );
};
