import { DotCutlistState } from '@cutr/constants/cutlist';
import { defaultSheetEdgeTrimConfigAllSides } from '@cutr/constants/cutlist-theme';

import { getSandwichType } from './materials';

export function backwardsCompatibleDotCutlistFileContent(
  dotCutlistFileContent: DotCutlistState
): DotCutlistState {
  if (!dotCutlistFileContent) return dotCutlistFileContent;
  let newDotCutlistFileContent = {
    ...dotCutlistFileContent,
    parts: dotCutlistFileContent.parts.map((part) => {
      let newPart = { ...part };

      newPart = Object.keys(newPart).includes('partType')
        ? newPart
        : { ...newPart, partType: 'panel' };

      newPart = Object.keys(newPart).includes('createLabel')
        ? newPart
        : { ...newPart, createLabel: false };
      return newPart;
    }),
  };
  if (newDotCutlistFileContent.groupState) {
    newDotCutlistFileContent.groupState = {
      ...newDotCutlistFileContent.groupState,
      groups: newDotCutlistFileContent.groupState.groups.map((group) => {
        let newGroup = { ...group };

        // set all sides to 10 for all older BnB orders
        newGroup = Object.keys(newGroup).includes('sheetEdgeTrimConfig')
          ? newGroup
          : {
              ...newGroup,
              sheetEdgeTrimConfig: defaultSheetEdgeTrimConfigAllSides,
            };

        newGroup = Object.keys(newGroup).includes('sheetSizeSelection')
          ? newGroup
          : {
              ...newGroup,
              sheetSizeSelection: 'manual',
              automaticSheetSizeMaterials: [],
            };

        const allPartsAreSheets = newDotCutlistFileContent.parts
          .filter((p) => p.groupId === newGroup.id)
          .every((p) => p.partType === 'sheet');
        newGroup = Object.keys(newGroup).includes('type')
          ? newGroup
          : {
              ...newGroup,
              type: allPartsAreSheets ? 'sheets-only' : 'panels-and-strips',
            };
        return { ...newGroup, materialSandwichType: getSandwichType(newGroup) };
      }),
    };
  }

  // we had an outage that had corrupted .cutlist files so that it had parts that didn't have corresponding groups
  // this is dangerous because parts won't show anywhere in the UI, but will be used for everything else (pricing, pdf, etc).
  // filter out any part that doesn't have corresponding group information
  newDotCutlistFileContent = {
    ...newDotCutlistFileContent,
    parts: newDotCutlistFileContent.parts.filter((part) => {
      return (newDotCutlistFileContent.groupState?.groups || []).some(
        (group) => group.id === part.groupId
      );
    }),
    status: 'draft', //everytime we open a .cutlist it will be a draft
    currency: newDotCutlistFileContent.currency || 'EUR',
    sourceCurrency: newDotCutlistFileContent.sourceCurrency || 'EUR',
    exchangeRate: newDotCutlistFileContent.exchangeRate || 1,
  };
  return newDotCutlistFileContent;
}
