import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import s from './Configurator.module.css';
import { NavSidebar } from './NavSidebar';
import { Preview } from './Preview';

const queryClient = new QueryClient();
const Providers = ({ children }: PropsWithChildren) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export const Configurator = () => {
  const { id } = useParams();

  return (
    <div className={s.configuratorLayout}>
      <Providers>
        <NavSidebar />
        {id && <Preview id={id} />}
      </Providers>
    </div>
  );
};
