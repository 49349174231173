import { MaterialGroup, MaterialSandwichType } from '@cutr/constants/cutlist';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  DoubleCore,
  DoubleCoreDoubleLayer,
  Icon,
  SingleCore,
  SingleCoreDoubleLayer,
} from '@/primitives/Icons';
import { useCurrentFeatures } from '@/theme';

import styles from './MaterialSandwichSelect.module.css';

export const sandwichIconMap = {
  'single-core': <SingleCore />,
  'single-core-double-layer': <SingleCoreDoubleLayer />,
  'double-core': <DoubleCore />,
  'double-core-double-layer': <DoubleCoreDoubleLayer />,
};

export const MaterialSandwichSelect = ({
  group,
  onSandwichTypeUpdate,
}: {
  group: MaterialGroup;
  onSandwichTypeUpdate: (sandwichType: MaterialSandwichType) => void;
}) => {
  const { t } = useTranslation();
  const { supportedMaterialSandwiches } = useCurrentFeatures();

  const options = supportedMaterialSandwiches.map((sandwichType, index) => ({
    index,
    sandwichType,
    label: t(`cutlist-form.materialSandwichTypes.${sandwichType}.label`),
    isSelected: group.materialSandwichType === sandwichType,
  }));

  if (options.length <= 1) return null;

  // if none selected, select the first one
  if (!options.some((option) => option.isSelected))
    options[0].isSelected = true;

  return (
    <div className={styles.materialSandwitchGrid}>
      {options.map((option) => {
        return (
          <SandwichItem
            key={option.index}
            sandwichType={option.sandwichType}
            label={option.label}
            onSandwichSelect={onSandwichTypeUpdate}
            isSelected={option.isSelected}
          />
        );
      })}
    </div>
  );
};

export const SandwichItem = ({
  sandwichType,
  label,
  isSelected,
  onSandwichSelect,
}: {
  sandwichType: MaterialSandwichType;
  label: string;
  isSelected?: boolean;
  onSandwichSelect: (type: MaterialSandwichType) => void;
}) => {
  return (
    <div
      data-id={sandwichType}
      className={cn(
        styles.materialSandwitchItem,
        isSelected && styles.selected
      )}
      onClick={() => onSandwichSelect(sandwichType)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSandwichSelect(sandwichType);
        }
      }}
      role="button"
      tabIndex={0}
      aria-label={label}
    >
      <div>
        <Icon icon={sandwichIconMap[sandwichType]} size={5.5} />
      </div>

      <div>
        <div>
          <strong>{label}</strong>
        </div>
      </div>
    </div>
  );
};
