import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { agentApi } from '@/api/backend/agent';
import { useAgentLoggedIn, useAuthStore } from '@/api/login';
import { ErrorMessage } from '@/blocks/ErrorMessage';
import { Button } from '@/primitives/Button';
import { Input } from '@/primitives/Input';

import styles from './AgentLogin.module.css';
const ENVIRONMENT = import.meta.env.VITE_CUTR_ENV;

export const AgentLogin = () => {
  const { t } = useTranslation();
  const { loginAgent } = useAuthStore();
  const [error, setError] = React.useState('');
  const navigate = useNavigate();
  const isAgent = useAgentLoggedIn();
  const { pathname } = useLocation();

  if (isAgent && pathname === '/admin') {
    const path = '/admin/orders';
    return <Navigate to={path} />;
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    const formData = new FormData(e.currentTarget);
    setError('');
    const email = formData.get('email') as string;
    const password = formData.get('password') as string;

    agentApi
      .login(email, password)
      .then(() => {
        return agentApi.sessionToToken();
      })
      .then((data) => {
        const { email, cutlistAdminSourceId, token, id } = data;
        const agent = { email, cutlistAdminSourceId, id };
        loginAgent({ agent, token });

        navigate('/admin/orders');
      })
      .catch((e) => {
        setError(e.message);
        console.error(e);
      });

    e.preventDefault();
  };

  const handleDevLogin = () => {
    agentApi
      .devLogin()
      .then((data) => {
        const { email, cutlistAdminSourceId, token, id } = data;
        const agent = { email, cutlistAdminSourceId, id };
        loginAgent({ agent, token });

        navigate('/admin/orders');
      })
      .catch((e) => {
        setError(e.message);
        console.error(e);
      });
  };

  return (
    <main className="content">
      <div className={styles.agentLogin}>
        <div className={styles.header}>
          <h2>{t('agent.login.title')}</h2>
          <p>{t('agent.login.description')}</p>
        </div>

        <form onSubmit={onSubmit}>
          <div>
            <label htmlFor="email">
              {t('agent.login.email.label')} {'*'}
            </label>
            <div className={styles.help}>{t('agent.login.email.help')}</div>
            <Input type="email" name="email" required autoComplete="email" />
          </div>
          <div>
            <label htmlFor="password">
              {t('agent.login.password.label')} {'*'}
            </label>
            <div className={styles.help}>{t('agent.login.password.help')}</div>
            <Input
              type="password"
              name="password"
              required
              autoComplete="current-password"
            />
          </div>
          <Button type="submit">{t('agent.login.submit')}</Button>
        </form>
        {ENVIRONMENT !== 'production' && (
          <Button onClick={handleDevLogin}>{'Dev login'}</Button>
        )}
        <p />
        {Boolean(error) && <ErrorMessage message={error} />}
      </div>
    </main>
  );
};
