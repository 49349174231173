import {
  NestingConfig,
  Orientation,
  PartIn,
  PartOut,
} from '@cutr/constants/cutlist-nesting';

import { partFitsOffcut } from './helpers';
import { Offcut } from './offcut';

export type SawingStep = {
  partToSaw: PartIn;
  amountsToSaw: number;
  amountsFit: number;
  offcutToSaw: Offcut;
  partOrientation: Orientation;
  sawOrientation: Orientation;
  nestingConfig: NestingConfig;
};

export type SawingResult = {
  parts: PartOut[];
  newOffcuts: Offcut[];
  sawingLengthMM: number;
  stripSawingLengthMM: number;
};

function partReloadDepth(
  offcutToSaw: Offcut,
  sawOrientation: Orientation,
  offcutAValid: boolean,
  offcutBValid: boolean
): number {
  if (!offcutAValid && !offcutBValid) return offcutToSaw.reloadDepth;
  if (offcutToSaw.fullSheetOffcut) return 1;

  const prevSawOrientation = offcutToSaw.previousSawOrientation;

  if (prevSawOrientation === 'horizontal' && sawOrientation === 'horizontal')
    return offcutToSaw.reloadDepth + 1;

  if (prevSawOrientation === 'horizontal' && sawOrientation === 'vertical')
    return offcutToSaw.reloadDepth + 2;

  if (prevSawOrientation === 'vertical' && sawOrientation === 'vertical')
    return offcutToSaw.reloadDepth + 1;

  // prevSawOrientation = vertical && sawOrientation = horizontal
  return offcutToSaw.reloadDepth + 2;
}

function offcutReloadDepths(
  offcutToSaw: Offcut,
  sawOrientation: Orientation
): { reloadDepthOffcutA: number; reloadDepthOffcutB: number } {
  if (offcutToSaw.fullSheetOffcut && sawOrientation === 'horizontal')
    return { reloadDepthOffcutA: 1, reloadDepthOffcutB: 0 };

  if (offcutToSaw.fullSheetOffcut && sawOrientation === 'vertical')
    return { reloadDepthOffcutA: 0, reloadDepthOffcutB: 1 };

  const prevSawOrientation = offcutToSaw.previousSawOrientation;

  if (prevSawOrientation === 'horizontal' && sawOrientation === 'horizontal') {
    return {
      reloadDepthOffcutA: offcutToSaw.reloadDepth,
      reloadDepthOffcutB: offcutToSaw.reloadDepth + 1,
    };
  }
  if (prevSawOrientation === 'horizontal' && sawOrientation === 'vertical') {
    return {
      reloadDepthOffcutA: offcutToSaw.reloadDepth + 1,
      reloadDepthOffcutB: offcutToSaw.reloadDepth + 2,
    };
  }

  if (prevSawOrientation === 'vertical' && sawOrientation === 'vertical') {
    return {
      reloadDepthOffcutA: offcutToSaw.reloadDepth,
      reloadDepthOffcutB: offcutToSaw.reloadDepth + 1,
    };
  }

  // prevSawOrientation = vertical && sawOrientation = horizontal
  return {
    reloadDepthOffcutA: offcutToSaw.reloadDepth + 2,
    reloadDepthOffcutB: offcutToSaw.reloadDepth + 1,
  };
}

// Gets a SawingStep and returns the result of that SawingStep by creating PartOuts, new offcuts, etc.
export function saw(sawingStep: SawingStep): SawingResult | null {
  const {
    partToSaw,
    amountsToSaw,
    offcutToSaw,
    partOrientation,
    sawOrientation,
    nestingConfig,
  } = sawingStep;
  if (!partFitsOffcut(partToSaw, partOrientation, offcutToSaw)) return null;

  //   partOrientation = vertical     partOrientation = horizontal
  //      .----------------.             .----------------.
  //      |      |         |             |  part   |      |
  //      | part |         |             |---------.      |
  //      |      |         |             |                |
  //      |------.         |             |                |
  //      |         offcut |             |         offcut |
  //      .----------------.             .----------------.

  //   sawOrientation = vertical      sawOrientation = horizontal
  //      .----------------.             .----------------.
  //      |  part   |      |             |  part   |   A  |
  //      |---------.      |             |---------.......|
  //      |         .  A   |             |                |
  //      |    B    .      |             |         B      |
  //      |         .      |             |                |
  //      .----------------.             .----------------.

  //  We place "amountsToSaw" pieces of partToSaw next to each other in the sawOrientation:
  //
  //   sawOrientation = vertical        sawOrientation = horizontal
  //   .----------------------.         .--------------------------.
  //   |  part   |            |         | part |...| part |   A    |
  //   |---------.            |         |-----------------.........|
  //   |   ...   |            |         |                          |
  //   |---------.    A       |         |                          |
  //   |  part   |            |         |             B            |
  //   |---------.            |         |                          |
  //   |         .            |         |                          |
  //   |    B    .            |         |                          |
  //   |         .            |         |                          |
  //   .----------------------.         .--------------------------.

  const partLengthMM =
    partOrientation === 'horizontal' ? partToSaw.lengthMM : partToSaw.widthMM;
  const partWidthMM =
    partOrientation === 'horizontal' ? partToSaw.widthMM : partToSaw.lengthMM;

  // totalLengthMM and totalWidthMM  refer to the "used" portion of the offcut
  // for "amountsToSaw" number identical parts.
  const totalLengthMM =
    sawOrientation === 'horizontal'
      ? partLengthMM * amountsToSaw +
        nestingConfig.bladeThickness * (amountsToSaw - 1)
      : partLengthMM;
  const totalWidthMM =
    sawOrientation === 'horizontal'
      ? partWidthMM
      : partWidthMM * amountsToSaw +
        nestingConfig.bladeThickness * (amountsToSaw - 1);

  const { reloadDepthOffcutA, reloadDepthOffcutB } = offcutReloadDepths(
    offcutToSaw,
    sawOrientation
  );
  // offcutA and offcutB are new offcuts from the leftovers
  const offcutA: Offcut = {
    lengthMM:
      offcutToSaw.lengthMM - totalLengthMM - nestingConfig.bladeThickness,
    widthMM:
      sawOrientation === 'horizontal' ? totalWidthMM : offcutToSaw.widthMM,
    sheetId: offcutToSaw.sheetId,
    offsetX: offcutToSaw.offsetX + totalLengthMM + nestingConfig.bladeThickness,
    offsetY: offcutToSaw.offsetY,
    sheetConfig: offcutToSaw.sheetConfig,
    fullSheetOffcut: false,
    previousSawOrientation: 'vertical',
    reloadDepth: reloadDepthOffcutA,
  };
  const offcutB: Offcut = {
    lengthMM:
      sawOrientation === 'horizontal' ? offcutToSaw.lengthMM : totalLengthMM,
    widthMM: offcutToSaw.widthMM - totalWidthMM - nestingConfig.bladeThickness,
    sheetId: offcutToSaw.sheetId,
    offsetX: offcutToSaw.offsetX,
    offsetY: offcutToSaw.offsetY + totalWidthMM + nestingConfig.bladeThickness,
    sheetConfig: offcutToSaw.sheetConfig,
    fullSheetOffcut: false,
    previousSawOrientation: 'horizontal',
    reloadDepth: reloadDepthOffcutB,
  };

  // create result array of 'valid' offcuts
  const newOffcuts: Offcut[] = [];
  const offcutAValid = offcutA.lengthMM > 0 && offcutA.widthMM > 0;
  if (offcutAValid) newOffcuts.push(offcutA);
  const offcutBValid = offcutB.lengthMM > 0 && offcutB.widthMM > 0;
  if (offcutBValid) newOffcuts.push(offcutB);

  // we first saw along the offcut, and the in the opposite direction saw each piece of partToSaw
  let sawingLengthMM = 0;
  if (sawOrientation == 'horizontal') {
    sawingLengthMM +=
      totalWidthMM < offcutToSaw.widthMM ? offcutToSaw.lengthMM : 0; // horizontal saw

    sawingLengthMM +=
      totalLengthMM < offcutToSaw.lengthMM
        ? amountsToSaw * totalWidthMM
        : (amountsToSaw - 1) * totalWidthMM; // all vertical saws
  } else if (sawOrientation == 'vertical') {
    sawingLengthMM +=
      totalLengthMM < offcutToSaw.lengthMM ? offcutToSaw.widthMM : 0; // vertical saw

    sawingLengthMM +=
      totalWidthMM < offcutToSaw.widthMM
        ? amountsToSaw * totalLengthMM
        : (amountsToSaw - 1) * totalLengthMM; // all horizontal saws
  }

  const parts: PartOut[] = [];
  for (let i = 0; i < amountsToSaw; i++) {
    if (sawOrientation == 'horizontal') {
      parts.push({
        id: partToSaw.id,
        name: partToSaw.name,
        offsetX:
          offcutToSaw.offsetX +
          i * (partLengthMM + nestingConfig.bladeThickness),
        offsetY: offcutToSaw.offsetY,
        sheetId: offcutToSaw.sheetId,
        lengthMM: partLengthMM,
        widthMM: partWidthMM,
        orientation: partOrientation,
        partType: partToSaw.partType,
        reloadDepth: partReloadDepth(
          offcutToSaw,
          sawOrientation,
          offcutAValid,
          offcutBValid
        ),
      });
    } else {
      parts.push({
        id: partToSaw.id,
        name: partToSaw.name,
        offsetX: offcutToSaw.offsetX,
        offsetY:
          offcutToSaw.offsetY +
          i * (partWidthMM + nestingConfig.bladeThickness),
        sheetId: offcutToSaw.sheetId,
        lengthMM: partLengthMM,
        widthMM: partWidthMM,
        orientation: partOrientation,
        partType: partToSaw.partType,
        reloadDepth: partReloadDepth(
          offcutToSaw,
          sawOrientation,
          offcutAValid,
          offcutBValid
        ),
      });
    }
  }

  return {
    parts: parts,
    newOffcuts: newOffcuts,
    sawingLengthMM: partToSaw.partType === 'panel' ? sawingLengthMM : 0,
    stripSawingLengthMM: partToSaw.partType === 'strip' ? sawingLengthMM : 0,
  };
}
