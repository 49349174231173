import React from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { cutlistsApi } from '@/api/backend/cutlists';
import { selectHasMaterials, useCutlistState } from '@/api/store';
import { hydrateStoreFn, useFetchMaterials, useIsNextDisabled } from '@/hooks';
import { isInIframe } from '@/utils/misc';

import { NotFound } from './Errors';
import { SkeletonOrderPage } from './Order';

export const CutlistRoutes = () => {
  useFetchMaterials();
  const hasMaterials = useCutlistState(selectHasMaterials);
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const articleCode = searchParams.get('material');
  const groupToFocusId = searchParams.get('groupId');

  const [error, setError] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState<boolean | null>(
    state?.newOrder ? false : true
  );
  const isSubmitted = React.useRef<boolean>(false);

  const isNextDisabled = useIsNextDisabled();
  const hydrateStore = hydrateStoreFn();

  const isPartsPage = pathname.includes('/parts');
  const uiLoading = isLoading || !hasMaterials;
  const isEmbedded = isInIframe();

  React.useEffect(() => {
    if (!id) {
      setError('Not found');
      return;
    }

    if (!hasMaterials) {
      return;
    }

    if (state?.newOrder) {
      window.history.replaceState({}, '');
      return;
    }

    cutlistsApi
      .getCutlist(id)
      .then((cutlist) => {
        const missingMaterials = Array.from(
          new Set([
            ...(state?.missingArticleCodes || []),
            ...(articleCode ? [articleCode] : []),
          ])
        );

        const hydrateOptions = {
          missingMaterials,
          activeGroupArticleCode: articleCode || undefined,
          activeGroupId: groupToFocusId || undefined,
        };
        hydrateStore(cutlist, hydrateOptions);
        isSubmitted.current = Boolean(cutlist.submittedAt);
      })
      .catch((e) => {
        setError(e?.message || 'Could not fetch cutlist');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [hasMaterials]);

  React.useEffect(() => {
    // when in admin editing mode, we don't want to force redirect
    if (isLoading || isPartsPage || isEmbedded) return;

    if (isNextDisabled) {
      navigate(`/cutlist/${id}/parts?${searchParams.toString()}`, {
        replace: true,
      });
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (isLoading) return;

    if (isEmbedded || !isSubmitted.current) {
      if (isPartsPage) return;
      navigate(`/cutlist/${id}/parts`, { replace: true });
      return;
    }

    navigate(`/cutlist/${id}`, { replace: true });
  }, [isLoading]);

  if (uiLoading && !isPartsPage) return null;
  if (uiLoading) return <SkeletonOrderPage />;
  if (error) return <NotFound />;
  return <Outlet />;
};
