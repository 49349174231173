import {
  CutlistMaterialGroup,
  Material,
  MaterialGroup,
  MaterialGroupState,
  PartItem,
} from '@cutr/constants/cutlist';
import { BackendCutlistGroup } from '@cutr/constants/cutlist-backend';
import { Features } from '@cutr/constants/cutlist-theme';
import * as Sentry from '@sentry/react';
import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { materialGroupMapper, partMapper } from '@/hooks';
import {
  useDebouncedParts,
  useDebouncedSheetGroups,
} from '@/interfaces/Nesting';
import {
  useCreateMaterialGroup,
  useUpdateMaterialGroup,
} from '@/queries/materialGroup';
import { findMatchingMaterialsWithFallback } from '@/utils/edgeband';
import {
  applyCustomTrimThickness,
  getTrimConfigByType,
} from '@/utils/features';
import { useDebounce } from '@/utils/hooks';
import { hashString, isTruthy } from '@/utils/misc';
import {
  createBackendGrouping,
  getSmallestFittingMaterial,
  groupPartsByMaterial,
} from '@/utils/nesting';

import { useIsLoggedIn } from './login';
import {
  getEdgebandingMaterials,
  getHPLMaterials,
  getMaterial,
} from './materials';
import { useCutlistParts, useCutlistState } from './store';

export type MaterialComponent = 'core1' | 'topHpl' | 'bottomHpl' | 'edgeband';

type MaterialGroupStateActions = {
  addGroup(group?: Partial<MaterialGroup>): MaterialGroup['id'];
  addGroupsByArticleCodes(
    articleCodes: Material['articleCode'][],
    currentFeatures: Features,
    activeGroupArticleCode?: string
  ): void;
  setGroup(group: MaterialGroup): void;
  removeGroup(id: string): void;
  setActive(id: string): void;
  clear(): void;
  groupBy(): void;
  init(
    state: MaterialGroupState,
    options?: { missingMaterials?: string[] }
  ): void;

  // These functions are used by the Agent Quoting flow
  addBackendGroup(group: MaterialGroup): void;
  setGroupIndex(groupId: string, index: number): void;
};

const generateMaterialGroup = (
  materialGroup?: Partial<MaterialGroup>
): MaterialGroup => ({
  id: nanoid(6),
  name: '',
  core1: null,
  core2: null,
  topHpl: null,
  bottomHpl: null,
  materialSandwichType: null,
  sheetEdgeTrimConfig: null,
  sheetSizeSelection: 'manual',
  automaticSheetSizeMaterials: [],
  edgeProfile: 'none',
  edgeband: null,
  createLabels: false,
  ...materialGroup,
});

export const setGroupAndParts = (group: MaterialGroup) => {
  useMaterialGroupState.getState().setGroup(group);
  useCutlistState.getState().updatePartsFromGroup(group);
};

export const updateAutomaticSheetSizeMaterials = (group: MaterialGroup) => {
  if (group.sheetSizeSelection !== 'automatic') return;
  const { parts } = useCutlistState.getState();
  const groupParts = parts.filter((p) => p.groupId === group.id);
  const updatedCore = getSmallestFittingMaterial(
    groupParts,
    group.automaticSheetSizeMaterials
  );

  if (group.core1 != updatedCore) {
    const updatedGroup = { ...group, core1: updatedCore };
    setGroupAndParts(updatedGroup);
  }
};

export const articleCodeToMaterialGroupMapper = (
  articleCode: string,
  currentFeatures: Features
) => {
  const { supportedSheetEdgeTrims, defaultSheetEdgeTrimTypeForPartGroup } =
    currentFeatures;

  let groupParam: Partial<MaterialGroup> = {
    core1: articleCode,
    materialSandwichType: 'single-core',
    type: 'panels-and-strips',
    sheetEdgeTrimConfig: getTrimConfigByType(
      supportedSheetEdgeTrims,
      defaultSheetEdgeTrimTypeForPartGroup
    ),
  };
  const hplMaterials = getHPLMaterials();
  const isHpl = !!hplMaterials.find((x) => x.articleCode === articleCode);
  const edgebandMaterials = getEdgebandingMaterials();
  const isEdgeband = !!edgebandMaterials.find(
    (x) => x.articleCode === articleCode
  );
  if (isHpl) {
    groupParam = {
      topHpl: articleCode,
      bottomHpl: articleCode,
      materialSandwichType: 'single-core-double-layer',
    };
  }
  if (isEdgeband) {
    groupParam = {
      edgeband: articleCode,
      materialSandwichType: 'single-core-double-layer',
    };
  }

  const materialGroup = generateMaterialGroup(groupParam);

  const material = getMaterial(articleCode);
  if (groupParam.type === 'panels-and-strips') {
    applyCustomTrimThickness(materialGroup, material);
  }

  return materialGroup;
};

export const useMaterialGroupState = create<
  MaterialGroupState & MaterialGroupStateActions
>()(
  devtools(
    (set, get) => ({
      groups: [],
      init: (state) => {
        const groups = [...state.groups];

        set(() => {
          return { ...state, groups };
        });
      },
      groupBy: () => {
        if (get().groups?.length) return;

        const { parts, setPart } = useCutlistState.getState();
        const groups = groupPartsByMaterial(parts);
        const materialGroups: MaterialGroup[] = [];
        const newParts: PartItem[] = [];

        if (!groups.size) {
          materialGroups.push(generateMaterialGroup());
        }

        groups.forEach((groupParts) => {
          if (!groupParts.length) return;

          const part = groupParts[0];
          const materialGroup = generateMaterialGroup({
            core1: part.core1,
            core2: part.core2,
            topHpl: part.topHpl,
            bottomHpl: part.bottomHpl,
          });

          materialGroups.push(materialGroup);
          groupParts.forEach((part) => {
            newParts.push({ ...part, groupId: materialGroup.id });
          });
        });

        set(() => ({
          groups: materialGroups,
          activeGroup: materialGroups[0].id,
        }));
        newParts.forEach((part) => setPart(part));
      },
      addGroup: (group = {}) => {
        const materialGroup = generateMaterialGroup(group);
        set((state) => {
          return {
            groups: [...state.groups, materialGroup],
            activeGroup: materialGroup.id,
          };
        });

        return materialGroup.id;
      },
      addGroupsByArticleCodes: (
        articleCodes,
        currentFeatures,
        activeGroupArticleCode
      ) => {
        set((state) => {
          const groups = [
            ...state.groups,
            ...articleCodes.map((articleCode) =>
              articleCodeToMaterialGroupMapper(articleCode, currentFeatures)
            ),
          ];

          const activeGroupId = groups.find(
            (g) => g.core1 === activeGroupArticleCode
          )?.id;

          return {
            groups,
            activeGroup: activeGroupId || state.activeGroup || groups[0]?.id,
          };
        });
      },
      setGroup: (group) => {
        set((state) => {
          const index = state.groups.findIndex((g) => g.id === group.id);
          const groups = state.groups.slice(0);

          if (groups[index]?.core1 !== group?.core1) {
            group.edgeProfile = 'none';
          }

          groups[index] = group;

          return { groups };
        });
      },
      removeGroup: (id) => {
        const { removePartsByGroup } = useCutlistState.getState();
        removePartsByGroup(id);
        set((state) => {
          const isSelected = state.activeGroup === id;
          const idx = state.groups.findIndex((g) => g.id == id);
          const groups = state.groups.filter((g) => g.id !== id);
          const next = Math.max(0, idx - 1);
          return {
            activeGroup: isSelected ? groups[next]?.id : state.activeGroup,
            groups,
          };
        });
      },
      setActive: (id) => set(() => ({ activeGroup: id })),
      clear: () => set(() => ({ groups: [], activeGroup: undefined })),

      addBackendGroup: (group: MaterialGroup) => {
        const groups = [...get().groups, group];

        set(() => {
          return { activeGroup: group.id, groups };
        });
      },
      setGroupIndex: (groupId, index) => {
        set((state) => {
          const position = state.groups.findIndex((g) => g.id === groupId);
          const group = state.groups[position];
          const groups = state.groups.slice(0);

          const { parts } = useCutlistState.getState();
          const groupParts = parts.filter((p) => p.groupId === groupId);

          const newGroup = { ...group, index, hash: undefined };

          const hash = hashString(
            JSON.stringify({
              ...newGroup,
              parts: groupParts,
            })
          );
          groups[position] = { ...newGroup, hash };

          return { groups };
        });
      },
    }),
    { name: 'materialsGroup' }
  )
);

export const useMaterialGroups = () =>
  useMaterialGroupState((state) => state.groups);

const groupBySelector = (state: MaterialGroupStateActions) => state.groupBy;
export const useGroupBy = () => useMaterialGroupState(groupBySelector);

export const activeGroupSelector = (state: MaterialGroupState) =>
  state.groups.find((g) => g.id === state.activeGroup);
export const useActiveGroup = () => useMaterialGroupState(activeGroupSelector);

export const useActiveGroupParts = () => {
  const activeGroup = useActiveGroup();
  const parts = useCutlistState((state) => state.parts);
  return parts.filter((p) => p.groupId === activeGroup?.id);
};

const groupSelectorById = (state: MaterialGroupState, id: string) =>
  state.groups.find((g) => g.id === id);
export const useGroupById = (id: string) =>
  useMaterialGroupState((state) => groupSelectorById(state, id));

export const edgebandSelector = (state: MaterialGroupState) => {
  const activeGroup = activeGroupSelector(state);
  return Boolean(activeGroup?.edgeband);
};
export const useEdgebandSelected = () =>
  useMaterialGroupState(edgebandSelector);

export const sheetEdgeTrimConfigSelector = (state: MaterialGroupState) => {
  const activeGroup = activeGroupSelector(state);
  return activeGroup?.sheetEdgeTrimConfig;
};
export const useSheetEdgeTrimConfig = () =>
  useMaterialGroupState(sheetEdgeTrimConfigSelector);

export const useActiveGroupMaterials = () => {
  const group = useActiveGroup();
  if (!group) return {};

  const [core1, core2, topHpl, bottomHpl] = [
    group.core1,
    group.core2,
    group.topHpl,
    group.bottomHpl,
  ].map(getMaterial);

  return { core1, core2, topHpl, bottomHpl };
};

export const useMatchingMaterials = () => {
  const edgebandingMaterials = getEdgebandingMaterials();
  const sheetMaterials = useActiveGroupMaterials();
  return findMatchingMaterialsWithFallback(
    sheetMaterials,
    edgebandingMaterials
  );
};

export const useActiveGroupGrainDirection = () => {
  const materials = useActiveGroupMaterials();

  if (materials.topHpl || materials.bottomHpl) {
    const topHplGrainDirection = materials.topHpl?.hasGrainDirection;
    const topHplTextureDirection = materials.topHpl?.hasTextureDirection;
    const bottomHplGrainDirection = materials.bottomHpl?.hasGrainDirection;
    const bottomHplTextureDirection = materials.bottomHpl?.hasTextureDirection;
    return [
      topHplGrainDirection,
      topHplTextureDirection,
      bottomHplGrainDirection,
      bottomHplTextureDirection,
    ].some((it) => it === true);
  }
  return (
    materials.core1?.hasGrainDirection || materials.core1?.hasTextureDirection
  );
};

export const useEdgebandingMaterialForGroup = () => {
  const group = useActiveGroup();
  const parts = useCutlistParts();

  return Array.from(
    new Set(
      parts
        // you only want to check against the active group
        .filter((p) => p.groupId === group?.id)
        .flatMap((p) => [
          p.edgebanding?.length1,
          p.edgebanding?.length2,
          p.edgebanding?.width1,
          p.edgebanding?.width2,
        ])
    )
  ).filter(Boolean);
};

export function makeTitle(group: MaterialGroup) {
  const material = getMaterial(group.core1);
  const coreName =
    group.sheetSizeSelection === 'automatic'
      ? material?.variationGroup?.name || material?.name
      : material?.name;
  return [coreName || group.core1, group.topHpl, group.bottomHpl]
    .filter(Boolean)
    .join(' / ');
}

export const useSaveSensitiveData = () => {
  const group = useActiveGroup();
  const debouncedGroup = useDebounce<MaterialGroup | undefined>(group, 500);
  const { title } = useCutlistState();
  const debouncedTitle = useDebounce<string>(title, 500);

  const saveSensitiveData = React.useMemo(() => {
    const hasNotes = debouncedGroup
      ? Boolean(
          [
            debouncedGroup.continuousGrain,
            debouncedGroup.additionalProcessing,
          ].filter(isTruthy).length
        )
      : false;

    return {
      hasNotes,
      debouncedTitle,
    };
  }, [debouncedGroup, debouncedTitle]);

  return saveSensitiveData;
};

export const useActiveGroupPricingSensitiveData = () => {
  const activeGroup = useActiveGroup();
  const { discountAmount, discountPercentage, markupAmount, markupPercentage } =
    useCutlistState();
  let {
    paintColor = null,
    paintThicknessUM = 0,
    edgeProfile,
    createLabels,
  } = activeGroup || {};
  const isLoggedIn = useIsLoggedIn();

  if (!paintThicknessUM) paintThicknessUM = 0;

  const priceSensitiveData = React.useMemo(() => {
    return {
      paintColor,
      paintThicknessUM,
      edgeProfile,
      createLabels,
      isLoggedIn,
      discountAmount,
      discountPercentage,
      markupAmount,
      markupPercentage,
    };
  }, [
    paintColor,
    paintThicknessUM,
    edgeProfile,
    createLabels,
    isLoggedIn,
    discountAmount,
    discountPercentage,
    markupAmount,
    markupPercentage,
  ]);

  return priceSensitiveData;
};

export const useIsGroupSaved = () => {
  const groups = useMaterialGroups();
  const parts = useCutlistState((state) => state.parts);

  const isGroupSaved = useCallback(
    (groupId: string) => {
      const group = groups.find((g) => g.id === groupId);
      if (!group) return false;
      const savedStateHash = group.hash;

      const groupParts = parts.filter((p) => p.groupId === group.id);
      const groupWithoutHash = { ...group, hash: undefined };
      const currentStateHash = hashString(
        JSON.stringify({ ...groupWithoutHash, parts: groupParts })
      );

      return currentStateHash === savedStateHash;
    },
    [groups, parts]
  );

  return isGroupSaved;
};

export const useHasGroupChanged = ({ groupId }: { groupId: string }) => {
  const isGroupSaved = useIsGroupSaved();

  return !isGroupSaved(groupId);
};

export const useUnsavedGroups = () => {
  const isGroupSaved = useIsGroupSaved();
  const groups = useMaterialGroups();

  const unsavedGroups = groups.filter((g) => !isGroupSaved(g.id));

  return unsavedGroups;
};

/**
 * @returns nestingResult
 * nestingResult is the actual nesting result or null if the group is not nested
 */
export const useIsNesting = ({
  groupId,
}: {
  groupId: string;
}): BackendCutlistGroup | null => {
  const [debouncedGroups, debouncedGroupsRef] = useDebouncedSheetGroups();
  const { groups } = useMaterialGroupState();
  const [nestingResult, setNestingResult] =
    useState<BackendCutlistGroup | null>(null);
  const [parts] = useDebouncedParts();

  useEffect(() => {
    setNestingResult(null);
  }, [parts]);

  useEffect(() => {
    if (!debouncedGroups.length) {
      return;
    }

    const cutlistGroups = createBackendGrouping(debouncedGroups, groups);

    const nestingResult = cutlistGroups.find(
      (g) => g.materialGroup.groupId === groupId
    );

    if (!nestingResult) return;

    setNestingResult(nestingResult);
  }, [debouncedGroupsRef]);

  return nestingResult;
};

/**
 * @returns nestingResults
 * nestingResults contains all the material groups that are nested
 */
export const useIsSomeNesting = (): BackendCutlistGroup[] | null => {
  const [debouncedGroups, debouncedGroupsRef] = useDebouncedSheetGroups();
  const { groups } = useMaterialGroupState();
  const [nestingResults, setNestingResults] = useState<
    BackendCutlistGroup[] | null
  >(null);
  const [parts] = useDebouncedParts();

  useEffect(() => {
    setNestingResults(null);
  }, [parts]);

  useEffect(() => {
    if (!debouncedGroups.length) {
      return;
    }

    const cutlistGroups = createBackendGrouping(debouncedGroups, groups);

    if (!cutlistGroups.length) return;

    setNestingResults(cutlistGroups);
  }, [debouncedGroupsRef]);

  return nestingResults;
};

/**
 * @returns [saveMaterialGroup, canSaveMaterialGroup, isSaving]
 * saveMaterialGroup is the function to save the material group
 * canSaveMaterialGroup is true if the material group can be saved
 * isSaving is true if the material group is still being saved
 */
export const useSaveGroupChanges = ({
  group,
}: {
  group: MaterialGroup;
}): [() => Promise<void>, boolean] => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { mutateAsync: updateMaterialGroup, isPending: isSaving } =
    useUpdateMaterialGroup(id as string);
  const nestingResult = useIsNesting({ groupId: group.id });
  const hydrateMaterialGroups = useHydrateMaterialGroups();
  const hasGroupChanged = useHasGroupChanged({ groupId: group.id });

  const canSave =
    !!nestingResult && Boolean(group.ulid) && hasGroupChanged && !isSaving;

  const handleSaveMaterialGroup = useCallback(async () => {
    if (!nestingResult) return;
    if (!group.ulid) return;

    const backendGroups = await updateMaterialGroup({
      materialGroupId: group.ulid,
      materialGroup: nestingResult,
    });

    hydrateMaterialGroups({
      backendGroups,
      groupId: group.id,
    });

    toast.success(t('agent.toasts.materialSettingsSaved'));
  }, [nestingResult]);

  return [handleSaveMaterialGroup, canSave];
};

/**
 * @returns [createMaterialGroup, canCreateMaterialGroup, isCreating]
 * createMaterialGroup is the function to create a new material group
 * canCreateMaterialGroup is true if the material group can be created
 * isCreating is true if the material group is still being created
 */
export const useCreateGroup = ({
  group,
}: {
  group: MaterialGroup;
}): [() => Promise<void>, boolean] => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { mutateAsync: createMaterialGroup, isPending: isCreating } =
    useCreateMaterialGroup(id as string);
  const nestingResult = useIsNesting({ groupId: group.id });
  const hydrateMaterialGroups = useHydrateMaterialGroups();

  const canCreate = !!nestingResult && Boolean(group.core1) && !isCreating;

  const handleCreateMaterialGroup = useCallback(async () => {
    if (!nestingResult) return;
    const backendGroups = await createMaterialGroup(nestingResult);

    hydrateMaterialGroups({
      backendGroups,
      groupId: group.id,
    });

    toast.success(t('agent.toasts.materialAddedToOrder'));
  }, [nestingResult]);

  return [handleCreateMaterialGroup, canCreate];
};

/**
 * @returns [saveMaterialGroup, canSaveMaterialGroup, isSaving]
 * saveMaterialGroup is the function to save the material group
 * canSaveMaterialGroup is true if the material group can be saved
 * isSaving is true if the material group is still being saved
 */
export const useSaveAllGroupChanges = (): [() => Promise<number>, boolean] => {
  const { id } = useParams();
  const hydrateMaterialGroups = useHydrateMaterialGroups();
  const unsavedGroups = useUnsavedGroups();
  const nestingResults = useIsSomeNesting();

  const { mutateAsync: createMaterialGroup, isPending: isCreating } =
    useCreateMaterialGroup(id as string);
  const { mutateAsync: updateMaterialGroup, isPending: isUpdating } =
    useUpdateMaterialGroup(id as string);

  const canSave = !!nestingResults && !isCreating && !isUpdating;

  const handleSaveAllMaterialGroups = useCallback(async (): Promise<number> => {
    if (!nestingResults) return Promise.resolve(0);
    if (!nestingResults.length) return Promise.resolve(0);
    if (!unsavedGroups.length) return Promise.resolve(0);

    const unsavedIds = unsavedGroups.map((g) => g.id);
    const unsavedBackendGroups = nestingResults.filter(
      (g) =>
        unsavedIds.includes(g.materialGroup.groupId) &&
        g.materialGroup.core1MaterialId
    );

    for (const group of unsavedBackendGroups) {
      if (group.materialGroup.ulid) {
        const backendGroups = await updateMaterialGroup({
          materialGroupId: group.materialGroup.ulid,
          materialGroup: group,
        });

        hydrateMaterialGroups({
          backendGroups,
          groupId: group.materialGroup.groupId,
        });
      }

      if (!group.materialGroup.ulid) {
        const backendGroups = await createMaterialGroup(group);

        hydrateMaterialGroups({
          backendGroups,
          groupId: group.materialGroup.groupId,
        });
      }
    }

    return Promise.resolve(unsavedBackendGroups.length);
  }, [nestingResults]);

  return [handleSaveAllMaterialGroups, canSave];
};

/**
 * @returns hydrateMaterialGroups
 * hydrateMaterialGroups is a function which:
 * - removes the old group with groupId
 * - adds the new group with backend data
 * - sets the parts for the new group
 * - resets all the groups indexes
 */
const useHydrateMaterialGroups = () => {
  const { setPart } = useCutlistState();

  const { addBackendGroup, removeGroup, setGroupIndex } =
    useMaterialGroupState();

  const handler = useCallback(
    async ({
      backendGroups,
      groupId,
    }: {
      backendGroups: CutlistMaterialGroup[];
      groupId: string;
    }) => {
      const backendGroup = backendGroups.find(
        (g) => g.groupId === groupId
      ) as CutlistMaterialGroup;

      if (!backendGroup) {
        Sentry.captureException(
          'New group (before mapping to FE structure) not found. This should never happen.',
          // @ts-expect-error Own error type
          { extra: backendGroups }
        );
        return;
      }

      const newGroup = materialGroupMapper(backendGroup);
      removeGroup(groupId);
      addBackendGroup(newGroup);

      backendGroup.parts.forEach((part) => {
        const partItem = partMapper(part);
        setPart(partItem, newGroup);
      });

      backendGroups.forEach((g) => {
        setGroupIndex(g.groupId, g.index);
      });
    },
    []
  );

  return handler;
};
