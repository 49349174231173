import { useLeadDetails } from '@/api/account';
import { Button } from '@/primitives/Button';
import { Edit, Icon, UserAvatar } from '@/primitives/Icons';

import styles from './OwnerInfo.module.css';

export const OwnerInfo = ({ onEdit }: { onEdit?: () => void }) => {
  const { name, email } = useLeadDetails();
  return (
    <div className={styles.container}>
      <div className="opposites">
        <h4 className={styles.title}>
          <Icon icon={<UserAvatar />} size={2.5} />
          {name}
        </h4>
        {onEdit && (
          <Button
            style={{
              color: ' var(--primary)',
            }}
            size="xs"
            variant="icon"
            className="outline"
            icon={<Icon icon={<Edit />} />}
            onClick={() => onEdit()}
          />
        )}
      </div>
      <p>{email}</p>
    </div>
  );
};
