import { RAL_TO_HEX } from '@cutr/constants/colors';
import { MaterialGroup } from '@cutr/constants/cutlist';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getMaterial } from '@/api/materials';
import { setGroupAndParts, useActiveGroup } from '@/api/materialsGroup';
import { Modal } from '@/blocks/Modal';
import { Button } from '@/primitives/Button';
import { Edit, Icon, PaintingBullet, Trash } from '@/primitives/Icons';
import { Select } from '@/primitives/Select';
import { useToggle } from '@/utils/hooks';

import styles from './PaintingSelect.module.css';

const RAL_COLORS_SUBSET = [
  'RAL1013',
  'RAL9001',
  'RAL9010',
  'RAL9016',
  'RAL7016',
  'RAL7024',
];

const THICKNESSES = ['80', '100', '120', '140'];

const DEFAULT_THICKNESS = THICKNESSES[0];

export const PaintingSelect = () => {
  const activeGroup = useActiveGroup()!;
  const [isOpen, toggleModal] = useToggle(false);
  const { t } = useTranslation();

  if (!activeGroup) return null;

  const paint = {
    label: activeGroup.paintColor || t('cutlist-form.edgeProfiling.none'),
    name: activeGroup.paintColor,
    thicknessUM: activeGroup.paintThicknessUM,
  };
  const hideColorBullet = !activeGroup.paintColor;

  const core1Material = getMaterial(activeGroup.core1);
  const disabled = !(core1Material?.paintingAllowed || false);

  const tooltip = disabled
    ? t('cutlist-form.paintingNotSupportedTooltip')
    : undefined;

  return (
    <>
      <div className={styles.paintingBox}>
        <div className="flexAlign opposites">
          <div
            aria-disabled={disabled}
            className={classNames(styles.paintingItem, tooltip && 'tooltip')}
            role="button"
            tabIndex={0}
            onClick={() => {
              if (disabled) return;
              toggleModal();
            }}
            data-tooltip={tooltip}
          >
            <Icon
              style={{
                color: paint.name ? RAL_TO_HEX[paint.name] : 'var(--gray-8)',
                visibility: hideColorBullet ? 'hidden' : 'visible',
                width: hideColorBullet ? 0 : undefined,
              }}
              icon={<PaintingBullet />}
              size={4}
            />
            <strong>{paint.label}</strong>
            <span style={{ color: 'var(--gray-8)' }}>
              {paint.thicknessUM ? paint.thicknessUM + ' µm' : ''}{' '}
            </span>
          </div>
          <Button
            disabled={disabled}
            className={classNames(
              'smaller pill snug outline',
              activeGroup.paintColor ? 'delete' : null
            )}
            onClick={() => {
              if (activeGroup.paintColor) {
                setGroupAndParts({
                  ...activeGroup,
                  paintColor: undefined,
                  paintThicknessUM: undefined,
                });
              }
              if (!activeGroup.paintColor) toggleModal();
            }}
          >
            <Icon icon={activeGroup.paintColor ? <Trash /> : <Edit />} />
          </Button>
        </div>
      </div>
      {isOpen && (
        <Modal
          title={t('cutlist-form.painting')}
          isOpen={isOpen}
          handleClose={() => toggleModal()}
        >
          <div>
            <PaintingModal toggleModal={toggleModal} />
          </div>
        </Modal>
      )}
    </>
  );
};

type PaintingModalProps = {
  toggleModal: () => void;
};

const PaintingModal = ({ toggleModal }: PaintingModalProps) => {
  const { t } = useTranslation();
  const activeGroup = useActiveGroup() as MaterialGroup;
  const [paintColor, setPaintColor] = React.useState(activeGroup.paintColor);
  const [paintThicknessUM, setPaintThicknessUM] = React.useState(
    activeGroup.paintThicknessUM
      ? activeGroup.paintThicknessUM
      : DEFAULT_THICKNESS
  );

  const selectOptions = THICKNESSES.map((thickness) => ({
    value: thickness,
    label: thickness + ' µm',
    selected: thickness === paintThicknessUM.toString(),
  }));

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setGroupAndParts({
      ...activeGroup,
      paintColor,
      paintThicknessUM: paintColor ? Number(paintThicknessUM) : undefined,
    });
    toggleModal();
  };

  return (
    <div>
      <p>{t('cutlist-form.paint.modal.body')}</p>

      <form onSubmit={onSubmit} className="stack">
        <strong>{t('cutlist-form.paint.modal.colorLabel')}</strong>
        <div className={styles.colorsContainer}>
          {RAL_COLORS_SUBSET.map((color) => (
            <label
              key={color}
              className={classNames('flexAlign', styles.paintingItem)}
            >
              <input
                name={'paint-color'}
                type="radio"
                value={color}
                style={{ appearance: 'auto', margin: 0 }}
                onChange={(e) => setPaintColor(e.currentTarget.value)}
                checked={color === paintColor}
                className="hidden"
              />
              <div style={{ color: RAL_TO_HEX[color] }}>
                <Icon icon={<PaintingBullet />} size={4} />
              </div>
              {color}
            </label>
          ))}
        </div>

        <strong>{t('cutlist-form.paint.modal.thicknessLabel')}</strong>

        <Select
          id="paint-thickness"
          options={selectOptions}
          defaultValue={paintThicknessUM}
          onChange={(e) => setPaintThicknessUM(e.currentTarget.value)}
        />

        <Button type="submit">{t('common.cta.save')}</Button>
      </form>
    </div>
  );
};
