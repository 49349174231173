// @ts-nocheck - mock is not typed properly
import { CutlistOrder } from '@cutr/constants/cutlist';
import React from 'react';
import { PropsWithChildren } from 'react';

import { useCutlistState } from '@/api/store';
import { hydrateStoreFn, resetStoreFn } from '@/hooks';

export const CutlistMockProvider = ({ children }: PropsWithChildren) => {
  const hydrateStore = hydrateStoreFn();
  const resetStore = resetStoreFn();
  const cutlistStore = useCutlistState();

  React.useEffect(() => {
    if (cutlistStore.orderId) return;
    resetStore();
    hydrateStore(mockCutlist);
  }, []);

  return <>{children}</>;
};

export const mockCutlist: CutlistOrder = {
  totalAmountExclVAT: 39566,
  totalAmountInclVAT: 47875,
  shortId: 'C-3KU92DJ',
  thirdPartyId: null,
  title: 'TV-kast Zwart Essen toltaal',
  billingEmail: 'test@cutr.ai',
  customerReference: '',
  notes: '',
  vatRate: 21,
  currency: 'EUR',
  sourceCurrency: 'EUR',
  totalAmountExVat: null,
  requestType: 'place_order',
  manualQuote: true,
  quoteInfo: ['edgebandNotWideEnough'],
  deliverLeftoverMaterials: true,
  id: '01J2BH06K5W097DX0JM8APH8T4',
  createdAt: '2024-07-09T10:35:33.350Z',
  updatedAt: '2024-07-09T17:34:42.192Z',
  archivedAt: null,
  userLeadId: '01J2BH01NXW80RGRYC18AK4Y19',
  userId: null,
  customerOrganisationId: null,
  userLead: {
    email: 'jasper@cutr.ai',
    locale: 'en',
    id: '01J2BH01NXW80RGRYC18AK4Y19',
    createdAt: '2024-07-09T10:35:28.319Z',
    updatedAt: '2024-07-09T10:35:28.319Z',
    archivedAt: null,
    source: {
      id: '01J2BEP9ME37AFXPBYDG9B2XBC',
      name: 'Stiho',
      ticker: 'STIHO',
      emailTo: 'log@cutr.ai',
      locale: 'en',
    },
  },
  userLeadDetail: {
    name: 'Woody Cutie',
    companyName: 'Cutr B.V.',
    phone: '',
    clientNumber: '319299',
    id: '01J2BH06K6Z6CVKY9F70RTHY69',
    createdAt: '2024-07-09T10:35:33.353Z',
    updatedAt: '2024-07-09T17:34:12.849Z',
    archivedAt: '',
    userLeadId: '01J2BH01NXW80RGRYC18AK4Y19',
    cutlistId: '01J2BH06K5W097DX0JM8APH8T4',
  },
  materialGroups: [
    {
      trimThicknessMM: {
        length1: 10,
        length2: 10,
        width1: 10,
        width2: 10,
      },
      index: 0,
      name: 'Thermopal Spaanplaat R34032 NW',
      materialSandwichType: 'single-core',
      sheetEdgeTrimType: 'all-sides',
      length1TrimThicknessMM: 10,
      length2TrimThicknessMM: 10,
      width1TrimThicknessMM: 10,
      width2TrimThicknessMM: 10,
      continuousGrain: '',
      additionalProcessing: '',
      groupId: 'oNEbNT',
      paintThicknessUM: 0,
      edgeProfileType: 'none',
      id: '01J2C8ZNXH1WTMKJB558JWVP52',
      createdAt: '2024-07-09T17:34:42.106Z',
      updatedAt: '2024-07-09T17:34:42.106Z',
      archivedAt: null,
      cutlistId: '01J2BH06K5W097DX0JM8APH8T4',
      core1MaterialId: '01J2BEPNZ3DX2J3AXPVYCT1JSD',
      core2MaterialId: null,
      topHplId: null,
      bottomHplId: null,
      edgebandId: '01J2BEPNZDADXF4CDW87HZPZFF',
      parts: [
        {
          edgeProfile: {
            length1: 'none',
            length2: 'none',
            width1: 'none',
            width2: 'none',
          },
          index: 0,
          quantity: 2,
          label: '9 PLINT',
          createLabel: false,
          grainDirection: 'along',
          materialSandwichType: 'single-core',
          lengthMM: 1744,
          widthMM: 90,
          thicknessUM: 18000,
          length1EdgeProfile: 'none',
          length2EdgeProfile: 'none',
          width1EdgeProfile: 'none',
          width2EdgeProfile: 'none',
          partType: 'panel',
          groupId: 'oNEbNT',
          id: '01J2C8ZNXR978K49NFYTTZXG09',
          createdAt: '2024-07-09T17:34:42.152Z',
          updatedAt: '2024-07-09T17:34:42.152Z',
          archivedAt: null,
          cutlistId: '01J2BH06K5W097DX0JM8APH8T4',
          cutlistMaterialGroupId: '01J2C8ZNXH1WTMKJB558JWVP52',
          core1MaterialId: '01J2BEPNZ3DX2J3AXPVYCT1JSD',
          core2MaterialId: null,
          topHplId: null,
          bottomHplId: null,
          length1EdgebandId: '01J2BEPNZDADXF4CDW87HZPZFF',
          width1EdgebandId: '01J2BEPNZDADXF4CDW87HZPZFF',
          length2EdgebandId: '01J2BEPNZDADXF4CDW87HZPZFF',
          width2EdgebandId: '01J2BEPNZDADXF4CDW87HZPZFF',
          core1Material: {
            articleCode: '354656',
            thicknessMM: 18,
            unitAmount: 9890,
            m2Amount: 1682,
            nameWithCOCClaim: 'Thermopal Spaanplaat R34032 NW (PEFC 100%)',
            supplierCode: 'BNB',
            name: 'Thermopal Spaanplaat R34032 NW',
            originalName: 'Thermopal Spaanplaat R34032 NW',
            brand: 'Thermopal',
            series: 'Repro',
            colorCode: 'R34032',
            structureName: 'Wood',
            structureCode: 'NW',
            shortDescription: 'K1 Spaan Thermopal R34032 NW (4) 2800x2100x18mm',
            longDescription: null,
            imageUrl: null,
            sourceName: null,
            sourceLink: null,
            supplierERPCode: null,
            type: 'melamine-chipboard',
            lengthMM: 2800,
            widthMM: 2100,
            thicknessUM: 18000,
            priceSyncedAt: '2024-07-05T12:06:24.826Z',
            priceAmount: 9890,
            priceUnit: 'piece',
            weightMG: 74088000,
            weightUnit: 'piece',
            sawingAllowed: null,
            paintingAllowed: null,
            facetEdgeProfileAllowed: null,
            roundEdgeProfileAllowed: null,
            angledEdgeProfileAllowed: null,
            angledEdgeProfileType: null,
            allowedEdgeProfiles: [],
            hasGrainDirection: true,
            hasTextureDirection: false,
            hasVisibleSide: null,
            gluingAndPressingAllowed: false,
            length1TrimThicknessMM: null,
            length2TrimThicknessMM: null,
            width1TrimThicknessMM: null,
            width2TrimThicknessMM: null,
            fscClaim: null,
            pefcClaim: 'pefc_100',
            id: '01J2BEPNZ3DX2J3AXPVYCT1JSD',
            createdAt: '2024-07-09T09:55:24.217Z',
            updatedAt: '2024-07-09T09:55:24.217Z',
            archivedAt: null,
            variationGroupId: null,
          },
          core2Material: null,
          topHpl: null,
          bottomHpl: null,
          width1Edgeband: {
            articleCode: '372589',
            thicknessMM: 0.8,
            unitAmount: 2800,
            m2Amount: 2435,
            nameWithCOCClaim: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            supplierCode: 'BNB',
            name: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            originalName: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            brand: 'Rehau',
            series: null,
            colorCode: 'R34032',
            structureName: 'Wood',
            structureCode: 'NW',
            shortDescription:
              'Kantenband ABS Rehau R34032 NW 23x0,8mm 50 meter rol',
            longDescription: null,
            imageUrl: null,
            sourceName: null,
            sourceLink: null,
            supplierERPCode: null,
            type: 'edgeband',
            lengthMM: 50000,
            widthMM: 23,
            thicknessUM: 800,
            priceSyncedAt: '2024-07-05T12:06:24.826Z',
            priceAmount: 2800,
            priceUnit: 'piece',
            weightMG: 1000000,
            weightUnit: 'piece',
            sawingAllowed: null,
            paintingAllowed: null,
            facetEdgeProfileAllowed: null,
            roundEdgeProfileAllowed: null,
            angledEdgeProfileAllowed: null,
            angledEdgeProfileType: null,
            allowedEdgeProfiles: [],
            hasGrainDirection: false,
            hasTextureDirection: false,
            hasVisibleSide: null,
            gluingAndPressingAllowed: false,
            length1TrimThicknessMM: null,
            length2TrimThicknessMM: null,
            width1TrimThicknessMM: null,
            width2TrimThicknessMM: null,
            fscClaim: null,
            pefcClaim: null,
            id: '01J2BEPNZDADXF4CDW87HZPZFF',
            createdAt: '2024-07-09T09:55:24.217Z',
            updatedAt: '2024-07-09T09:55:24.217Z',
            archivedAt: null,
            variationGroupId: null,
          },
          length1Edgeband: {
            articleCode: '372589',
            thicknessMM: 0.8,
            unitAmount: 2800,
            m2Amount: 2435,
            nameWithCOCClaim: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            supplierCode: 'BNB',
            name: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            originalName: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            brand: 'Rehau',
            series: null,
            colorCode: 'R34032',
            structureName: 'Wood',
            structureCode: 'NW',
            shortDescription:
              'Kantenband ABS Rehau R34032 NW 23x0,8mm 50 meter rol',
            longDescription: null,
            imageUrl: null,
            sourceName: null,
            sourceLink: null,
            supplierERPCode: null,
            type: 'edgeband',
            lengthMM: 50000,
            widthMM: 23,
            thicknessUM: 800,
            priceSyncedAt: '2024-07-05T12:06:24.826Z',
            priceAmount: 2800,
            priceUnit: 'piece',
            weightMG: 1000000,
            weightUnit: 'piece',
            sawingAllowed: null,
            paintingAllowed: null,
            facetEdgeProfileAllowed: null,
            roundEdgeProfileAllowed: null,
            angledEdgeProfileAllowed: null,
            angledEdgeProfileType: null,
            allowedEdgeProfiles: [],
            hasGrainDirection: false,
            hasTextureDirection: false,
            hasVisibleSide: null,
            gluingAndPressingAllowed: false,
            length1TrimThicknessMM: null,
            length2TrimThicknessMM: null,
            width1TrimThicknessMM: null,
            width2TrimThicknessMM: null,
            fscClaim: null,
            pefcClaim: null,
            id: '01J2BEPNZDADXF4CDW87HZPZFF',
            createdAt: '2024-07-09T09:55:24.217Z',
            updatedAt: '2024-07-09T09:55:24.217Z',
            archivedAt: null,
            variationGroupId: null,
          },
          width2Edgeband: {
            articleCode: '372589',
            thicknessMM: 0.8,
            unitAmount: 2800,
            m2Amount: 2435,
            nameWithCOCClaim: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            supplierCode: 'BNB',
            name: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            originalName: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            brand: 'Rehau',
            series: null,
            colorCode: 'R34032',
            structureName: 'Wood',
            structureCode: 'NW',
            shortDescription:
              'Kantenband ABS Rehau R34032 NW 23x0,8mm 50 meter rol',
            longDescription: null,
            imageUrl: null,
            sourceName: null,
            sourceLink: null,
            supplierERPCode: null,
            type: 'edgeband',
            lengthMM: 50000,
            widthMM: 23,
            thicknessUM: 800,
            priceSyncedAt: '2024-07-05T12:06:24.826Z',
            priceAmount: 2800,
            priceUnit: 'piece',
            weightMG: 1000000,
            weightUnit: 'piece',
            sawingAllowed: null,
            paintingAllowed: null,
            facetEdgeProfileAllowed: null,
            roundEdgeProfileAllowed: null,
            angledEdgeProfileAllowed: null,
            angledEdgeProfileType: null,
            allowedEdgeProfiles: [],
            hasGrainDirection: false,
            hasTextureDirection: false,
            hasVisibleSide: null,
            gluingAndPressingAllowed: false,
            length1TrimThicknessMM: null,
            length2TrimThicknessMM: null,
            width1TrimThicknessMM: null,
            width2TrimThicknessMM: null,
            fscClaim: null,
            pefcClaim: null,
            id: '01J2BEPNZDADXF4CDW87HZPZFF',
            createdAt: '2024-07-09T09:55:24.217Z',
            updatedAt: '2024-07-09T09:55:24.217Z',
            archivedAt: null,
            variationGroupId: null,
          },
          length2Edgeband: {
            articleCode: '372589',
            thicknessMM: 0.8,
            unitAmount: 2800,
            m2Amount: 2435,
            nameWithCOCClaim: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            supplierCode: 'BNB',
            name: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            originalName: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            brand: 'Rehau',
            series: null,
            colorCode: 'R34032',
            structureName: 'Wood',
            structureCode: 'NW',
            shortDescription:
              'Kantenband ABS Rehau R34032 NW 23x0,8mm 50 meter rol',
            longDescription: null,
            imageUrl: null,
            sourceName: null,
            sourceLink: null,
            supplierERPCode: null,
            type: 'edgeband',
            lengthMM: 50000,
            widthMM: 23,
            thicknessUM: 800,
            priceSyncedAt: '2024-07-05T12:06:24.826Z',
            priceAmount: 2800,
            priceUnit: 'piece',
            weightMG: 1000000,
            weightUnit: 'piece',
            sawingAllowed: null,
            paintingAllowed: null,
            facetEdgeProfileAllowed: null,
            roundEdgeProfileAllowed: null,
            angledEdgeProfileAllowed: null,
            angledEdgeProfileType: null,
            allowedEdgeProfiles: [],
            hasGrainDirection: false,
            hasTextureDirection: false,
            hasVisibleSide: null,
            gluingAndPressingAllowed: false,
            length1TrimThicknessMM: null,
            length2TrimThicknessMM: null,
            width1TrimThicknessMM: null,
            width2TrimThicknessMM: null,
            fscClaim: null,
            pefcClaim: null,
            id: '01J2BEPNZDADXF4CDW87HZPZFF',
            createdAt: '2024-07-09T09:55:24.217Z',
            updatedAt: '2024-07-09T09:55:24.217Z',
            archivedAt: null,
            variationGroupId: null,
          },
        },
      ],
      core1Material: {
        articleCode: '354656',
        thicknessMM: 18,
        unitAmount: 9890,
        m2Amount: 1682,
        nameWithCOCClaim: 'Thermopal Spaanplaat R34032 NW (PEFC 100%)',
        supplierCode: 'BNB',
        name: 'Thermopal Spaanplaat R34032 NW',
        originalName: 'Thermopal Spaanplaat R34032 NW',
        brand: 'Thermopal',
        series: 'Repro',
        colorCode: 'R34032',
        structureName: 'Wood',
        structureCode: 'NW',
        shortDescription: 'K1 Spaan Thermopal R34032 NW (4) 2800x2100x18mm',
        longDescription: null,
        imageUrl: null,
        sourceName: null,
        sourceLink: null,
        supplierERPCode: null,
        type: 'melamine-chipboard',
        lengthMM: 2800,
        widthMM: 2100,
        thicknessUM: 18000,
        priceSyncedAt: '2024-07-05T12:06:24.826Z',
        priceAmount: 9890,
        priceUnit: 'piece',
        weightMG: 74088000,
        weightUnit: 'piece',
        sawingAllowed: null,
        paintingAllowed: null,
        facetEdgeProfileAllowed: null,
        roundEdgeProfileAllowed: null,
        angledEdgeProfileAllowed: null,
        angledEdgeProfileType: null,
        allowedEdgeProfiles: [],
        hasGrainDirection: true,
        hasTextureDirection: false,
        hasVisibleSide: null,
        gluingAndPressingAllowed: false,
        length1TrimThicknessMM: null,
        length2TrimThicknessMM: null,
        width1TrimThicknessMM: null,
        width2TrimThicknessMM: null,
        fscClaim: null,
        pefcClaim: 'pefc_100',
        id: '01J2BEPNZ3DX2J3AXPVYCT1JSD',
        createdAt: '2024-07-09T09:55:24.217Z',
        updatedAt: '2024-07-09T09:55:24.217Z',
        archivedAt: null,
        variationGroupId: null,
      },
      core2Material: null,
      bottomHpl: null,
      topHpl: null,
      edgeband: {
        articleCode: '372589',
        thicknessMM: 0.8,
        unitAmount: 2800,
        m2Amount: 2435,
        nameWithCOCClaim: 'ABS KB bestemd voor Pfleiderer R34032 NW',
        supplierCode: 'BNB',
        name: 'ABS KB bestemd voor Pfleiderer R34032 NW',
        originalName: 'ABS KB bestemd voor Pfleiderer R34032 NW',
        brand: 'Rehau',
        series: null,
        colorCode: 'R34032',
        structureName: 'Wood',
        structureCode: 'NW',
        shortDescription:
          'Kantenband ABS Rehau R34032 NW 23x0,8mm 50 meter rol',
        longDescription: null,
        imageUrl: null,
        sourceName: null,
        sourceLink: null,
        supplierERPCode: null,
        type: 'edgeband',
        lengthMM: 50000,
        widthMM: 23,
        thicknessUM: 800,
        priceSyncedAt: '2024-07-05T12:06:24.826Z',
        priceAmount: 2800,
        priceUnit: 'piece',
        weightMG: 1000000,
        weightUnit: 'piece',
        sawingAllowed: null,
        paintingAllowed: null,
        facetEdgeProfileAllowed: null,
        roundEdgeProfileAllowed: null,
        angledEdgeProfileAllowed: null,
        angledEdgeProfileType: null,
        allowedEdgeProfiles: [],
        hasGrainDirection: false,
        hasTextureDirection: false,
        hasVisibleSide: null,
        gluingAndPressingAllowed: false,
        length1TrimThicknessMM: null,
        length2TrimThicknessMM: null,
        width1TrimThicknessMM: null,
        width2TrimThicknessMM: null,
        fscClaim: null,
        pefcClaim: null,
        id: '01J2BEPNZDADXF4CDW87HZPZFF',
        createdAt: '2024-07-09T09:55:24.217Z',
        updatedAt: '2024-07-09T09:55:24.217Z',
        archivedAt: null,
        variationGroupId: null,
      },
    },
    {
      trimThicknessMM: {
        length1: 0,
        length2: 0,
        width1: 0,
        width2: 0,
      },
      index: 1,
      name: 'Populieren multiplex 250x122cm',
      materialSandwichType: 'single-core',
      edgeProfileType: 'none',
      sheetEdgeTrimType: 'no-side',
      length1TrimThicknessMM: 0,
      length2TrimThicknessMM: 0,
      width1TrimThicknessMM: 0,
      width2TrimThicknessMM: 0,
      continuousGrain: '',
      additionalProcessing: '',
      groupId: 'EZ7k-J',
      paintColor: null,
      paintThicknessUM: 0,
      id: '01J2C8ZNXT3VDBZY2HVD4FFZPW',
      createdAt: '2024-07-09T17:34:42.111Z',
      updatedAt: '2024-07-09T17:34:42.111Z',
      archivedAt: null,
      cutlistId: '01J2BH06K5W097DX0JM8APH8T4',
      core1MaterialId: '01J2BEPNXSGHG4CBPQWE2PP0CP',
      core2MaterialId: null,
      topHplId: null,
      bottomHplId: null,
      edgebandId: null,
      parts: [
        {
          edgebanding: {
            length1: null,
            length2: null,
            width1: null,
            width2: null,
          },
          edgeProfile: {
            length1: 'none',
            length2: 'none',
            width1: 'none',
            width2: 'none',
          },
          index: 0,
          quantity: 1,
          label: '-/-',
          createLabel: false,
          grainDirection: 'none',
          materialSandwichType: 'single-core',
          lengthMM: 2480,
          widthMM: 1200,
          thicknessUM: 18000,
          length1EdgeProfile: 'none',
          length2EdgeProfile: 'none',
          width1EdgeProfile: 'none',
          width2EdgeProfile: 'none',
          partType: 'sheet',
          groupId: 'EZ7k-J',
          id: '01J2C8ZNXZN5YEAZ19W5AV2WVH',
          createdAt: '2024-07-09T17:34:42.155Z',
          updatedAt: '2024-07-09T17:34:42.155Z',
          archivedAt: null,
          cutlistId: '01J2BH06K5W097DX0JM8APH8T4',
          cutlistMaterialGroupId: '01J2C8ZNXT3VDBZY2HVD4FFZPW',
          core1MaterialId: '01J2BEPNXSGHG4CBPQWE2PP0CP',
          core2MaterialId: null,
          topHplId: null,
          bottomHplId: null,
          length1EdgebandId: null,
          width1EdgebandId: null,
          length2EdgebandId: null,
          width2EdgebandId: null,
          core1Material: {
            articleCode: '117461',
            thicknessMM: 18,
            unitAmount: 6552,
            m2Amount: 2148,
            nameWithCOCClaim: 'Populieren multiplex 250x122cm (PEFC 70%)',
            supplierCode: 'BNB',
            name: 'Populieren multiplex 250x122cm',
            originalName: 'Populieren multiplex 250x122cm PEFC',
            brand: null,
            series: null,
            colorCode: null,
            structureName: null,
            structureCode: null,
            shortDescription: 'K2 Populieren multiplex b/bb mr 250x122cm 18mm',
            longDescription: null,
            imageUrl: null,
            sourceName: null,
            sourceLink: null,
            supplierERPCode: null,
            type: 'plywood',
            lengthMM: 2500,
            widthMM: 1220,
            thicknessUM: 18000,
            priceSyncedAt: '2024-07-05T12:06:24.826Z',
            priceAmount: 6552,
            priceUnit: 'piece',
            weightMG: 24705000,
            weightUnit: 'piece',
            sawingAllowed: null,
            paintingAllowed: null,
            facetEdgeProfileAllowed: null,
            roundEdgeProfileAllowed: null,
            angledEdgeProfileAllowed: null,
            angledEdgeProfileType: null,
            allowedEdgeProfiles: [],
            hasGrainDirection: true,
            hasTextureDirection: false,
            hasVisibleSide: null,
            gluingAndPressingAllowed: true,
            length1TrimThicknessMM: null,
            length2TrimThicknessMM: null,
            width1TrimThicknessMM: null,
            width2TrimThicknessMM: null,
            fscClaim: null,
            pefcClaim: 'pefc_70',
            id: '01J2BEPNXSGHG4CBPQWE2PP0CP',
            createdAt: '2024-07-09T09:55:24.217Z',
            updatedAt: '2024-07-09T09:55:24.217Z',
            archivedAt: null,
            variationGroupId: null,
          },
          core2Material: null,
          topHpl: null,
          bottomHpl: null,
          width1Edgeband: null,
          length1Edgeband: null,
          width2Edgeband: null,
          length2Edgeband: null,
        },
      ],
      core1Material: {
        articleCode: '117461',
        thicknessMM: 18,
        unitAmount: 6552,
        m2Amount: 2148,
        nameWithCOCClaim: 'Populieren multiplex 250x122cm (PEFC 70%)',
        supplierCode: 'BNB',
        name: 'Populieren multiplex 250x122cm',
        originalName: 'Populieren multiplex 250x122cm PEFC',
        brand: null,
        series: null,
        colorCode: null,
        structureName: null,
        structureCode: null,
        shortDescription: 'K2 Populieren multiplex b/bb mr 250x122cm 18mm',
        longDescription: null,
        imageUrl: null,
        sourceName: null,
        sourceLink: null,
        supplierERPCode: null,
        type: 'plywood',
        lengthMM: 2500,
        widthMM: 1220,
        thicknessUM: 18000,
        priceSyncedAt: '2024-07-05T12:06:24.826Z',
        priceAmount: 6552,
        priceUnit: 'piece',
        weightMG: 24705000,
        weightUnit: 'piece',
        sawingAllowed: null,
        paintingAllowed: null,
        facetEdgeProfileAllowed: null,
        roundEdgeProfileAllowed: null,
        angledEdgeProfileAllowed: null,
        angledEdgeProfileType: null,
        allowedEdgeProfiles: [],
        hasGrainDirection: true,
        hasTextureDirection: false,
        hasVisibleSide: null,
        gluingAndPressingAllowed: true,
        length1TrimThicknessMM: null,
        length2TrimThicknessMM: null,
        width1TrimThicknessMM: null,
        width2TrimThicknessMM: null,
        fscClaim: null,
        pefcClaim: 'pefc_70',
        id: '01J2BEPNXSGHG4CBPQWE2PP0CP',
        createdAt: '2024-07-09T09:55:24.217Z',
        updatedAt: '2024-07-09T09:55:24.217Z',
        archivedAt: null,
        variationGroupId: null,
      },
      core2Material: null,
      bottomHpl: null,
      topHpl: null,
      edgeband: null,
    },
    {
      trimThicknessMM: {
        length1: 10,
        length2: 10,
        width1: 10,
        width2: 10,
      },
      index: 2,
      name: 'Thermopal Spaanplaat R34032 NW',
      materialSandwichType: 'double-core',
      edgeProfileType: 'none',
      sheetEdgeTrimType: 'all-sides',
      length1TrimThicknessMM: 10,
      length2TrimThicknessMM: 10,
      width1TrimThicknessMM: 10,
      width2TrimThicknessMM: 10,
      continuousGrain: '',
      additionalProcessing:
        'LET OP!\nDe zaagdelen 7 en 8 moeten aan de korte niet gefineerde kant B2 45° gezaagd worden!\nAls dit niet mogelijk is graag de 4 delen 20mm langer leveren.',
      groupId: 'ASdwy1',
      paintColor: null,
      paintThicknessUM: 0,
      id: '01J2C8ZNXZN5YEAZ19W5AV2WVJ',
      createdAt: '2024-07-09T17:34:42.116Z',
      updatedAt: '2024-07-09T17:34:42.116Z',
      archivedAt: null,
      cutlistId: '01J2BH06K5W097DX0JM8APH8T4',
      core1MaterialId: '01J2BEPNZ3DX2J3AXPVYCT1JSD',
      core2MaterialId: '01J2BEPNZ3DX2J3AXPVYCT1JSD',
      topHplId: null,
      bottomHplId: null,
      edgebandId: '01J2BEPNZDADXF4CDW87HZPZFF',
      parts: [
        {
          edgebanding: {
            length1: {
              articleCode: '372589',
              thicknessMM: 0.8,
              unitAmount: 2800,
              m2Amount: 2435,
              nameWithCOCClaim: 'ABS KB bestemd voor Pfleiderer R34032 NW',
              supplierCode: 'BNB',
              name: 'ABS KB bestemd voor Pfleiderer R34032 NW',
              originalName: 'ABS KB bestemd voor Pfleiderer R34032 NW',
              brand: 'Rehau',
              series: null,
              colorCode: 'R34032',
              structureName: 'Wood',
              structureCode: 'NW',
              shortDescription:
                'Kantenband ABS Rehau R34032 NW 23x0,8mm 50 meter rol',
              longDescription: null,
              imageUrl: null,
              sourceName: null,
              sourceLink: null,
              supplierERPCode: null,
              type: 'edgeband',
              lengthMM: 50000,
              widthMM: 23,
              thicknessUM: 800,
              priceSyncedAt: '2024-07-05T12:06:24.826Z',
              priceAmount: 2800,
              priceUnit: 'piece',
              weightMG: 1000000,
              weightUnit: 'piece',
              sawingAllowed: null,
              paintingAllowed: null,
              facetEdgeProfileAllowed: null,
              roundEdgeProfileAllowed: null,
              angledEdgeProfileAllowed: null,
              angledEdgeProfileType: null,
              allowedEdgeProfiles: [],
              hasGrainDirection: false,
              hasTextureDirection: false,
              hasVisibleSide: null,
              gluingAndPressingAllowed: false,
              length1TrimThicknessMM: null,
              length2TrimThicknessMM: null,
              width1TrimThicknessMM: null,
              width2TrimThicknessMM: null,
              fscClaim: null,
              pefcClaim: null,
              id: '01J2BEPNZDADXF4CDW87HZPZFF',
              createdAt: '2024-07-09T09:55:24.217Z',
              updatedAt: '2024-07-09T09:55:24.217Z',
              archivedAt: null,
              variationGroupId: null,
            },
            length2: {
              articleCode: '372589',
              thicknessMM: 0.8,
              unitAmount: 2800,
              m2Amount: 2435,
              nameWithCOCClaim: 'ABS KB bestemd voor Pfleiderer R34032 NW',
              supplierCode: 'BNB',
              name: 'ABS KB bestemd voor Pfleiderer R34032 NW',
              originalName: 'ABS KB bestemd voor Pfleiderer R34032 NW',
              brand: 'Rehau',
              series: null,
              colorCode: 'R34032',
              structureName: 'Wood',
              structureCode: 'NW',
              shortDescription:
                'Kantenband ABS Rehau R34032 NW 23x0,8mm 50 meter rol',
              longDescription: null,
              imageUrl: null,
              sourceName: null,
              sourceLink: null,
              supplierERPCode: null,
              type: 'edgeband',
              lengthMM: 50000,
              widthMM: 23,
              thicknessUM: 800,
              priceSyncedAt: '2024-07-05T12:06:24.826Z',
              priceAmount: 2800,
              priceUnit: 'piece',
              weightMG: 1000000,
              weightUnit: 'piece',
              sawingAllowed: null,
              paintingAllowed: null,
              facetEdgeProfileAllowed: null,
              roundEdgeProfileAllowed: null,
              angledEdgeProfileAllowed: null,
              angledEdgeProfileType: null,
              allowedEdgeProfiles: [],
              hasGrainDirection: false,
              hasTextureDirection: false,
              hasVisibleSide: null,
              gluingAndPressingAllowed: false,
              length1TrimThicknessMM: null,
              length2TrimThicknessMM: null,
              width1TrimThicknessMM: null,
              width2TrimThicknessMM: null,
              fscClaim: null,
              pefcClaim: null,
              id: '01J2BEPNZDADXF4CDW87HZPZFF',
              createdAt: '2024-07-09T09:55:24.217Z',
              updatedAt: '2024-07-09T09:55:24.217Z',
              archivedAt: null,
              variationGroupId: null,
            },
            width1: {
              articleCode: '372589',
              thicknessMM: 0.8,
              unitAmount: 2800,
              m2Amount: 2435,
              nameWithCOCClaim: 'ABS KB bestemd voor Pfleiderer R34032 NW',
              supplierCode: 'BNB',
              name: 'ABS KB bestemd voor Pfleiderer R34032 NW',
              originalName: 'ABS KB bestemd voor Pfleiderer R34032 NW',
              brand: 'Rehau',
              series: null,
              colorCode: 'R34032',
              structureName: 'Wood',
              structureCode: 'NW',
              shortDescription:
                'Kantenband ABS Rehau R34032 NW 23x0,8mm 50 meter rol',
              longDescription: null,
              imageUrl: null,
              sourceName: null,
              sourceLink: null,
              supplierERPCode: null,
              type: 'edgeband',
              lengthMM: 50000,
              widthMM: 23,
              thicknessUM: 800,
              priceSyncedAt: '2024-07-05T12:06:24.826Z',
              priceAmount: 2800,
              priceUnit: 'piece',
              weightMG: 1000000,
              weightUnit: 'piece',
              sawingAllowed: null,
              paintingAllowed: null,
              facetEdgeProfileAllowed: null,
              roundEdgeProfileAllowed: null,
              angledEdgeProfileAllowed: null,
              angledEdgeProfileType: null,
              allowedEdgeProfiles: [],
              hasGrainDirection: false,
              hasTextureDirection: false,
              hasVisibleSide: null,
              gluingAndPressingAllowed: false,
              length1TrimThicknessMM: null,
              length2TrimThicknessMM: null,
              width1TrimThicknessMM: null,
              width2TrimThicknessMM: null,
              fscClaim: null,
              pefcClaim: null,
              id: '01J2BEPNZDADXF4CDW87HZPZFF',
              createdAt: '2024-07-09T09:55:24.217Z',
              updatedAt: '2024-07-09T09:55:24.217Z',
              archivedAt: null,
              variationGroupId: null,
            },
            width2: {
              articleCode: '372589',
              thicknessMM: 0.8,
              unitAmount: 2800,
              m2Amount: 2435,
              nameWithCOCClaim: 'ABS KB bestemd voor Pfleiderer R34032 NW',
              supplierCode: 'BNB',
              name: 'ABS KB bestemd voor Pfleiderer R34032 NW',
              originalName: 'ABS KB bestemd voor Pfleiderer R34032 NW',
              brand: 'Rehau',
              series: null,
              colorCode: 'R34032',
              structureName: 'Wood',
              structureCode: 'NW',
              shortDescription:
                'Kantenband ABS Rehau R34032 NW 23x0,8mm 50 meter rol',
              longDescription: null,
              imageUrl: null,
              sourceName: null,
              sourceLink: null,
              supplierERPCode: null,
              type: 'edgeband',
              lengthMM: 50000,
              widthMM: 23,
              thicknessUM: 800,
              priceSyncedAt: '2024-07-05T12:06:24.826Z',
              priceAmount: 2800,
              priceUnit: 'piece',
              weightMG: 1000000,
              weightUnit: 'piece',
              sawingAllowed: null,
              paintingAllowed: null,
              facetEdgeProfileAllowed: null,
              roundEdgeProfileAllowed: null,
              angledEdgeProfileAllowed: null,
              angledEdgeProfileType: null,
              allowedEdgeProfiles: [],
              hasGrainDirection: false,
              hasTextureDirection: false,
              hasVisibleSide: null,
              gluingAndPressingAllowed: false,
              length1TrimThicknessMM: null,
              length2TrimThicknessMM: null,
              width1TrimThicknessMM: null,
              width2TrimThicknessMM: null,
              fscClaim: null,
              pefcClaim: null,
              id: '01J2BEPNZDADXF4CDW87HZPZFF',
              createdAt: '2024-07-09T09:55:24.217Z',
              updatedAt: '2024-07-09T09:55:24.217Z',
              archivedAt: null,
              variationGroupId: null,
            },
          },
          edgeProfile: {
            length1: 'none',
            length2: 'none',
            width1: 'none',
            width2: 'none',
          },
          index: 0,
          quantity: 4,
          label: '1 ZW',
          createLabel: false,
          grainDirection: 'along',
          materialSandwichType: 'double-core',
          lengthMM: 2573,
          widthMM: 400,
          thicknessUM: 35000,
          length1EdgeProfile: 'none',
          length2EdgeProfile: 'none',
          width1EdgeProfile: 'none',
          width2EdgeProfile: 'none',
          partType: 'panel',
          groupId: 'ASdwy1',
          id: '01J2C8ZNY3M1PQVF7PPH4RMRCM',
          createdAt: '2024-07-09T17:34:42.159Z',
          updatedAt: '2024-07-09T17:34:42.159Z',
          archivedAt: null,
          cutlistId: '01J2BH06K5W097DX0JM8APH8T4',
          cutlistMaterialGroupId: '01J2C8ZNXZN5YEAZ19W5AV2WVJ',
          core1MaterialId: '01J2BEPNZ3DX2J3AXPVYCT1JSD',
          core2MaterialId: '01J2BEPNZ3DX2J3AXPVYCT1JSD',
          topHplId: null,
          bottomHplId: null,
          length1EdgebandId: '01J2BEPNZDADXF4CDW87HZPZFF',
          width1EdgebandId: '01J2BEPNZDADXF4CDW87HZPZFF',
          length2EdgebandId: '01J2BEPNZDADXF4CDW87HZPZFF',
          width2EdgebandId: '01J2BEPNZDADXF4CDW87HZPZFF',
          core1Material: {
            articleCode: '354656',
            thicknessMM: 18,
            unitAmount: 9890,
            m2Amount: 1682,
            nameWithCOCClaim: 'Thermopal Spaanplaat R34032 NW (PEFC 100%)',
            supplierCode: 'BNB',
            name: 'Thermopal Spaanplaat R34032 NW',
            originalName: 'Thermopal Spaanplaat R34032 NW',
            brand: 'Thermopal',
            series: 'Repro',
            colorCode: 'R34032',
            structureName: 'Wood',
            structureCode: 'NW',
            shortDescription: 'K1 Spaan Thermopal R34032 NW (4) 2800x2100x18mm',
            longDescription: null,
            imageUrl: null,
            sourceName: null,
            sourceLink: null,
            supplierERPCode: null,
            type: 'melamine-chipboard',
            lengthMM: 2800,
            widthMM: 2100,
            thicknessUM: 18000,
            priceSyncedAt: '2024-07-05T12:06:24.826Z',
            priceAmount: 9890,
            priceUnit: 'piece',
            weightMG: 74088000,
            weightUnit: 'piece',
            sawingAllowed: null,
            paintingAllowed: null,
            facetEdgeProfileAllowed: null,
            roundEdgeProfileAllowed: null,
            angledEdgeProfileAllowed: null,
            angledEdgeProfileType: null,
            allowedEdgeProfiles: [],
            hasGrainDirection: true,
            hasTextureDirection: false,
            hasVisibleSide: null,
            gluingAndPressingAllowed: false,
            length1TrimThicknessMM: null,
            length2TrimThicknessMM: null,
            width1TrimThicknessMM: null,
            width2TrimThicknessMM: null,
            fscClaim: null,
            pefcClaim: 'pefc_100',
            id: '01J2BEPNZ3DX2J3AXPVYCT1JSD',
            createdAt: '2024-07-09T09:55:24.217Z',
            updatedAt: '2024-07-09T09:55:24.217Z',
            archivedAt: null,
            variationGroupId: null,
          },
          core2Material: {
            articleCode: '354656',
            thicknessMM: 18,
            unitAmount: 9890,
            m2Amount: 1682,
            nameWithCOCClaim: 'Thermopal Spaanplaat R34032 NW (PEFC 100%)',
            supplierCode: 'BNB',
            name: 'Thermopal Spaanplaat R34032 NW',
            originalName: 'Thermopal Spaanplaat R34032 NW',
            brand: 'Thermopal',
            series: 'Repro',
            colorCode: 'R34032',
            structureName: 'Wood',
            structureCode: 'NW',
            shortDescription: 'K1 Spaan Thermopal R34032 NW (4) 2800x2100x18mm',
            longDescription: null,
            imageUrl: null,
            sourceName: null,
            sourceLink: null,
            supplierERPCode: null,
            type: 'melamine-chipboard',
            lengthMM: 2800,
            widthMM: 2100,
            thicknessUM: 18000,
            priceSyncedAt: '2024-07-05T12:06:24.826Z',
            priceAmount: 9890,
            priceUnit: 'piece',
            weightMG: 74088000,
            weightUnit: 'piece',
            sawingAllowed: null,
            paintingAllowed: null,
            facetEdgeProfileAllowed: null,
            roundEdgeProfileAllowed: null,
            angledEdgeProfileAllowed: null,
            angledEdgeProfileType: null,
            allowedEdgeProfiles: [],
            hasGrainDirection: true,
            hasTextureDirection: false,
            hasVisibleSide: null,
            gluingAndPressingAllowed: false,
            length1TrimThicknessMM: null,
            length2TrimThicknessMM: null,
            width1TrimThicknessMM: null,
            width2TrimThicknessMM: null,
            fscClaim: null,
            pefcClaim: 'pefc_100',
            id: '01J2BEPNZ3DX2J3AXPVYCT1JSD',
            createdAt: '2024-07-09T09:55:24.217Z',
            updatedAt: '2024-07-09T09:55:24.217Z',
            archivedAt: null,
            variationGroupId: null,
          },
          topHpl: null,
          bottomHpl: null,
          width1Edgeband: {
            articleCode: '372589',
            thicknessMM: 0.8,
            unitAmount: 2800,
            m2Amount: 2435,
            nameWithCOCClaim: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            supplierCode: 'BNB',
            name: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            originalName: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            brand: 'Rehau',
            series: null,
            colorCode: 'R34032',
            structureName: 'Wood',
            structureCode: 'NW',
            shortDescription:
              'Kantenband ABS Rehau R34032 NW 23x0,8mm 50 meter rol',
            longDescription: null,
            imageUrl: null,
            sourceName: null,
            sourceLink: null,
            supplierERPCode: null,
            type: 'edgeband',
            lengthMM: 50000,
            widthMM: 23,
            thicknessUM: 800,
            priceSyncedAt: '2024-07-05T12:06:24.826Z',
            priceAmount: 2800,
            priceUnit: 'piece',
            weightMG: 1000000,
            weightUnit: 'piece',
            sawingAllowed: null,
            paintingAllowed: null,
            facetEdgeProfileAllowed: null,
            roundEdgeProfileAllowed: null,
            angledEdgeProfileAllowed: null,
            angledEdgeProfileType: null,
            allowedEdgeProfiles: [],
            hasGrainDirection: false,
            hasTextureDirection: false,
            hasVisibleSide: null,
            gluingAndPressingAllowed: false,
            length1TrimThicknessMM: null,
            length2TrimThicknessMM: null,
            width1TrimThicknessMM: null,
            width2TrimThicknessMM: null,
            fscClaim: null,
            pefcClaim: null,
            id: '01J2BEPNZDADXF4CDW87HZPZFF',
            createdAt: '2024-07-09T09:55:24.217Z',
            updatedAt: '2024-07-09T09:55:24.217Z',
            archivedAt: null,
            variationGroupId: null,
          },
          length1Edgeband: {
            articleCode: '372589',
            thicknessMM: 0.8,
            unitAmount: 2800,
            m2Amount: 2435,
            nameWithCOCClaim: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            supplierCode: 'BNB',
            name: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            originalName: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            brand: 'Rehau',
            series: null,
            colorCode: 'R34032',
            structureName: 'Wood',
            structureCode: 'NW',
            shortDescription:
              'Kantenband ABS Rehau R34032 NW 23x0,8mm 50 meter rol',
            longDescription: null,
            imageUrl: null,
            sourceName: null,
            sourceLink: null,
            supplierERPCode: null,
            type: 'edgeband',
            lengthMM: 50000,
            widthMM: 23,
            thicknessUM: 800,
            priceSyncedAt: '2024-07-05T12:06:24.826Z',
            priceAmount: 2800,
            priceUnit: 'piece',
            weightMG: 1000000,
            weightUnit: 'piece',
            sawingAllowed: null,
            paintingAllowed: null,
            facetEdgeProfileAllowed: null,
            roundEdgeProfileAllowed: null,
            angledEdgeProfileAllowed: null,
            angledEdgeProfileType: null,
            allowedEdgeProfiles: [],
            hasGrainDirection: false,
            hasTextureDirection: false,
            hasVisibleSide: null,
            gluingAndPressingAllowed: false,
            length1TrimThicknessMM: null,
            length2TrimThicknessMM: null,
            width1TrimThicknessMM: null,
            width2TrimThicknessMM: null,
            fscClaim: null,
            pefcClaim: null,
            id: '01J2BEPNZDADXF4CDW87HZPZFF',
            createdAt: '2024-07-09T09:55:24.217Z',
            updatedAt: '2024-07-09T09:55:24.217Z',
            archivedAt: null,
            variationGroupId: null,
          },
          width2Edgeband: {
            articleCode: '372589',
            thicknessMM: 0.8,
            unitAmount: 2800,
            m2Amount: 2435,
            nameWithCOCClaim: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            supplierCode: 'BNB',
            name: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            originalName: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            brand: 'Rehau',
            series: null,
            colorCode: 'R34032',
            structureName: 'Wood',
            structureCode: 'NW',
            shortDescription:
              'Kantenband ABS Rehau R34032 NW 23x0,8mm 50 meter rol',
            longDescription: null,
            imageUrl: null,
            sourceName: null,
            sourceLink: null,
            supplierERPCode: null,
            type: 'edgeband',
            lengthMM: 50000,
            widthMM: 23,
            thicknessUM: 800,
            priceSyncedAt: '2024-07-05T12:06:24.826Z',
            priceAmount: 2800,
            priceUnit: 'piece',
            weightMG: 1000000,
            weightUnit: 'piece',
            sawingAllowed: null,
            paintingAllowed: null,
            facetEdgeProfileAllowed: null,
            roundEdgeProfileAllowed: null,
            angledEdgeProfileAllowed: null,
            angledEdgeProfileType: null,
            allowedEdgeProfiles: [],
            hasGrainDirection: false,
            hasTextureDirection: false,
            hasVisibleSide: null,
            gluingAndPressingAllowed: false,
            length1TrimThicknessMM: null,
            length2TrimThicknessMM: null,
            width1TrimThicknessMM: null,
            width2TrimThicknessMM: null,
            fscClaim: null,
            pefcClaim: null,
            id: '01J2BEPNZDADXF4CDW87HZPZFF',
            createdAt: '2024-07-09T09:55:24.217Z',
            updatedAt: '2024-07-09T09:55:24.217Z',
            archivedAt: null,
            variationGroupId: null,
          },
          length2Edgeband: {
            articleCode: '372589',
            thicknessMM: 0.8,
            unitAmount: 2800,
            m2Amount: 2435,
            nameWithCOCClaim: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            supplierCode: 'BNB',
            name: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            originalName: 'ABS KB bestemd voor Pfleiderer R34032 NW',
            brand: 'Rehau',
            series: null,
            colorCode: 'R34032',
            structureName: 'Wood',
            structureCode: 'NW',
            shortDescription:
              'Kantenband ABS Rehau R34032 NW 23x0,8mm 50 meter rol',
            longDescription: null,
            imageUrl: null,
            sourceName: null,
            sourceLink: null,
            supplierERPCode: null,
            type: 'edgeband',
            lengthMM: 50000,
            widthMM: 23,
            thicknessUM: 800,
            priceSyncedAt: '2024-07-05T12:06:24.826Z',
            priceAmount: 2800,
            priceUnit: 'piece',
            weightMG: 1000000,
            weightUnit: 'piece',
            sawingAllowed: null,
            paintingAllowed: null,
            facetEdgeProfileAllowed: null,
            roundEdgeProfileAllowed: null,
            angledEdgeProfileAllowed: null,
            angledEdgeProfileType: null,
            allowedEdgeProfiles: [],
            hasGrainDirection: false,
            hasTextureDirection: false,
            hasVisibleSide: null,
            gluingAndPressingAllowed: false,
            length1TrimThicknessMM: null,
            length2TrimThicknessMM: null,
            width1TrimThicknessMM: null,
            width2TrimThicknessMM: null,
            fscClaim: null,
            pefcClaim: null,
            id: '01J2BEPNZDADXF4CDW87HZPZFF',
            createdAt: '2024-07-09T09:55:24.217Z',
            updatedAt: '2024-07-09T09:55:24.217Z',
            archivedAt: null,
            variationGroupId: null,
          },
        },
      ],
      core1Material: {
        articleCode: '354656',
        thicknessMM: 18,
        unitAmount: 9890,
        m2Amount: 1682,
        nameWithCOCClaim: 'Thermopal Spaanplaat R34032 NW (PEFC 100%)',
        supplierCode: 'BNB',
        name: 'Thermopal Spaanplaat R34032 NW',
        originalName: 'Thermopal Spaanplaat R34032 NW',
        brand: 'Thermopal',
        series: 'Repro',
        colorCode: 'R34032',
        structureName: 'Wood',
        structureCode: 'NW',
        shortDescription: 'K1 Spaan Thermopal R34032 NW (4) 2800x2100x18mm',
        longDescription: null,
        imageUrl: null,
        sourceName: null,
        sourceLink: null,
        supplierERPCode: null,
        type: 'melamine-chipboard',
        lengthMM: 2800,
        widthMM: 2100,
        thicknessUM: 18000,
        priceSyncedAt: '2024-07-05T12:06:24.826Z',
        priceAmount: 9890,
        priceUnit: 'piece',
        weightMG: 74088000,
        weightUnit: 'piece',
        sawingAllowed: null,
        paintingAllowed: null,
        facetEdgeProfileAllowed: null,
        roundEdgeProfileAllowed: null,
        angledEdgeProfileAllowed: null,
        angledEdgeProfileType: null,
        allowedEdgeProfiles: [],
        hasGrainDirection: true,
        hasTextureDirection: false,
        hasVisibleSide: null,
        gluingAndPressingAllowed: false,
        length1TrimThicknessMM: null,
        length2TrimThicknessMM: null,
        width1TrimThicknessMM: null,
        width2TrimThicknessMM: null,
        fscClaim: null,
        pefcClaim: 'pefc_100',
        id: '01J2BEPNZ3DX2J3AXPVYCT1JSD',
        createdAt: '2024-07-09T09:55:24.217Z',
        updatedAt: '2024-07-09T09:55:24.217Z',
        archivedAt: null,
        variationGroupId: null,
      },
      core2Material: {
        articleCode: '354656',
        thicknessMM: 18,
        unitAmount: 9890,
        m2Amount: 1682,
        nameWithCOCClaim: 'Thermopal Spaanplaat R34032 NW (PEFC 100%)',
        supplierCode: 'BNB',
        name: 'Thermopal Spaanplaat R34032 NW',
        originalName: 'Thermopal Spaanplaat R34032 NW',
        brand: 'Thermopal',
        series: 'Repro',
        colorCode: 'R34032',
        structureName: 'Wood',
        structureCode: 'NW',
        shortDescription: 'K1 Spaan Thermopal R34032 NW (4) 2800x2100x18mm',
        longDescription: null,
        imageUrl: null,
        sourceName: null,
        sourceLink: null,
        supplierERPCode: null,
        type: 'melamine-chipboard',
        lengthMM: 2800,
        widthMM: 2100,
        thicknessUM: 18000,
        priceSyncedAt: '2024-07-05T12:06:24.826Z',
        priceAmount: 9890,
        priceUnit: 'piece',
        weightMG: 74088000,
        weightUnit: 'piece',
        sawingAllowed: null,
        paintingAllowed: null,
        facetEdgeProfileAllowed: null,
        roundEdgeProfileAllowed: null,
        angledEdgeProfileAllowed: null,
        angledEdgeProfileType: null,
        allowedEdgeProfiles: [],
        hasGrainDirection: true,
        hasTextureDirection: false,
        hasVisibleSide: null,
        gluingAndPressingAllowed: false,
        length1TrimThicknessMM: null,
        length2TrimThicknessMM: null,
        width1TrimThicknessMM: null,
        width2TrimThicknessMM: null,
        fscClaim: null,
        pefcClaim: 'pefc_100',
        id: '01J2BEPNZ3DX2J3AXPVYCT1JSD',
        createdAt: '2024-07-09T09:55:24.217Z',
        updatedAt: '2024-07-09T09:55:24.217Z',
        archivedAt: null,
        variationGroupId: null,
      },
      bottomHpl: null,
      topHpl: null,
      edgeband: {
        articleCode: '372589',
        thicknessMM: 0.8,
        unitAmount: 2800,
        m2Amount: 2435,
        nameWithCOCClaim: 'ABS KB bestemd voor Pfleiderer R34032 NW',
        supplierCode: 'BNB',
        name: 'ABS KB bestemd voor Pfleiderer R34032 NW',
        originalName: 'ABS KB bestemd voor Pfleiderer R34032 NW',
        brand: 'Rehau',
        series: null,
        colorCode: 'R34032',
        structureName: 'Wood',
        structureCode: 'NW',
        shortDescription:
          'Kantenband ABS Rehau R34032 NW 23x0,8mm 50 meter rol',
        longDescription: null,
        imageUrl: null,
        sourceName: null,
        sourceLink: null,
        supplierERPCode: null,
        type: 'edgeband',
        lengthMM: 50000,
        widthMM: 23,
        thicknessUM: 800,
        priceSyncedAt: '2024-07-05T12:06:24.826Z',
        priceAmount: 2800,
        priceUnit: 'piece',
        weightMG: 1000000,
        weightUnit: 'piece',
        sawingAllowed: null,
        paintingAllowed: null,
        facetEdgeProfileAllowed: null,
        roundEdgeProfileAllowed: null,
        angledEdgeProfileAllowed: null,
        angledEdgeProfileType: null,
        allowedEdgeProfiles: [],
        hasGrainDirection: false,
        hasTextureDirection: false,
        hasVisibleSide: null,
        gluingAndPressingAllowed: false,
        length1TrimThicknessMM: null,
        length2TrimThicknessMM: null,
        width1TrimThicknessMM: null,
        width2TrimThicknessMM: null,
        fscClaim: null,
        pefcClaim: null,
        id: '01J2BEPNZDADXF4CDW87HZPZFF',
        createdAt: '2024-07-09T09:55:24.217Z',
        updatedAt: '2024-07-09T09:55:24.217Z',
        archivedAt: null,
        variationGroupId: null,
      },
    },
  ],
  addresses: [],
};
