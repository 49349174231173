import { Alert, Icon } from '@/primitives/Icons';

export const ErrorMessage = ({
  message,
  children,
}: {
  message: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className="errors">
      <h3>
        <span className="flexAlign gap-xs">
          <Icon icon={<Alert />} />
          {message}
        </span>
      </h3>
      {children}
    </div>
  );
};
