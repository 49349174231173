import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from '@/primitives/Button';

import { Modal, ModalProps } from './Modal';
import styles from './Modal.module.css';

type ConfirmationDialogVariant = 'primary' | 'alert';

type ConfirmationDialogProps = Omit<ModalProps, 'handleClose'> & {
  onConfirm: () => void;
  onClose: () => void;
  confirmLabel?: string;
  variant?: ConfirmationDialogVariant;
};

const variantsMap: Record<ConfirmationDialogVariant, string | null> = {
  primary: '',
  alert: 'alert',
};

export const ConfirmationDialog = ({
  children,
  confirmLabel,
  onConfirm,
  onClose,
  variant = 'primary',
  ...props
}: ConfirmationDialogProps) => {
  const { t } = useTranslation();

  const buttonStyle = variantsMap[variant];

  return (
    <Modal handleClose={onClose} {...props}>
      <div className={styles.body}>{children}</div>
      <div className="flexAlign">
        <Button className="outline" onClick={onClose}>
          {t('common.cta.cancel')}
        </Button>
        <Button
          className={cn(buttonStyle)}
          onClick={onConfirm}
          data-cy="confirm-button"
        >
          {confirmLabel || t('common.cta.confirm')}
        </Button>
      </div>
    </Modal>
  );
};
