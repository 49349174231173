import React from 'react';
import { useTranslation } from 'react-i18next';

import BasePagination from './BasePagination';
import styles from './Pagination.module.css';
type PaginationProps = {
  children: React.ReactElement[];
  navKey?: 'sheet' | 'material';
};
export const Pagination = ({ children, navKey = 'sheet' }: PaginationProps) => {
  const { t } = useTranslation();
  const container = React.useRef<HTMLDivElement>(null);
  const [page, setPage] = React.useState(1);
  const pages = React.Children.count(children);

  React.useEffect(() => {
    const isEmpty = pages === 0;
    const isOutOfBounds = page > pages;

    if (isEmpty || isOutOfBounds) {
      setPage(1);
      return;
    }
  }, [page, pages]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content} ref={container}>
        {React.Children.map(children, (child, i) => {
          const display = i + 1 === page ? 'block' : 'none';
          return React.cloneElement(child, {
            ...child.props,
            style: { display },
          });
        })}
      </div>
      {pages > 1 && (
        <div className={styles.navigationWrapper}>
          <p>{t(`translation.pagination.${navKey}`, { page, pages })}</p>
          <BasePagination
            totalPages={pages}
            currentPage={page}
            onPageChanged={setPage}
          />
        </div>
      )}
    </div>
  );
};
