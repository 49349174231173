import './Field.css';

import { CutlistState } from '@cutr/constants/cutlist';
import cn from 'classnames';

import { UserLeadState } from '@/api/account';
import { Address } from '@/api/address';

import { Input, InputProps } from './Input';

export type FieldProp = {
  label: string;
  name: keyof UserLeadState | keyof Address | keyof CutlistState;
  isRequired?: boolean;
  isDisabled?: boolean;
  type?: 'text' | 'email' | 'date';
  value?: string;
  setProp: (value: string) => void;
  className?: string;
};

export const Field = ({
  label = '',
  name,
  isDisabled = false,
  isRequired,
  type = 'text',
  setProp,
  className,
  ...props
}: FieldProp & InputProps) => {
  return (
    <div className={cn('field', className)}>
      {label && (
        <label htmlFor={name}>
          {label} {isRequired && '*'}
        </label>
      )}
      <Input
        name={name}
        id={name}
        required={isRequired}
        type={type}
        disabled={isDisabled}
        onInput={(e) => {
          setProp(e.currentTarget.value);
        }}
        className={className}
        {...props}
      />
    </div>
  );
};
