import './Badge.css';

import React from 'react';

export type BadgeVariant =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'purple'
  | 'success'
  | 'warning'
  | 'signal'
  | 'alert'
  | 'pill'
  | 'new';

interface BadgeProps {
  variant?: BadgeVariant;
  label?: string;
  tooltip?: string;
  children?: React.ReactNode;
}

const Badge: React.FC<BadgeProps> = ({
  variant = 'default',
  label,
  tooltip,
  children,
}) => {
  const className = ['badge', variant].join(' ');

  return (
    <div className={className} data-tooltip={tooltip}>
      {children || label}
    </div>
  );
};

export default Badge;
