import { Features } from '@cutr/constants/cutlist-theme';
import React, { PropsWithChildren } from 'react';

const FeatureContext = React.createContext({} as Features);

export const useCurrentFeaturesFromProvider = () =>
  React.useContext(FeatureContext);

export const FeatureProvider = ({
  children,
  initialState,
}: PropsWithChildren & { initialState: Features }) => {
  return (
    <FeatureContext.Provider value={initialState}>
      {children}
    </FeatureContext.Provider>
  );
};
