import {
  CURRENCY_OPTIONS,
  CurrencyOptions,
  CutlistOrder,
  MaterialGroup,
  PricingLineItem,
} from '@cutr/constants/cutlist';
import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { agentApi } from '@/api/backend/agent';
import { useFeatureFlag } from '@/api/featureFlags';
import { useMaterialGroupState } from '@/api/materialsGroup';
import {
  MaterialPriceMap,
  useAggregatedMaterialGroups,
  usePricingStore,
} from '@/api/pricing';
import { useCutlistState } from '@/api/store';
import { AgentQuoteHeader } from '@/blocks/AgentOrderHeader';
import Card from '@/blocks/Card';
import { PriceSummary } from '@/blocks/PriceSummary';
import { Button } from '@/primitives/Button';
import { ArrowLeft, Cart, Icon } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { Select } from '@/primitives/Select';
import { useAgentCutlistPatch, useSavePriceOverride } from '@/queries/agent';
import { agentKeys } from '@/queries/keys';
import { currencyFormatter } from '@/utils/format';
import {
  useDebounce,
  useDebounceArray,
  useNextInputOnEnter,
} from '@/utils/hooks';
import { cleanNumberInput } from '@/utils/input';

import styles from './AgentQuotePrice.module.css';

const isDev = import.meta.env.VITE_CUTR_ENV === 'development';

export const AgentQuotePrice = (): JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParams();
  const priceStore = usePricingStore();
  const { mutateAsync: savePriceOverride } = useSavePriceOverride(id!);
  const { discountAmount, discountPercentage, markupAmount, markupPercentage } =
    useCutlistState();

  const toCheckout = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await savePriceOverride({
        id: id!,
        discountAmount,
        discountPercentage,
        markupAmount,
        markupPercentage,
        pricingLineItems: priceStore.pricingLineItems,
      });

      toast.success(t('common.cta.priceSaved') as string);
      navigate(`/admin/orders/${id}/quote/checkout`);
    } catch (e) {
      priceStore.setError(e as Error);
      toast.error(t('common.errors.saveOrder') as string);
    }
  };

  return (
    <form name="quote" onSubmit={toCheckout}>
      <AgentQuoteHeader />
      <section className="layout">
        <QuoteEditor />
        <Aside />
      </section>
    </form>
  );
};

const Aside = () => {
  usePricingFromQuote();

  return (
    <aside>
      <PriceSummary>
        <div className="stack" style={{ paddingTop: 'var(--space-s)' }}>
          <Button
            type="submit"
            icon={<Icon style={{ flex: '0 0 auto' }} icon={<Cart />} />}
          >
            {t('agent.quoteFlow.toCheckout')}
          </Button>
        </div>
      </PriceSummary>
    </aside>
  );
};

export function usePricingFromQuote() {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { discountAmount, discountPercentage, markupAmount, markupPercentage } =
    useCutlistState();
  const latestOverridesRef = React.useRef<(number | null)[]>([]);

  const priceStore = usePricingStore();
  const quantityOverrides = priceStore.pricingLineItems
    .filter((pli) => pli.category !== 'discount' && pli.category !== 'markup')
    .map((pli) => pli.quantityOverride);

  const debouncedDiscountAmount = useDebounce<number | undefined>(
    discountAmount,
    500,
    discountAmount
  );

  const debouncedDiscountPercentage = useDebounce<number | undefined>(
    discountPercentage,
    500,
    discountPercentage
  );

  const debouncedMarkupAmount = useDebounce<number | undefined>(
    markupAmount,
    500,
    markupAmount
  );

  const debouncedMarkupPercentage = useDebounce<number | undefined>(
    markupPercentage,
    500,
    markupPercentage
  );

  const debouncedOverrides = useDebounceArray<number | null>(
    quantityOverrides,
    500,
    quantityOverrides
  );

  React.useEffect(() => {
    latestOverridesRef.current = quantityOverrides;
  }, [quantityOverrides]);

  React.useEffect(() => {
    if (!id) return;
    const queryKey = agentKeys.getPriceOverride(
      id,
      debouncedOverrides,
      debouncedDiscountAmount,
      debouncedDiscountPercentage,
      debouncedMarkupAmount,
      debouncedMarkupPercentage
    );

    const priceCache = queryClient.getQueryData<CutlistOrder>(queryKey);
    if (priceCache) {
      priceStore.setAmountVat(priceCache.totalAmountInclVAT);
      priceStore.setAmountNoVat(priceCache.totalAmountExclVAT);
      priceStore.setPricing(priceCache.pricingLineItems);
      return;
    }

    priceStore.setError(null);

    queryClient
      .fetchQuery<CutlistOrder>({
        queryKey,
        queryFn: () =>
          agentApi.getPriceOverride({
            id,
            pricingLineItems: priceStore.pricingLineItems,
            discountAmount,
            discountPercentage,
            markupAmount,
            markupPercentage,
          }),
      })
      .then((data) => {
        if (
          JSON.stringify(debouncedOverrides) ===
          JSON.stringify(latestOverridesRef.current)
        ) {
          priceStore.setAmountVat(data.totalAmountInclVAT);
          priceStore.setAmountNoVat(data.totalAmountExclVAT);
          priceStore.setPricing(data.pricingLineItems);
        }
      })
      .catch((e) => {
        priceStore.setError(e);
      });
  }, [
    ...debouncedOverrides,
    debouncedDiscountAmount,
    debouncedDiscountPercentage,
    debouncedMarkupAmount,
    debouncedMarkupPercentage,
  ]);

  React.useEffect(() => {
    if (!id) return;

    const queryKey = agentKeys.getPriceOverride(
      id,
      quantityOverrides,
      debouncedDiscountAmount,
      debouncedDiscountPercentage,
      debouncedMarkupAmount,
      debouncedMarkupPercentage
    );

    const priceCache = queryClient.getQueryData<CutlistOrder>(queryKey);
    if (!priceCache) return;

    priceStore.setAmountVat(priceCache.totalAmountInclVAT);
    priceStore.setAmountNoVat(priceCache.totalAmountExclVAT);
    priceStore.setPricing(priceCache.pricingLineItems);
  }, [
    ...quantityOverrides,
    debouncedDiscountAmount,
    debouncedDiscountPercentage,
    debouncedMarkupAmount,
    debouncedMarkupPercentage,
  ]);
}

const CurrencyBlock = () => {
  const { t } = useTranslation();

  const {
    currency,
    setCurrency,
    exchangeRate,
    setExchangeRate,
    sourceCurrency,
    discountAmount,
    discountPercentage,
    markupAmount,
    markupPercentage,
  } = useCutlistState();
  const { setPricing, setAmountVat, setAmountNoVat } = usePricingStore();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { mutateAsync: patchCutlist } = useAgentCutlistPatch(id!);
  const { mutateAsync: savePriceOverride } = useSavePriceOverride(id!);

  const [pendingCurrency, setPendingCurrency] = React.useState(currency);
  const [pendingExchangeRate, setPendingExchangeRate] = React.useState(
    exchangeRate.toString()
  );

  if (!id) return null;

  const handleSaveCurrency = async () => {
    try {
      const newExchangeRate =
        pendingCurrency === sourceCurrency ? 1 : Number(pendingExchangeRate);

      await patchCutlist({
        id,
        fields: {
          currency: pendingCurrency as CurrencyOptions,
          exchangeRate: newExchangeRate,
        },
      });

      const queryKey = agentKeys.getPrice(id as string);
      const queryFn = () => agentApi.getPrice(id as string);

      const data = await queryClient.fetchQuery({ queryKey, queryFn });

      await savePriceOverride({
        id: id!,
        discountAmount,
        discountPercentage,
        markupAmount,
        markupPercentage,
        pricingLineItems: data?.pricingLineItems,
      });

      setExchangeRate(newExchangeRate);
      setCurrency(pendingCurrency as CurrencyOptions);
      setPricing(data?.pricingLineItems || []);
      setAmountVat(data?.totalAmountInclVAT || 0);
      setAmountNoVat(data?.totalAmountExclVAT || 0);

      toast.success(t('agent.currencySaved'));
    } catch (e) {
      toast.error(t('agent.errors.saveCurrency'));
    }
  };

  return (
    <>
      <Select
        value={pendingCurrency}
        defaultValue={pendingCurrency}
        onChange={(e) => setPendingCurrency(e.target.value as CurrencyOptions)}
        className="smaller"
        style={{ width: '5rem' }}
        options={CURRENCY_OPTIONS.map((c) => ({ label: c, value: c }))}
      />
      {sourceCurrency !== pendingCurrency && (
        <div className="flexAlign">
          <span>{`1 ${sourceCurrency} =`}</span>
          <Input
            type="number"
            value={pendingExchangeRate ?? ''}
            onChange={(e) => setPendingExchangeRate(e.target.value)}
            placeholder={`1 ${sourceCurrency} = ? ${pendingCurrency}`}
            className="smaller"
            step="0.01"
            min="0"
            style={{ width: '5rem', margin: '0 0.25rem' }}
          />
          <span>{pendingCurrency}</span>
        </div>
      )}
      <Button
        className="smaller"
        onClick={handleSaveCurrency}
        disabled={
          pendingCurrency === currency &&
          (pendingCurrency === sourceCurrency ||
            Number(pendingExchangeRate) === exchangeRate)
        }
      >
        {t('common.cta.save')}
      </Button>
    </>
  );
};

const QuoteEditor = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const isCurrencyEnabled = useFeatureFlag('cutlist-currency-switcher');

  if (!id) return null;

  useNextInputOnEnter();

  return (
    <div>
      <div className="flexAlign gap-m gutter">
        <Button
          className="outline smaller"
          onClick={() =>
            navigate(`/admin/orders/${id}/quote/parts`, { replace: true })
          }
          icon={<Icon icon={<ArrowLeft />} />}
        >
          {t('agent.backDialogTitle')}
        </Button>
        <h2>{t('agent.quoteEditor.title')}</h2>
        {isCurrencyEnabled && <CurrencyBlock />}
      </div>

      <MaterialGroupQuotes />
    </div>
  );
};

const MaterialGroupQuotes = () => {
  const groups = useMaterialGroupState((state) => state.groups);

  return (
    <div className={styles.quoteContainer}>
      {groups
        .filter((g) => g.ulid)
        .sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
        .map((group) => (
          <MaterialGroupQuote key={group.id} group={group} />
        ))}
    </div>
  );
};

const MaterialGroupQuote = ({ group }: { group: MaterialGroup }) => {
  const materialsPriceMap: MaterialPriceMap = useAggregatedMaterialGroups();
  const { currency } = useCutlistState();

  const getTotalAmount = (lineItems: PricingLineItem[]) => {
    if (lineItems.length === 0) {
      return '€ ---.--';
    }

    const totalAmount = lineItems.reduce(
      (acc, item) => acc + item.totalAmount,
      0
    );
    return currencyFormatter(totalAmount / 100, currency);
  };

  const currentLineItems = materialsPriceMap[group.id] || [];
  const groupTotalAmount = getTotalAmount(currentLineItems);

  const materialLineItems = currentLineItems.filter(
    (item) => item.category === 'material'
  );
  const materialTotalAmount = getTotalAmount(materialLineItems);

  const productionCostLineItems = currentLineItems.filter(
    (item) => item.category !== 'material'
  );
  const productionCostTotalAmount = getTotalAmount(productionCostLineItems);

  return (
    <Card key={group.id}>
      <QuoteHeader groupTotalAmount={groupTotalAmount} index={group.index} />
      <div className={styles.quoteBody}>
        <QuoteSection
          title={t('agent.quoteEditor.subheaders.materials')}
          subtotal={materialTotalAmount}
          lineItems={materialLineItems}
        />
        <QuoteSection
          title={t('agent.quoteEditor.subheaders.productionCosts')}
          subtotal={productionCostTotalAmount}
          lineItems={productionCostLineItems}
        />
      </div>
    </Card>
  );
};

const QuoteHeader = ({
  groupTotalAmount,
  index,
}: {
  groupTotalAmount: string;
  index?: number;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <div className="flexAlign opposites">
        <h3>{t('agent.quoteEditor.materialNr', { count: index })} </h3>
        <h3>{'•'}</h3>
        <h3>{groupTotalAmount}</h3>
      </div>

      <MaterialQuoteActions />
    </div>
  );
};

const QuoteSection = ({
  title,
  subtotal,
  lineItems,
}: {
  title: string;
  subtotal: string;
  lineItems: PricingLineItem[];
}) => {
  return (
    <>
      <div className={styles.subheader}>
        <h4>{title}</h4>
        <h4>{'•'}</h4>
        <h4>{subtotal}</h4>
      </div>

      {lineItems.map((pricingLineItem) => (
        <LineItem key={pricingLineItem.id} pricingLineItem={pricingLineItem} />
      ))}

      <div className={styles.emptyRow} />
    </>
  );
};

const LineItem = ({
  pricingLineItem,
}: {
  pricingLineItem: PricingLineItem;
}) => {
  const { currency } = useCutlistState();
  const isEditable = ((pricingLineItem: PricingLineItem) => {
    if (
      pricingLineItem.category === 'material' &&
      pricingLineItem.unit !== 'm'
    ) {
      return true;
    }

    if (pricingLineItem.category === 'sawing' && pricingLineItem.unit === 'm') {
      return true;
    }

    return false;
  })(pricingLineItem);

  const { setPricingLineItem } = usePricingStore();

  const handleQuantityOverride = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPricingLineItem({
      ...pricingLineItem,
      quantityOverride: Number(cleanNumberInput(e.target.value)),
    });
  };

  return (
    <>
      <span className={cn(styles.cell, styles.padStart)}>
        {pricingLineItem.description}
      </span>

      {!isEditable && (
        <span className={cn(styles.cell, styles.alignEnd)}>
          {pricingLineItem.unit === 'second'
            ? `${pricingLineItem.quantity / 60}`
            : pricingLineItem.quantity}
        </span>
      )}

      {isEditable && (
        <Input
          name={`${pricingLineItem.id}-quantity`}
          id={`${pricingLineItem.id}-quantity`}
          value={pricingLineItem.quantityOverride ?? ''}
          placeholder={isDev ? pricingLineItem.quantity.toString() : undefined}
          required
          className={styles.editableCell}
          onWheel={(e) => e.currentTarget.blur()}
          onChange={handleQuantityOverride}
        />
      )}

      <strong className={cn(styles.cell, styles.alignCenter)}>{'×'}</strong>
      <span className={styles.cell}>
        {currencyFormatter(
          (pricingLineItem.unit === 'second'
            ? pricingLineItem.unitAmount * 60
            : pricingLineItem.unitAmount) / 100,
          currency
        )}
      </span>
      <strong className={cn(styles.cell, styles.alignCenter)}>{'='}</strong>
      <strong className={styles.cell}>
        {currencyFormatter(pricingLineItem.totalAmount / 100, currency)}
      </strong>
    </>
  );
};

const MaterialQuoteActions = () => {
  return <div></div>;
};
