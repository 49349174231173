let session = {
  getItem: () => null,
  setItem: () => null,
} as unknown as Storage;
let local = {
  getItem: () => null,
  setItem: () => null,
} as unknown as Storage;

try {
  session = window.sessionStorage;
} catch (e) {
  // do nothing
}

try {
  local = window.localStorage;
} catch (e) {
  // do nothing
}

export const LocalStorage = {
  get(key: string) {
    const item = local.getItem(key);
    if (!item) return null;

    return JSON.parse(item);
  },
  set(key: string, value: unknown) {
    const obj = JSON.stringify(value);
    return local.setItem(key, obj);
  },
};

export const SessionStorage = {
  get(key: string) {
    const item = session.getItem(key);
    if (!item) return null;

    return JSON.parse(item);
  },
  set(key: string, value: unknown) {
    const obj = JSON.stringify(value);
    return session.setItem(key, obj);
  },
  remove(key: string) {
    return session.removeItem(key);
  },
};
