import { Features, Theme } from '@cutr/constants/cutlist-theme';
import React from 'react';

import { FeatureProvider } from '@/FeatureProvider';
import { useGroupByMaterial, useTogglePricing } from '@/hooks';
import { useApplyTheme } from '@/hooks';

import { CutlistMockProvider } from './decorators/mockCutlist';
import { MaterialsProvider } from './decorators/mockMaterials';
import { Args } from './layout/types';

export const withFeaturesOverride = (Story: React.FC, context: Args) => {
  return function WithFeaturesOverride() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { theme, ...features } = context;
    return (
      <FeatureProvider initialState={{ ...features }}>
        <Story />
      </FeatureProvider>
    );
  };
};

export const withMockCutlist = (Story: React.FC) => {
  useGroupByMaterial();

  return function WithMockCutlist() {
    return (
      <CutlistMockProvider>
        <Story />
      </CutlistMockProvider>
    );
  };
};

export const withMockMaterials = (Story: React.FC) => {
  return function WithMockMaterials() {
    return (
      <MaterialsProvider>
        <Story />
      </MaterialsProvider>
    );
  };
};

export const withTheme = (
  Story: React.FC,
  context: { theme: Theme } & Features
) => {
  return function WithTheme() {
    const { theme } = context;
    useApplyTheme(theme);
    return (
      <>
        <Story />
      </>
    );
  };
};

export const withPriceToggle = (Story: React.FC) => {
  return function WithPriceToggle() {
    useTogglePricing();
    return (
      <>
        <Story />
      </>
    );
  };
};
