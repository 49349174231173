import React, { useState } from 'react';

import { Input } from '@/primitives/Input';

interface TimeInputProps {
  value: number;
  onChange: (seconds: number) => void;
  placeholder?: string;
  'data-cy'?: string;
  key?: React.Key;
}

export const TimeInput: React.FC<TimeInputProps> = ({
  value,
  onChange,
  placeholder,
  'data-cy': dataCy,
  key,
}) => {
  const getValue = (totalSeconds: number) => {
    if (totalSeconds === 0) {
      return '0:00';
    }

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };
  const [timeString, setTimeString] = useState(getValue(value));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [minutesStr, secondsStr] = e.target.value.split(':');
    const minutes = parseInt(minutesStr, 10) || 0;
    const seconds = parseInt(secondsStr, 10) || 0;

    if (seconds >= 0 && seconds < 60) {
      const totalSeconds = minutes * 60 + seconds;
      onChange(totalSeconds);
      setTimeString(getValue(totalSeconds));
    }
  };

  return (
    <Input
      className="snug"
      value={timeString}
      onChange={handleChange}
      placeholder={placeholder}
      data-cy={dataCy}
      pattern="[0-9]+:[0-5][0-9]"
      key={key}
    />
  );
};
