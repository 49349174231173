import { MaterialGroup } from '@cutr/constants/cutlist';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { resetErrors } from '@/api/errors';
import { getMaterial } from '@/api/materials';
import { setGroupAndParts } from '@/api/materialsGroup';
import { useCutlistState } from '@/api/store';
import Card from '@/blocks/Card';
import { ConfirmationDialog } from '@/blocks/ConfirmationDialog';
import { useCurrentFeatures } from '@/theme';
import {
  applyCustomTrimThickness,
  getTrimConfigByType,
} from '@/utils/features';

import styles from './MaterialGroupTypeSelect.module.css';

export const MaterialGroupTypeSelect = ({
  group,
}: {
  group: MaterialGroup;
}) => {
  const { t } = useTranslation();
  const [typeToConfirm, confirmType] = React.useState<
    'panels-and-strips' | 'sheets-only' | undefined
  >(undefined);
  const {
    supportedSheetEdgeTrims,
    defaultSheetEdgeTrimTypeForPartGroup,
    defaultSheetEdgeTrimTypeForSheetGroup,
  } = useCurrentFeatures();

  const { addPart, removePartsByGroup } = useCutlistState();
  const groupParts = useCutlistState((state) =>
    state.parts.filter((part) => part.groupId === group.id)
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const type = e.target.value as MaterialGroup['type'];

    if (groupParts.length === 0) {
      updateMaterialGroupType(type);
      return;
    }

    // trigger confirmation modal
    confirmType(type);
  };

  const updateMaterialGroupType = (type: MaterialGroup['type']) => {
    const defaultGroupTrimType = (() => {
      if (type === 'sheets-only') return defaultSheetEdgeTrimTypeForSheetGroup;
      if (type === 'panels-and-strips')
        return defaultSheetEdgeTrimTypeForPartGroup;

      // return a default even if we were explicit above
      return defaultSheetEdgeTrimTypeForPartGroup;
    })();

    const sheetEdgeTrimConfig = getTrimConfigByType(
      supportedSheetEdgeTrims,
      defaultGroupTrimType
    );

    const updatedGroup = { ...group, type, sheetEdgeTrimConfig };

    const material = getMaterial(group.core1);
    if (type !== 'sheets-only') {
      applyCustomTrimThickness(updatedGroup, material);
    }

    resetGroup();

    const partType = type === 'panels-and-strips' ? 'panel' : 'sheet';
    addPart(updatedGroup, partType);

    setGroupAndParts(updatedGroup);
    resetErrors();
  };

  const resetGroup = () => {
    removePartsByGroup(group.id);
    confirmType(undefined);
  };

  return (
    <div className={styles.groupTypeGrid}>
      <input
        type="radio"
        name="groupType"
        id="panels-and-strips"
        className="hidden"
        value="panels-and-strips"
        checked={group.type === 'panels-and-strips'}
        onChange={handleChange}
      />
      <label htmlFor="panels-and-strips">
        <Card className={styles.groupTypeContent}>
          <strong>
            {t('cutlist-form.groupTypes.panels-and-strips.title')}
          </strong>
          {t('cutlist-form.groupTypes.panels-and-strips.description')}
        </Card>
      </label>

      <input
        type="radio"
        name="groupType"
        id="sheets-only"
        className="hidden"
        value="sheets-only"
        checked={group.type === 'sheets-only'}
        onChange={handleChange}
      />
      <label htmlFor="sheets-only">
        <Card className={styles.groupTypeContent}>
          <strong>{t('cutlist-form.groupTypes.sheets-only.title')}</strong>
          {t('cutlist-form.groupTypes.sheets-only.description')}
        </Card>
      </label>

      <ConfirmationDialog
        title={t('cutlist-form.cta.changeGroupType.title')}
        onConfirm={() => {
          updateMaterialGroupType(typeToConfirm!);
          confirmType(undefined);
        }}
        onClose={() => confirmType(undefined)}
        isOpen={!!typeToConfirm}
      >
        {t('cutlist-form.cta.changeGroupType.body')}
      </ConfirmationDialog>
    </div>
  );
};
