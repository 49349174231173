/* eslint-disable react/no-unknown-property */
import { EdgeProfileType } from '@cutr/constants/edge-profiling';

const NoEdgeProfiling = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <g>
      <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
      <path
        d="M86.443 8.55713H16V36.0255H86.443V8.55713ZM18.6582 33.3673V11.2154H83.7848V33.3673H18.6582Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

const FacetTop = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L11 36L11 11.9137L14.3349 8L59 8Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const FacetBothSides = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L14.3635 36L11 31.658L11 11.9137L14.3349 8L59 8Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const RoundedTop = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      transform="scale(1,-1)"
      transform-origin="center"
      d="M11 12H77V32H15C12.7909 32 11 30.2091 11 28V12Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const RoundedBothSides = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L15 36C12.7909 36 11 34.2091 11 32L11 12C11 9.79086 12.7909 8 15 8L59 8Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const Angle80 = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L15.0944 36L11.1507 8L59 8Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const Angle80Radius3 = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L14.0689 36L11.5781 12.4202C11.3286 10.0579 13.1806 8 15.556 8L59 8Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const Radius5 = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L11 36L11 15C11 11.134 14.134 8 18 8L59 8Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const Radius3 = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      transform="scale(1,-1)"
      transform-origin="center"
      d="M11 12H77V32H15C12.7909 32 11 30.2091 11 28V12Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const Radius8 = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M60 37L60 7L10 7L10 22C10 30.2843 16.7157 37 25 37L60 37Z"
      transform="scale(1,-1)"
      transform-origin="center"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const Radius3Double = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L15 36C12.7909 36 11 34.2091 11 32L11 12C11 9.79086 12.7909 8 15 8L59 8Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const Radius10 = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M11 36H77V8H28C18.6112 8 11 15.6112 11 25V36Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const Angle75 = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L16.8061 36L11.2192 8L59 8Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const Angle75Radius3 = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L16.8061 36L12.1735 12.7827L11.1929 12.9784L12.1735 12.7827C11.6798 10.308 13.5728 8 16.0962 8L59 8Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const Angle75Radius3Double = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L20.0868 36C18.1794 36 16.5374 34.6532 16.1641 32.7827L12.1735 12.7827L11.1929 12.9784L12.1735 12.7827C11.6798 10.308 13.5728 8 16.0962 8L59 8Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const Angle60 = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L23.6841 36L11.5245 8L59 8Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const Angle9x9 = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L11 36L11 22.342L22.11 8L59 8Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const ChamferSingle = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L11 36L11 11.9137L14.3349 8L59 8Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const ChamferDouble = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L14.3635 36L11 31.658L11 11.9137L14.3349 8L59 8Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const Angle45 = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L33.7297 36L12.0396 8L59 8Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

const Angle135 = () => (
  <svg viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="12" fill="var(--gray-3)" />
    <path
      d="M59 8L59 36L33.7297 36L12.0396 8L59 8Z"
      transform="scale(1,-1)"
      transform-origin="center"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

export const edgeProfilesIconMap: Record<EdgeProfileType, React.ReactNode> = {
  none: <NoEdgeProfiling />,
  'facet-top': <FacetTop />,
  'facet-both-sides': <FacetBothSides />,
  'rounded-top': <RoundedTop />,
  'rounded-both-sides': <RoundedBothSides />,
  angle45: <Angle45 />,
  angle135: <Angle135 />,
  radius3: <Radius3 />,
  radius5: <Radius5 />,
  radius8: <Radius8 />,
  radius10: <Radius10 />,
  'radius3-double': <Radius3Double />,
  angle80: <Angle80 />,
  'angle80-radius3': <Angle80Radius3 />,
  angle75: <Angle75 />,
  'angle75-radius3': <Angle75Radius3 />,
  'angle75-radius3-double': <Angle75Radius3Double />,
  angle60: <Angle60 />,
  angle9x9: <Angle9x9 />,
  'chamfer-single': <ChamferSingle />,
  'chamfer-double': <ChamferDouble />,
};
