import { createPortal } from 'react-dom';

type ReactPortalProps = {
  children: React.ReactNode;
  wrapperId: string;
};
function ReactPortal({
  children,
  wrapperId = 'react-portal-wrapper',
}: ReactPortalProps) {
  let element = document.getElementById(wrapperId);
  // if element is not found with wrapperId,
  // create and append to body
  if (!element) {
    element = createWrapperAndAppendToBody(wrapperId);
  }

  return createPortal(children, element);
}

function createWrapperAndAppendToBody(wrapperId: string) {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
}

export default ReactPortal;
