import cn from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { api, ApiError } from '@/api/backend';
import { useIsFeatureEnabled } from '@/api/featureFlags';
import { AuthState, useAuthStore, useIsLoggedIn } from '@/api/login';
import { ErrorMessage } from '@/blocks/ErrorMessage';
import { Button, RouterButton } from '@/primitives/Button';
import { Input } from '@/primitives/Input';
import { useThemeConfig } from '@/theme';

import styles from './LandingPage.module.css';
import NewLandingPage from './NewLandingPage';
const ENVIRONMENT = import.meta.env.VITE_CUTR_ENV;

function LandingPage() {
  const isLoggedIn = useIsLoggedIn();
  const { welcomeImage } = useThemeConfig();

  const isFeatureEnabled = useIsFeatureEnabled();

  const partnerImage = welcomeImage || '/cutlist.png';

  if (isFeatureEnabled('cutlist-unauth-users')) {
    return <NewLandingPage />;
  }

  return (
    <main className="content">
      <section className={styles.landingPage}>
        {!isLoggedIn ? <LoginForm /> : <Welcome />}
        <div className={styles.logo}>
          <img alt="cutlist" src={partnerImage} />
        </div>
        <HowTo />
        <Accordion />
      </section>
    </main>
  );
}

const Welcome = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'landing-page.welcome',
  });

  return (
    <div className={styles.welcome}>
      <h1>{t('title')}</h1>
      <p>{t('description')}</p>
      <RouterButton size="l" to="/" className={styles.callToAction}>
        {t('buttonText')}
      </RouterButton>
    </div>
  );
};

const Accordion = () => {
  const { t } = useTranslation();

  const data = t(`landing-page.faq`, {
    returnObjects: true,
  }) as {
    id: string;
    title: string;
    body: string;
    hasLink?: string;
  }[];

  if (typeof data !== 'object') return null;

  data.forEach((el, i) => (el.id = 'faq' + i));

  const [isExpanded, setIsExpanded] = React.useState(data[0].id);

  return (
    <div className={styles.faq}>
      <h3>{t('landing-page.titles.faq')}</h3>
      <dl>
        {data.map((content, index) => (
          <React.Fragment key={content.id}>
            <dt>
              <button
                id={`${content.id}-header`}
                aria-controls={`${content.id}-panel`}
                aria-expanded={isExpanded === content.id}
                onClick={() => setIsExpanded(content.id)}
              >
                {content.title}
              </button>
            </dt>
            <dd
              id={`${content.id}-panel`}
              aria-labelledby={`${content.id}-header`}
              hidden={isExpanded !== content.id}
            >
              {content.hasLink ? (
                <Trans
                  i18nKey={`landing-page.faq.` + index + '.body'}
                  components={[
                    <a
                      key={`url+${index}`}
                      href={content.hasLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      URL
                    </a>,
                  ]}
                />
              ) : (
                content.body
              )}
            </dd>
          </React.Fragment>
        ))}
      </dl>
    </div>
  );
};

const HowTo = () => {
  const { t } = useTranslation();

  const data = t(`landing-page.howTo`, {
    returnObjects: true,
  }) as {
    id: string;
    title: string;
    body: string;
  }[];

  if (typeof data !== 'object') return null;

  data.forEach((el, i) => (el.id = 'howto' + i));

  return (
    <div className={styles.howTo}>
      <h3>{t('landing-page.titles.howTo')}</h3>
      <ol>
        {data.map((content) => (
          <li key={content.id}>
            <strong>{content.title}</strong>
            <br />
            {content.body}
          </li>
        ))}
      </ol>
    </div>
  );
};

const LoginForm = () => {
  const { login } = useAuthStore();
  const { source, newAccountUrl, seeCustomerNumberUrl } = useThemeConfig();
  const { t } = useTranslation();
  const [error, setError] = React.useState('');
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const redirect = decodeURIComponent(searchParams.get('redirect') || '/');

  const devLogin: React.FormEventHandler<HTMLFormElement> = (e) => {
    setError('');
    api
      .devLogin()
      .then((data) => {
        const { email, clientNumber, token } = data as AuthState;
        login({ email, clientNumber, token });
        navigate(redirect);
      })
      .catch((e) => {
        handleError(e);
        console.error(e);
      });
    e.preventDefault();
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    const data = new FormData(e.currentTarget);
    setError('');
    api
      .login(
        data.get('customerNumber') as string,
        data.get('email') as string,
        source
      )
      .then((data) => {
        const { email, clientNumber, token } = data as AuthState;
        login({ email, clientNumber, token });
        navigate(redirect);
      })
      .catch((e) => {
        handleError(e);
        console.error(e);
      });
    e.preventDefault();
  };

  const handleError = (error: ApiError) => {
    const code = error.response?.status;
    if (code === 500) {
      setError(t(`landing-page.login.errors.notAvailable`));
      return;
    }
    if (code === 404) {
      setError(t(`landing-page.login.errors.notFound`));
      return;
    }
    if (code === 429) {
      setError(t(`landing-page.login.errors.tooManyRequests`));
      return;
    }

    setError(t(`landing-page.login.errors.notAvailable`));
    return;
  };

  const stripPrefix = (url: string) =>
    url.replace(/^(https?:\/\/)?(www\.)?/, '');

  return (
    <div className={styles.welcome}>
      <h2>{t(`landing-page.login.title`)}</h2>
      {Boolean(error) && <ErrorMessage message={error} />}
      <p>
        {t(`landing-page.login.body`)}{' '}
        <a href={newAccountUrl} target="_blank" rel="noreferrer">
          {stripPrefix(newAccountUrl || '')}
        </a>
      </p>

      <form onSubmit={onSubmit}>
        <div>
          <label htmlFor="email">
            {t(`landing-page.login.email`)} {'*'}
          </label>
          <div className={styles.help}>{t(`landing-page.login.emailHelp`)}</div>
          <Input type="email" name="email" required />
        </div>
        <div>
          <label htmlFor="customerNumber">
            {t(`landing-page.login.customerNumber`)} {'*'}
          </label>
          <div className={styles.help}>
            {t(`landing-page.login.customerNumberHelp`)}{' '}
            <a
              href={`${seeCustomerNumberUrl}`}
              target="_blank"
              rel="noreferrer"
            >
              {stripPrefix(seeCustomerNumberUrl || '')}
            </a>
          </div>
          <Input
            type="text"
            name="customerNumber"
            required
            pattern="[0-9]{3,7}"
          />
        </div>
        <Button type="submit" className={cn('pill', styles.callToAction)}>
          {t(`landing-page.login.submit`)}
        </Button>
      </form>
      {ENVIRONMENT === 'development' && (
        <form onSubmit={devLogin}>
          <Button type="submit" className={cn('pill', styles.callToAction)}>
            {'Sign in as a developer (only visible in dev environment)'}
          </Button>
        </form>
      )}
    </div>
  );
};

export default LandingPage;
