import {
  CurrencyOptions,
  CutlistOrderStatus,
  CutlistState,
  PartItem,
} from '@cutr/constants/cutlist';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { api } from '@/api/backend';
import { usePartsErrorStore } from '@/api/errors';
import { materialsCached } from '@/api/materials';
import { useActiveGroup, useMaterialGroupState } from '@/api/materialsGroup';
import { useCutlistState } from '@/api/store';
import { onFocusSelect } from '@/hooks';
import { Button } from '@/primitives/Button';
import { Icon, Layer, Trash } from '@/primitives/Icons';
import { Input, Textarea } from '@/primitives/Input';
import { cleanNumberInput } from '@/utils/input';

import styles from '../PartTableCommon/styles.module.css';
import { SkeletonTable } from './';

const tableHeaders = [
  { labelKey: 'field.partName.label', id: 'label', width: '80px' },
  { labelKey: 'field.quantity.label' },
  { labelKey: 'field.length.label' },
  { labelKey: 'field.width.label' },
  { labelKey: '', width: '50px' },
];

export const DEFAULT_STORE = {
  title: '',
  orderId: undefined,
  parts: [],
  hasMaterials: materialsCached(),
  notes: '',
  deliverLeftoverMaterials: true,
  customerReference: '',
  internalPointOfContact: '',
  requestedDeliveryDate: '',
  status: 'draft' as CutlistOrderStatus,
  vatRate: 21,
  currency: 'EUR' as CurrencyOptions,
  sourceCurrency: 'EUR' as CurrencyOptions,
  exchangeRate: 1,
};

export const FreeTextModal = ({ onDone }: { onDone: () => void }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const store = useCutlistState();
  const gStore = useMaterialGroupState();
  const activeGroup = useActiveGroup();
  const { parts, init, reset } = useFreeTextCutlistState();
  const [error, setError] = React.useState<string>('');

  const [input, setInput] = React.useState<string>('');

  React.useEffect(() => {
    reset();
  }, []);

  const onSubmitClick = async () => {
    setIsLoading(true);
    try {
      setError('');
      const resp = await api.parseCutlistFreeFormText(input);
      init(resp);
    } catch (e) {
      console.error(e);
      setError(t('upload-download.freeTextIntake.error'));
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirmClick = () => {
    const partsToRemove = store.parts.filter(
      (p) => p.groupId === gStore.activeGroup
    );
    partsToRemove.forEach((p) => store.removePart(p));
    parts.forEach((p) => {
      p.groupId = gStore.activeGroup || '';
      p.core1 = activeGroup?.core1 || null;
      p.core2 = activeGroup?.core2 || null;
      p.topHpl = activeGroup?.topHpl || null;
      p.bottomHpl = activeGroup?.bottomHpl || null;

      store.setPart(p);
    });
    onDone();
  };

  return (
    <div className={styles.freeTextIntakeContainer}>
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <p>
        &#x2757;
        <Trans
          i18nKey="upload-download.freeTextIntake.betaWarning"
          values={{ email: 'beta@cutr.ai' }}
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          components={{ a: <a /> }}
        />
      </p>
      <p>{t('upload-download.freeTextIntake.modalInfo')}</p>
      <Textarea
        rows={20}
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      <Button onClick={onSubmitClick} size="s">
        {t('upload-download.freeTextIntake.modalCTA')}
      </Button>
      {error && <p className={styles.error}>{error}</p>}
      {isLoading && (
        <form className={styles.orderSection} name="ai-generated-parts">
          <SkeletonTable />
        </form>
      )}
      {parts.length > 0 && (
        <form className={styles.orderSection} name="ai-generated-parts">
          <section className={styles.partSection}>
            <table>
              <Head headers={tableHeaders} />
              <tbody>
                {parts.map((part) => {
                  return <TableRow part={part} key={part.id} />;
                })}
              </tbody>
            </table>
            <Button onClick={onConfirmClick} size="s">
              {t('upload-download.freeTextIntake.modalCTA2')}
            </Button>
          </section>
        </form>
      )}
    </div>
  );
};

const Head = ({ headers }: { headers: typeof tableHeaders }) => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        {headers.map((h, i) => (
          <th key={h.labelKey || i} style={{ minWidth: h.width }}>
            {t(`cutlist-form.${h.labelKey}`)}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export type FreeTextCutlistState = CutlistState & FreeTextCutlistStateActions;

export const useFreeTextCutlistState = create<FreeTextCutlistState>()(
  devtools(
    (set) => ({
      ...DEFAULT_STORE,
      setPart: (part) =>
        set((state) => {
          const index = state.parts.findIndex((p) => p.id === part.id);
          if (index == -1) {
            state.parts.push(part);
          }
          const parts = state.parts.slice(0);
          parts[index] = part;

          return { parts };
        }),
      removePart: (part) =>
        set((state) => {
          const index = state.parts.findIndex((p) => p.id === part.id);
          return {
            parts: [
              ...state.parts.slice(0, index),
              ...state.parts.slice(index + 1),
            ],
          };
        }),
      init: (savedState) => set((state) => ({ ...state, ...savedState })),
      reset: () =>
        set((state) => ({
          ...state,
          ...DEFAULT_STORE,
          hasMaterials: materialsCached(),
        })),
    }),
    { name: 'cutlist' }
  )
);

export type FreeTextCutlistStateActions = {
  init: (state: CutlistState) => void;
  setPart: (part: PartItem) => void;
  removePart: (part: PartItem) => void;
  reset(): void;
};

const TableRow = ({ part }: { part: PartItem }) => {
  const { t } = useTranslation();
  const { setPart, removePart } = useFreeTextCutlistState();
  const { partErrors, addPartError, removePartErrorsByField } =
    usePartsErrorStore();

  const onUpdate = (key: keyof PartItem, value: PartItem[typeof key]) => {
    const updatedPart = { ...part };
    // @ts-expect-error this typing is hard :/
    updatedPart[key] = value;
    setPart(updatedPart);
  };

  const validateNumericInput = (key: keyof PartItem, value: string) => {
    const number = cleanNumberInput(value);

    if (key === 'quantity') {
      removePartErrorsByField(part.id, key);
      if (+number > 10000) {
        addPartError(
          part.id,
          key,
          `Part "${part.label}" quantity cannot be larger than ${10000}!`
        );
      }
    }
    return number;
  };

  const partsErrors = partErrors.filter(
    (error) => error.partItemId === part.id
  );

  const isFieldValid = (key: keyof PartItem) => {
    return partErrors.filter((error) => error.field === key).length === 0;
  };

  return (
    <tr
      id={`partRow-${part.id}`}
      className={partsErrors.length ? styles.errors : undefined}
    >
      <td>
        {part.partType === 'sheet' ? (
          <span className="flexAlign">
            <Icon icon={<Layer />} />
            {t('cutlist-form.field.fullSheet')}
          </span>
        ) : (
          <Input
            className="snug"
            defaultValue={part.label}
            placeholder={t('cutlist-form.field.partName.placeholder')}
            name={`label_${part.id}`}
            key={`label_${part.id}`}
            onFocus={onFocusSelect}
            onBlur={(e) => {
              onUpdate('label', e.currentTarget.value);
            }}
          />
        )}
      </td>
      <td>
        <Input
          className={isFieldValid('quantity') ? 'snug' : 'snug invalid'}
          type="number"
          value={part.quantity}
          name={`quantity_${part.id}`}
          key={`quantity_${part.id}`}
          onInput={(e) => {
            onUpdate(
              'quantity',
              validateNumericInput('quantity', e.currentTarget.value)
            );
          }}
        />
      </td>
      <td>
        <Input
          className="snug"
          value={part.lengthMM}
          disabled={part.partType === 'sheet'}
          placeholder={t('cutlist-form.field.length.placeholder')}
          pattern="[0-9]+"
          name={`length_${part.id}`}
          key={`length_${part.id}`}
          onFocus={onFocusSelect}
          onInput={(e) => {
            onUpdate('lengthMM', cleanNumberInput(e.currentTarget.value));
          }}
        />
      </td>
      <td>
        <Input
          className="snug"
          value={part.widthMM}
          disabled={part.partType === 'sheet'}
          placeholder={t('cutlist-form.field.width.placeholder')}
          pattern="[0-9]+"
          name={`width_${part.id}`}
          key={`width_${part.id}`}
          onFocus={onFocusSelect}
          onInput={(e) => {
            onUpdate('widthMM', cleanNumberInput(e.currentTarget.value));
          }}
        />
      </td>
      <td style={{ '--spaceX': '0.1rem' } as React.CSSProperties}>
        <div className="flexAlign gap-xxs alignEnd">
          <Button
            variant="icon"
            className="delete"
            name="delete"
            icon={<Icon icon={<Trash />} />}
            onClick={() => {
              removePart(part);
            }}
            tooltip={t('common.cta.delete')}
            aria-label={t('common.cta.delete')}
          />
        </div>
      </td>
    </tr>
  );
};
