import { useTranslation } from 'react-i18next';

import { EDGEBANDING_SIDES } from '@/interfaces/Edgebanding';
import { Icon, Info } from '@/primitives/Icons';

import styles from './EdgesTooltip.module.css';

export const EdgesTooltip = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Icon icon={<Info />} color="var(--gray-7)" />
      <div className={styles.tooltip}>
        <div className={styles.base}>
          {EDGEBANDING_SIDES.map((label) => (
            <div className={styles[label]} key={label}>
              {t(`cutlist-form.field.edgebanding.sides.${label}`)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
