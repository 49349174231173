import { IframeData } from '@cutr/constants/iframe-communication';
import React from 'react';

import { api } from '@/api/backend';
import { useAggregatedPrice } from '@/api/pricing';
import { useOrderId } from '@/api/store';
import { useIsNextDisabled } from '@/hooks';
import { useCurrentSource } from '@/theme';
import { cleanupLocalStorage, iframePostMessage } from '@/utils/misc';

const saveCutlist = (id = '', analyticsOpts: Record<string, unknown> = {}) => {
  api
    .saveEmbeddedCutlist(id, 'place_order')
    .then((response) => response.json())
    .then(({ data }) => {
      window.analytics.track('Embedded cutlist saved', {
        ...analyticsOpts,
        isNew: !id,
      });
      iframePostMessage('saved', { id: data.id });
      cleanupLocalStorage();
    })
    .catch((error) => {
      console.info(error);
      iframePostMessage('error', { message: error.message });
    });
};

export const useIframeListeners = () => {
  const id = useOrderId();
  const priceData = useAggregatedPrice();
  const isNextDisabled = useIsNextDisabled();
  const isValid = !isNextDisabled;
  const ticker = useCurrentSource();

  const onMessage = (data: IframeData) => {
    switch (data.payload.key) {
      case 'save':
        onSave();
        break;
      case 'cleanup':
        cleanupLocalStorage();
        break;
    }
  };

  const onSave = () => {
    if (!isValid) {
      iframePostMessage('validation', { isValid });
      return;
    }

    return saveCutlist(id, { ticker, priceData });
  };

  React.useEffect(() => {
    iframePostMessage('validation', { isValid: !isNextDisabled });
  }, [isNextDisabled]);

  React.useEffect(() => {
    const onMessageListener = (event: MessageEvent<IframeData>) => {
      // if (event.origin !== origin) return;
      if (event && event.data?.__msgId === window.name) {
        onMessage(event.data);
      }
    };

    window.addEventListener('message', onMessageListener);
    return () => window.removeEventListener('message', onMessageListener);
  }, [onMessage]);
};
