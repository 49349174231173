export const isWeekend = (date: string) => {
  const day = new Date(date).getDay();
  return day === 0 || day === 6;
};

export const getNthWorkingDayFromToday = (n: number) => {
  const today = new Date();
  return getNthWorkingDay(today, n);
};

export const getNthWorkingDay = (today: Date, n: number) => {
  const currentDate = new Date(today);
  let workingDays = 0;

  while (workingDays < n) {
    currentDate.setDate(currentDate.getDate() + 1);
    if (!isWeekend(currentDate.toISOString())) {
      workingDays++;
    }
  }

  return currentDate;
};

export const formatYYYYMMDD = (date: Date) => {
  const d = new Date(date);
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const year = d.getFullYear();

  return [year, padWithZero(month), padWithZero(day)].join('-');
};

const padWithZero = (num: number) => {
  return num < 10 ? `0${num}` : num;
};
